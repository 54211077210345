import React from 'react';
import { useFavorites } from '../contexts/FavoriteContext';
import ListingItem from './ListingItem';
import { Spin, Empty } from 'antd';

const FavoriteListings = () => {
  const { favorites, isLoading } = useFavorites();

  if (isLoading) {
    return (
      <div className="flex justify-center items-center min-h-[400px] pt-20">
        <Spin size="large" />
      </div>
    );
  }

  if (!favorites || favorites.length === 0) {
    return (
      <div className="max-w-3xl mx-auto px-4 py-8 pt-20">
        <h1 className="text-2xl font-bold text-center mb-8">Tin đăng yêu thích</h1>
        <Empty
          description={
            <span className="text-gray-500">Bạn chưa có tin đăng yêu thích nào</span>
          }
          className="bg-white p-8 rounded-lg shadow-sm"
        />
      </div>
    );
  }

  return (
    <div className="max-w-3xl mx-auto px-4 py-8 pt-20">
      <h1 className="text-2xl font-bold text-center mb-8">Tin đăng yêu thích</h1>
      
      <div className="space-y-4">
        {favorites.map((listing) => (
          <div key={listing.id} className="bg-white rounded-lg shadow-sm hover:shadow-md transition-shadow">
            <ListingItem
              listing={listing}
              showFavoriteButton={true}
              className="!block"
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default FavoriteListings;
