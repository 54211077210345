import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Tag, Modal, message, Input, Tooltip } from 'antd';
import { UserOutlined, SearchOutlined, CheckCircleOutlined, HomeOutlined, DeleteOutlined, MailOutlined } from '@ant-design/icons';
import api from '../../api';

const UserManagement = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    
    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const response = await api.get('/admin/users');
            setUsers(response.data.data);
        } catch (error) {
            message.error('Không thể tải danh sách người dùng');
        } finally {
            setLoading(false);
        }
    };

    const handleVerifyUser = async (userId) => {
        try {
            await api.post(`/admin/users/${userId}/verify`);
            message.success('Đã xác thực người dùng');
            fetchUsers();
        } catch (error) {
            message.error('Không thể xác thực người dùng');
        }
    };

    const handleToggleAdmin = async (userId, isAdmin) => {
        try {
            await api.post(`/admin/users/${userId}/toggle-admin`, { is_admin: !isAdmin });
            message.success(`Đã ${!isAdmin ? 'cấp' : 'thu hồi'} quyền admin`);
            fetchUsers();
        } catch (error) {
            message.error('Không thể thay đổi quyền admin');
        }
    };

    const handleToggleLandlord = async (userId, isLandlord) => {
        try {
            console.log('Calling toggleLandlord API:', userId, isLandlord);
            const response = await api.post(`/admin/users/${userId}/toggle-landlord`, { 
                is_landlord: !isLandlord 
            });
            console.log('API Response:', response);
            message.success(`Đã ${!isLandlord ? 'cấp' : 'thu hồi'} quyền chủ nhà`);
            fetchUsers();
        } catch (error) {
            console.error('API Error:', error);
            message.error('Không thể thay đổi quyền chủ nhà');
        }
    };

    const handleResendVerification = async (userId) => {
        try {
            await api.post(`/admin/users/${userId}/resend-verification`);
            message.success('Đã gửi lại email xác thực');
        } catch (error) {
            message.error('Không thể gửi lại email xác thực');
        }
    };

    const handleDeleteUser = async (userId) => {
        Modal.confirm({
            title: 'Xác nhận xóa',
            content: 'Bạn có chắc chắn muốn xóa người dùng này?',
            okText: 'Xóa',
            okType: 'danger',
            cancelText: 'Hủy',
            onOk: async () => {
                try {
                    await api.delete(`/admin/users/${userId}`);
                    message.success('Đã xóa người dùng');
                    fetchUsers();
                } catch (error) {
                    message.error('Không thể xóa người dùng');
                }
            },
        });
    };

    const handleSearch = (value) => {
        setSearchText(value);
    };

    const filteredUsers = users.filter(user => 
        user.username.toLowerCase().includes(searchText.toLowerCase()) ||
        user.email.toLowerCase().includes(searchText.toLowerCase()) ||
        user.full_name.toLowerCase().includes(searchText.toLowerCase())
    );

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 80,
        },
        {
            title: 'Tên đăng nhập',
            dataIndex: 'username',
            key: 'username',
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Họ tên',
            dataIndex: 'full_name',
            key: 'full_name',
        },
        {
            title: 'Số dư',
            dataIndex: 'balance',
            key: 'balance',
            render: (balance) => new Intl.NumberFormat('vi-VN', { style: 'currency', currency: 'VND' }).format(balance),
        },
        {
            title: 'Trạng thái',
            key: 'status',
            render: (_, record) => (
                <Space>
                    {record.is_verified && <Tag color="green">Đã xác thực</Tag>}
                    {record.is_landlord && <Tag color="blue">Chủ nhà</Tag>}
                    {record.is_admin && <Tag color="red">Admin</Tag>}
                </Space>
            ),
        },
        {
            title: 'Thao tác',
            key: 'action',
            render: (_, record) => (
                <Space>
                    {!record.is_verified && (
                        <Tooltip title="Xác thực người dùng">
                            <Button 
                                type="primary" 
                                icon={<CheckCircleOutlined />} 
                                size="small" 
                                onClick={() => handleVerifyUser(record.id)}
                            />
                        </Tooltip>
                    )}
                    
                    <Tooltip title={record.is_landlord ? 'Thu hồi quyền chủ nhà' : 'Cấp quyền chủ nhà'}>
                        <Button
                            type={record.is_landlord ? "default" : "primary"}
                            icon={<HomeOutlined />}
                            size="small"
                            onClick={() => handleToggleLandlord(record.id, record.is_landlord)}
                        />
                    </Tooltip>

                    <Tooltip title={record.is_admin ? 'Thu hồi quyền admin' : 'Cấp quyền admin'}>
                        <Button 
                            type={record.is_admin ? "default" : "primary"}
                            icon={<UserOutlined />}
                            size="small"
                            danger={record.is_admin}
                            onClick={() => handleToggleAdmin(record.id, record.is_admin)}
                        />
                    </Tooltip>

                    {!record.is_verified && (
                        <Tooltip title="Gửi lại email xác thực">
                            <Button
                                type="default"
                                icon={<MailOutlined />}
                                size="small"
                                onClick={() => handleResendVerification(record.id)}
                            />
                        </Tooltip>
                    )}

                    <Tooltip title="Xóa người dùng">
                        <Button
                            type="primary"
                            danger
                            icon={<DeleteOutlined />}
                            size="small"
                            onClick={() => handleDeleteUser(record.id)}
                        />
                    </Tooltip>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div style={{ marginBottom: 16, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Input
                    placeholder="Tìm kiếm người dùng..."
                    prefix={<SearchOutlined />}
                    onChange={e => handleSearch(e.target.value)}
                    style={{ width: 300 }}
                />
                <span>
                    <Button type="primary" onClick={fetchUsers} style={{ marginLeft: 16 }}>
                        Làm mới
                    </Button>
                </span>
            </div>
            <Table
                columns={columns}
                dataSource={filteredUsers}
                rowKey="id"
                loading={loading}
                pagination={{
                    pageSize: 10,
                    showSizeChanger: true,
                    showTotal: (total) => `Tổng ${total} người dùng`,
                }}
            />
        </div>
    );
};

export default UserManagement;