import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Input, Button, Card, Checkbox, message, Steps, Modal } from 'antd';
import { UserOutlined, LockOutlined, MailOutlined, PhoneOutlined, IdcardOutlined, CheckCircleOutlined } from '@ant-design/icons';
import { register } from '../api';

const { Step } = Steps;

const API_URL = process.env.REACT_APP_API_URL || 'https://thuenhadedang.io.vn';

function Register() {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [formData, setFormData] = useState({});
  const navigate = useNavigate();

  const [checkingEmail, setCheckingEmail] = useState(false);
  const [checkingUsername, setCheckingUsername] = useState(false);

  const checkEmailExists = async (email) => {
    try {
      setCheckingEmail(true);
      const response = await fetch(`${API_URL}/api/check-email?email=${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      return data.exists;
    } catch (error) {
      console.error('Error checking email:', error);
      return false;
    } finally {
      setCheckingEmail(false);
    }
  };

  const checkUsernameExists = async (username) => {
    try {
      setCheckingUsername(true);
      const response = await fetch(`${API_URL}/api/check-username?username=${username}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      const data = await response.json();
      return data.exists;
    } catch (error) {
      console.error('Error checking username:', error);
      return false;
    } finally {
      setCheckingUsername(false);
    }
  };

  const next = async () => {
    try {
      const values = await form.validateFields(
        currentStep === 0 
          ? ['email', 'password', 'confirmPassword']
          : ['username', 'full_name', 'phone_number']
      );
      
      setFormData(prev => ({...prev, ...values}));
      setCurrentStep(currentStep + 1);
    } catch (error) {
      console.error('Validation failed:', error);
    }
  };

  const prev = () => {
    setCurrentStep(currentStep - 1);
  };

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      
      const finalData = {
        ...formData,
        ...values
      };

      console.log('Submitting data:', finalData);

      const response = await register(finalData);
      
      if (response?.status === 'success') {
        message.success(response.message);
        navigate('/login');
      } else {
        throw new Error(response?.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      message.error(error.message || 'Đăng ký thất bại. Vui lòng thử lại.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (Object.keys(formData).length > 0) {
      form.setFieldsValue(formData);
    }
  }, [formData, form]);

  const emailFormItem = (
    <Form.Item
      name="email"
      rules={[
        { required: true, message: 'Vui lòng nhập email' },
        { type: 'email', message: 'Email không hợp lệ' }
      ]}
    >
      <Input prefix={<MailOutlined />} placeholder="Email" />
    </Form.Item>
  );

  const usernameFormItem = (
    <Form.Item
      name="username"
      rules={[
        { required: true, message: 'Vui lòng nhập tên đăng nhập' },
        { min: 3, message: 'Tên đăng nhập phải có ít nhất 3 ký tự' },
        {
          validator: async (_, value) => {
            if (value) {
              const exists = await checkUsernameExists(value);
              if (exists) {
                throw new Error('Tên đăng nhập đã tồn tại');
              }
            }
            return Promise.resolve();
          },
          validateTrigger: 'onBlur'
        }
      ]}
      validateStatus={checkingUsername ? 'validating' : undefined}
      hasFeedback
    >
      <Input 
        prefix={<UserOutlined className="text-gray-400" />}
        placeholder="Tên đăng nhập"
        className="rounded-lg"
      />
    </Form.Item>
  );

  const steps = [
    {
      title: 'Thông tin tài khoản',
      content: (
        <>
          {emailFormItem}
          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Vui lòng nhập mật khẩu' },
              { min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự' }
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="text-gray-400" />}
              placeholder="Mật khẩu"
              className="rounded-lg"
            />
          </Form.Item>

          <Form.Item
            name="confirmPassword"
            dependencies={['password']}
            rules={[
              { required: true, message: 'Vui lòng xác nhận mật khẩu' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Mật khẩu xác nhận không khớp'));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="text-gray-400" />}
              placeholder="Xác nhận mật khẩu"
              className="rounded-lg"
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Thông tin cá nhân',
      content: (
        <>
          {usernameFormItem}
          <Form.Item
            name="full_name"
            rules={[
              { required: true, message: 'Vui lòng nhập họ tên' }
            ]}
          >
            <Input 
              prefix={<IdcardOutlined className="text-gray-400" />}
              placeholder="Họ và tên"
              className="rounded-lg"
            />
          </Form.Item>

          <Form.Item
            name="phone_number"
            rules={[
              { required: true, message: 'Vui lòng nhập số điện thoại' },
              { 
                pattern: /^(0|84|\+84)?[0-9]{9,10}$/,
                message: 'Số điện thoại không hợp lệ. Vui lòng nhập số điện thoại Việt Nam (VD: 0912345678)' 
              }
            ]}
          >
            <Input
              prefix={<PhoneOutlined className="text-gray-400" />}
              placeholder="Số điện thoại (VD: 0912345678)"
              className="rounded-lg"
              maxLength={10}
            />
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
      <Card className="w-full max-w-md shadow-2xl">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-800">
            Đăng ký tài khoản
          </h2>
          <p className="mt-2 text-gray-600">
            Đăng ký để trở thành chủ nhà
          </p>
        </div>

        <Form
          form={form}
          name="register"
          onFinish={handleSubmit}
          layout="vertical"
          size="large"
        >
          <Steps current={currentStep} className="mb-8">
            {steps.map(item => (
              <Step key={item.title} title={item.title} />
            ))}
          </Steps>

          {steps[currentStep].content}

          <div className="flex justify-between mt-8">
            {currentStep > 0 && (
              <Button onClick={prev} size="large">
                Quay lại
              </Button>
            )}
            
            {currentStep < steps.length - 1 && (
              <Button 
                type="primary"
                onClick={next}
                size="large"
                className="ml-auto bg-gradient-to-r from-blue-500 to-indigo-600"
              >
                Tiếp tục
              </Button>
            )}
            
            {currentStep === steps.length - 1 && (
              <Button 
                type="primary"
                htmlType="submit"
                loading={loading}
                size="large"
                className="ml-auto bg-gradient-to-r from-blue-500 to-indigo-600"
              >
                {loading ? 'Đang xử lý...' : 'Đăng ký'}
              </Button>
            )}
          </div>
        </Form>

        <div className="text-center mt-6">
          Đã có tài khoản?{' '}
          <Link to="/login" className="text-blue-500 hover:text-blue-700 font-medium">
            Đăng nhập
          </Link>
        </div>
      </Card>
    </div>
  );
}

export default Register;
