import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { TextField } from '@mui/material';
import { FaSearch, FaMapMarkerAlt, FaDollarSign, FaRulerCombined, FaChevronLeft, FaHome, FaTimes } from 'react-icons/fa';
import RcSlider from 'rc-slider';
import 'rc-slider/assets/index.css';

const SearchBar = ({ onSearchResults, initialSearchType, searchParams, setSearchParams, onClearSearch, onSearch }) => {
  const [city, setCity] = useState('');
  const [district, setDistrict] = useState('');
  const [ward, setWard] = useState('');
  const [minPrice, setMinPrice] = useState('');
  const [maxPrice, setMaxPrice] = useState('');
  const [minArea, setMinArea] = useState('');
  const [maxArea, setMaxArea] = useState('');
  const [showLocationPopup, setShowLocationPopup] = useState(false);
  const [showPricePopup, setShowPricePopup] = useState(false);
  const [showAreaPopup, setShowAreaPopup] = useState(false);
  const [cities, setCities] = useState([]);
  const [showTypePopup, setShowTypePopup] = useState(false);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await axios.get('https://raw.githubusercontent.com/kenzouno1/DiaGioiHanhChinhVN/master/data.json');
        setCities(response.data);
      } catch (error) {
        console.error('Error fetching cities:', error);
      }
    };
    fetchCities();
  }, []);

  useEffect(() => {
    setSearchParams(prevParams => ({ ...prevParams, type: initialSearchType }));
  }, [initialSearchType]);

  useEffect(() => {
    if (searchParams.city) {
      setCity(searchParams.city);
    }
  }, [searchParams.city]);

  const handleChange = (e) => {
    setSearchParams({ ...searchParams, [e.target.name]: e.target.value });
  };

  const handleLocationSelect = (city, district, ward) => {
    const newParams = { ...searchParams };
    
    if (city !== undefined) newParams.city = city;
    if (district !== undefined) newParams.district = district;
    if (ward !== undefined) newParams.ward = ward;
    
    setSearchParams(newParams);
    
    if (city !== undefined) setCity(city);
    if (district !== undefined) setDistrict(district);
    if (ward !== undefined) setWard(ward);
    
    setShowLocationPopup(false);
  };

  const handlePriceChange = (min, max) => {
    setSearchParams({
      ...searchParams,
      minPrice: min === '' ? '' : parseInt(min, 10),
      maxPrice: max === '' ? '' : parseInt(max, 10)
    });
  };

  const handleAreaChange = (min, max) => {
    setSearchParams({
      ...searchParams,
      minArea: min === '' ? '' : parseInt(min, 10),
      maxArea: max === '' ? '' : parseInt(max, 10)
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { city, district, ward, minPrice, maxPrice, minArea, maxArea, type } = searchParams;

    const searchConditions = {
        city: city || '',
        district: district || '',
        ward: ward || '',
        minPrice: minPrice ? parseInt(minPrice) : 0,
        maxPrice: maxPrice ? parseInt(maxPrice) : 0,
        minArea: minArea ? parseInt(minArea) : 0,
        maxArea: maxArea ? parseInt(maxArea) : 0,
        type: type || ''
    };

    onSearch(searchConditions);
  };

  const handleTypeSelect = (type) => {
    setSearchParams({ ...searchParams, type });
    setShowTypePopup(false);
  };

  const handleClear = (field) => {
    if (field === 'location') {
      setSearchParams(prevParams => ({
        ...prevParams,
        city: '',
        district: null,
        ward: null
      }));
      setCity('');
      setDistrict('');
      setWard('');
    } else if (field === 'price') {
      setSearchParams({ ...searchParams, minPrice: '', maxPrice: '' });
    } else if (field === 'area') {
      setSearchParams({ ...searchParams, minArea: '', maxArea: '' });
    } else if (field === 'all') {
      onClearSearch();
    } else {
      setSearchParams({ ...searchParams, [field]: '' });
    }
  };

  const formatPriceDisplay = (min, max) => {
    const formatToMillions = (value) => {
      return (value / 1_000_000).toFixed(1); // Convert to millions and keep one decimal place
    };

    if (min && max) {
      return `${formatToMillions(min)} - ${formatToMillions(max)} triệu VND`;
    } else if (min) {
      return `Trên ${formatToMillions(min)} triệu VND`;
    } else if (max) {
      return `Dưới ${formatToMillions(max)} triệu VND`;
    }
    return 'Chọn giá';
  };

  const formatAreaDisplay = (min, max) => {
    if (min && max) {
      return `${min} - ${max} m²`;
    } else if (min) {
      return `Trên ${min} m²`;
    } else if (max) {
      return `Dưới ${max} m²`;
    }
    return 'Chọn diện tích';
  };

  // Define popup props
  const locationPopupProps = {
    onClose: () => setShowLocationPopup(false),
    onSelect: handleLocationSelect,
    cities: cities,
    currentCity: searchParams.city,
    currentDistrict: searchParams.district,
    currentWard: searchParams.ward
  };

  const pricePopupProps = {
    onClose: () => setShowPricePopup(false),
    onSelect: handlePriceChange,
    currentMinPrice: searchParams.minPrice,
    currentMaxPrice: searchParams.maxPrice
  };

  const areaPopupProps = {
    onClose: () => setShowAreaPopup(false),
    onSelect: handleAreaChange,
    currentMinArea: searchParams.minArea,
    currentMaxArea: searchParams.maxArea
  };

  const typePopupProps = {
    onClose: () => setShowTypePopup(false),
    onSelect: handleTypeSelect,
    currentType: searchParams.type
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white rounded-lg p-2 md:p-4">
      <div className="flex flex-col space-y-2 md:space-y-0 md:flex-row md:space-x-2">
        <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-2 flex-1">
          <button 
            type="button" 
            onClick={() => setShowTypePopup(true)}
            className="w-full md:flex-1 p-2.5 text-sm border border-gray-300 rounded-md flex items-center justify-between focus:outline-none focus:ring-1 focus:ring-primary-500 bg-white"
          >
            <div className="flex items-center min-w-0">
              <FaHome className="text-primary-500 mr-2 flex-shrink-0" />
              <span className="truncate">
                {searchParams.type || 'Loại nhà'}
              </span>
            </div>
            {searchParams.type && (
              <FaTimes 
                className="text-gray-400 hover:text-gray-600 cursor-pointer ml-2 flex-shrink-0" 
                onClick={(e) => { 
                  e.stopPropagation(); 
                  handleClear('type'); 
                }} 
              />
            )}
          </button>

          <button 
            type="button" 
            onClick={() => setShowLocationPopup(true)}
            className="w-full md:flex-1 p-2.5 text-sm border border-gray-300 rounded-md flex items-center justify-between focus:outline-none focus:ring-1 focus:ring-primary-500 bg-white"
          >
            <div className="flex items-center min-w-0">
              <FaMapMarkerAlt className="text-primary-500 mr-2 flex-shrink-0" />
              <span className="truncate">
                {searchParams.city ? 
                  [searchParams.ward, searchParams.district, searchParams.city]
                    .filter(Boolean)
                    .join(', ')
                  : 'Chọn khu vực'}
              </span>
            </div>
            {searchParams.city && (
              <FaTimes 
                className="text-gray-400 hover:text-gray-600 cursor-pointer ml-2 flex-shrink-0" 
                onClick={(e) => { 
                  e.stopPropagation(); 
                  handleClear('location'); 
                }} 
              />
            )}
          </button>

          <button 
            type="button"
            onClick={() => setShowPricePopup(true)}
            className="w-full md:flex-1 p-2.5 text-sm border border-gray-300 rounded-md flex items-center justify-between focus:outline-none focus:ring-1 focus:ring-primary-500 bg-white"
          >
            <div className="flex items-center min-w-0">
              <FaDollarSign className="text-primary-500 mr-2 flex-shrink-0" />
              <span className="truncate">
                {(searchParams.minPrice || searchParams.maxPrice) 
                  ? formatPriceDisplay(searchParams.minPrice, searchParams.maxPrice)
                  : 'Chọn giá'}
              </span>
            </div>
            {(searchParams.minPrice || searchParams.maxPrice) && (
              <FaTimes 
                className="text-gray-400 hover:text-gray-600 cursor-pointer ml-2 flex-shrink-0" 
                onClick={(e) => { 
                  e.stopPropagation(); 
                  handleClear('price'); 
                }} 
              />
            )}
          </button>

          <button 
            type="button"
            onClick={() => setShowAreaPopup(true)}
            className="w-full md:flex-1 p-2.5 text-sm border border-gray-300 rounded-md flex items-center justify-between focus:outline-none focus:ring-1 focus:ring-primary-500 bg-white"
          >
            <div className="flex items-center min-w-0">
              <FaRulerCombined className="text-primary-500 mr-2 flex-shrink-0" />
              <span className="truncate">
                {(searchParams.minArea || searchParams.maxArea)
                  ? formatAreaDisplay(searchParams.minArea, searchParams.maxArea)
                  : 'Chọn diện tích'}
              </span>
            </div>
            {(searchParams.minArea || searchParams.maxArea) && (
              <FaTimes 
                className="text-gray-400 hover:text-gray-600 cursor-pointer ml-2 flex-shrink-0" 
                onClick={(e) => { 
                  e.stopPropagation(); 
                  handleClear('area'); 
                }} 
              />
            )}
          </button>
        </div>

        <button
          type="submit"
          className="w-full md:w-auto md:min-w-[120px] p-2.5 bg-primary-500 text-white rounded-md hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 transition-colors flex items-center justify-center"
        >
          <FaSearch className="mr-2" />
          <span>Tìm kiếm</span>
        </button>
      </div>

      {showLocationPopup && <LocationPopup {...locationPopupProps} />}
      {showPricePopup && <PricePopup {...pricePopupProps} />}
      {showAreaPopup && <AreaPopup {...areaPopupProps} />}
      {showTypePopup && <TypePopup {...typePopupProps} />}
    </form>
  );
};

const LocationPopup = ({ onClose, onSelect, cities, currentCity, currentDistrict, currentWard }) => {
  const [currentView, setCurrentView] = useState('city');
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (currentCity && cities) {
      const city = cities.find(c => c.Name === currentCity);
      if (city) {
        setSelectedCity(city);
        if (currentDistrict) {
          const district = city.Districts.find(d => d.Name === currentDistrict);
          if (district) {
            setSelectedDistrict(district);
            if (currentWard) {
              setCurrentView('ward');
            } else {
              setCurrentView('district');
            }
          }
        }
      }
    }
  }, [currentCity, currentDistrict, currentWard, cities]);

  const normalizeString = (str) => {
    return str
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/đ/g, "d");
  };

  const filteredItems = () => {
    const normalizedSearch = normalizeString(searchTerm);
    
    if (currentView === 'city') {
      return cities.filter(city => 
        normalizeString(city.Name).includes(normalizedSearch)
      );
    } else if (currentView === 'district' && selectedCity) {
      return selectedCity.Districts.filter(district => 
        normalizeString(district.Name).includes(normalizedSearch)
      );
    } else if (currentView === 'ward' && selectedDistrict) {
      return selectedDistrict.Wards.filter(ward => 
        normalizeString(ward.Name).includes(normalizedSearch)
      );
    }
    return [];
  };

  const handleCitySelect = (city) => {
    setSelectedCity(city);
    setCurrentView('district');
    setSearchTerm('');
  };

  const handleDistrictSelect = (district) => {
    setSelectedDistrict(district);
    setCurrentView('ward');
    setSearchTerm('');
  };

  const handleWardSelect = (ward) => {
    onSelect(
      selectedCity.Name,
      selectedDistrict.Name,
      ward.Name
    );
    onClose();
  };

  const handleBack = () => {
    if (currentView === 'ward') {
      setCurrentView('district');
      setSelectedDistrict(null);
    } else if (currentView === 'district') {
      setCurrentView('city');
      setSelectedCity(null);
    }
    setSearchTerm('');
  };

  const handleSelectAll = () => {
    if (currentView === 'district' && selectedCity) {
      onSelect(selectedCity.Name, null, null);
    } else if (currentView === 'ward' && selectedDistrict) {
      onSelect(selectedCity.Name, selectedDistrict.Name, null);
    }
    onClose();
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-96 max-h-[80vh] flex flex-col overflow-hidden">
        <div className="flex items-center justify-between mb-4 p-4 border-b">
          <h3 className="text-lg font-semibold truncate">
            {currentView === 'city' ? 'Chọn Tỉnh/Thành phố' : 
             currentView === 'district' ? `${selectedCity?.Name || ''} - Quận/Huyện` :
             `${selectedDistrict?.Name || ''} - Phường/Xã`}
          </h3>
          {currentView !== 'city' && (
            <button 
              onClick={handleBack} 
              className="text-primary-500 hover:text-primary-600 flex-shrink-0 ml-2"
            >
              <FaChevronLeft />
            </button>
          )}
        </div>

        <TextField
          placeholder={`Tìm ${
            currentView === 'city' ? 'tỉnh/thành phố' : 
            currentView === 'district' ? 'quận/huyện' : 
            'phường/xã'
          }...`}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="mb-4"
          fullWidth
          variant="outlined"
          size="small"
        />

        <div className="flex-grow overflow-y-auto">
          <ul className="divide-y divide-gray-200">
            {filteredItems().map((item, index) => (
              <li 
                key={item.Id} 
                onClick={() => {
                  if (currentView === 'city') handleCitySelect(item);
                  else if (currentView === 'district') handleDistrictSelect(item);
                  else handleWardSelect(item);
                }}
                className="cursor-pointer p-2 hover:bg-gray-100 transition duration-150 ease-in-out"
              >
                <span className="block truncate">{item.Name}</span>
              </li>
            ))}
          </ul>
        </div>
        
        {currentView !== 'city' && (
          <button 
            onClick={handleSelectAll}
            className="mt-4 w-full bg-primary-500 text-white p-2 rounded-md hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500 transition duration-150 ease-in-out"
          >
            Chọn tất cả {currentView === 'district' ? 'Quận/Huyện' : 'Phường/Xã'}
          </button>
        )}
        <button 
          onClick={onClose}
          className="mt-2 w-full bg-secondary-500 text-white p-2 rounded-md hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 transition duration-150 ease-in-out"
        >
          Đóng
        </button>
      </div>
    </div>
  );
};

const PricePopup = ({ onClose, onSelect, currentMin, currentMax }) => {
  const [range, setRange] = useState([currentMin || 0, currentMax || 15_000_000]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleApply = () => {
    const minPrice = range[0] === 0 ? '' : range[0].toString();
    const maxPrice = range[1] === 15_000_000 ? '' : range[1].toString();
    onSelect(minPrice, maxPrice);
    onClose();
  };

  const formatPrice = (value) => {
    return `${(value / 1_000_000).toFixed(1)} triệu VND`; // Convert to millions
  };

  const quickSelectOptions = [
    { label: 'Tất cả', min: 0, max: 15_000_000 },
    { label: 'Dưới 1 triệu', min: 0, max: 1_000_000 },
    { label: '1 - 2 triệu', min: 1_000_000, max: 2_000_000 },
    { label: '2 - 3 triệu', min: 2_000_000, max: 3_000_000 },
    { label: '3 - 5 triệu', min: 3_000_000, max: 5_000_000 },
    { label: '5 - 7 triệu', min: 5_000_000, max: 7_000_000 },
    { label: '7 - 10 triệu', min: 7_000_000, max: 10_000_000 },
    { label: '10 - 15 triệu', min: 10_000_000, max: 15_000_000 },
    { label: 'Trên 15 triệu', min: 15_000_000, max: 15_000_000 },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-96 max-h-[80vh] flex flex-col overflow-hidden">
        <div className="p-4 border-b flex justify-between items-center">
          <span className="font-semibold">Chọn giá</span>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <div className="p-4 overflow-y-auto">
          <div className="mb-4">
            <div className="text-center mb-2 font-semibold">
              {formatPrice(range[0])} - {formatPrice(range[1])}
            </div>
            <RcSlider
              range
              min={0}
              max={15_000_000}
              step={500000}
              value={range}
              onChange={(newRange) => setRange(newRange)}
            />
            <div className="flex justify-between mt-2">
              <span>{formatPrice(0)}</span>
              <span>{formatPrice(15_000_000)}</span>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2">
            {quickSelectOptions.map((option, index) => (
              <button
                key={index}
                className={`p-2 text-sm text-center rounded ${
                  selectedOption === option ? 'bg-primary-100 text-primary-600' : 'bg-gray-100 hover:bg-gray-200'
                }`}
                onClick={() => {
                  setSelectedOption(option);
                  setRange([option.min, option.max]);
                }}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
        <div className="p-4 border-t flex justify-between">
          <button
            onClick={() => {
              onSelect('', ''); // Thay vì null, sử dụng chuỗi rỗng
              onClose();
            }}
            className="bg-gray-200 text-gray-700 p-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Xóa
          </button>
          <button
            onClick={handleApply}
            className="bg-primary-500 text-white p-2 rounded-md hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500"
          >
            Áp dụng
          </button>
        </div>
      </div>
    </div>
  );
};

const AreaPopup = ({ onClose, onSelect, currentMin, currentMax }) => {
  const [range, setRange] = useState([currentMin || 0, currentMax || 200]);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleApply = () => {
    const minArea = range[0] === 0 ? '' : range[0].toString();
    const maxArea = range[1] === 200 ? '' : range[1].toString();
    onSelect(minArea, maxArea);
    onClose();
  };

  const quickSelectOptions = [
    { label: 'Tất cả', min: 0, max: 200 },
    { label: 'Dưới 20 m2', min: 0, max: 20 },
    { label: '20 - 30 m²', min: 20, max: 30 },
    { label: '30 - 50 m²', min: 30, max: 50 },
    { label: '50 - 70 m²', min: 50, max: 70 },
    { label: '70 - 90 m²', min: 70, max: 90 },
    { label: 'Trên 90 m²', min: 90, max: 200 },
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg w-96 max-h-[80vh] flex flex-col">
        <div className="p-4 border-b flex justify-between items-center">
          <span className="font-semibold">Chọn diện tích</span>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <FaTimes />
          </button>
        </div>
        <div className="p-4 overflow-y-auto">
          <div className="mb-4">
            <div className="text-center mb-2 font-semibold">
              {range[0]} m² - {range[1]} m²
            </div>
            <RcSlider
              range
              min={0}
              max={200}
              step={5}
              value={range}
              onChange={(newRange) => setRange(newRange)}
            />
            <div className="flex justify-between mt-2">
              <span>0 m²</span>
              <span>200 m²</span>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-2">
            {quickSelectOptions.map((option, index) => (
              <button
                key={index}
                className={`p-2 text-sm text-center rounded ${
                  selectedOption === option ? 'bg-primary-100 text-primary-600' : 'bg-gray-100 hover:bg-gray-200'
                }`}
                onClick={() => {
                  setSelectedOption(option);
                  setRange([option.min, option.max]);
                }}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
        <div className="p-4 border-t flex justify-between">
          <button
            onClick={() => {
              onSelect('', ''); // Thay vì null, sử dụng chuỗi rỗng
              onClose();
            }}
            className="bg-gray-200 text-gray-700 p-2 rounded-md hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Xóa
          </button>
          <button
            onClick={handleApply}
            className="bg-primary-500 text-white p-2 rounded-md hover:bg-primary-600 focus:outline-none focus:ring-2 focus:ring-primary-500"
          >
            Áp dụng
          </button>
        </div>
      </div>
    </div>
  );
};

const TypePopup = ({ onClose, onSelect, currentType }) => {
  const types = [
    { value: 'Căn hộ', label: 'Căn hộ' },
    { value: 'Nhà nguyên căn', label: 'Nhà nguyên căn' },
    { value: 'Phòng trọ', label: 'Phòng trọ' },
    { value: 'Mặt bằng', label: 'Mặt bằng' },
    { value: 'Ở ghép', label: 'Tìm người ở ghép' },
    
  ];

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 w-96">
        <h3 className="text-lg font-semibold mb-4">Chọn loại nhà</h3>
        <ul className="divide-y divide-gray-200">
          {types.map((type, index) => (
            <li 
              key={type.value}
              onClick={() => {
                onSelect(type.value);
                onClose();
              }}
              className={`cursor-pointer p-2 hover:bg-gray-100 transition duration-150 ease-in-out ${
                currentType === type.value ? 'bg-primary-100' : ''
              } ${index === types.length - 1 ? '' : 'border-b border-gray-200'}`}
            >
              {type.label}
            </li>
          ))}
        </ul>
        <button 
          onClick={onClose}
          className="mt-4 w-full bg-secondary-500 text-white p-2 rounded-md hover:bg-secondary-600 focus:outline-none focus:ring-2 focus:ring-secondary-500 transition duration-150 ease-in-out"
        >
          Đóng
        </button>
      </div>
    </div>
  );
};

export default SearchBar;
