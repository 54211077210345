import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Spin, Tag, Breadcrumb } from 'antd';
import { Link } from 'react-router-dom';
import api from '../api';

const getCategoryColor = (category) => {
    switch (category) {
        case 'tin-tuc':
            return 'blue'; // Màu cho Tin tức
        case 'huong-dan':
            return 'green'; // Màu cho Hướng dẫn
        case 'thong-bao':
            return 'orange'; // Màu cho Thông báo
        case 'khac':
            return 'purple'; // Màu cho các danh mục khác
        default:
            return 'gray'; // Màu mặc định
    }
};

const NewsDetail = () => {
    const [news, setNews] = useState(null);
    const [loading, setLoading] = useState(true);
    const { id } = useParams();

    useEffect(() => {
        fetchNewsDetail();
    }, [id]);

    const fetchNewsDetail = async () => {
        try {
            const response = await api.get(`/news/${id}`);
            if (response.data.success) {
                setNews(response.data.data);
            }
        } catch (error) {
            console.error('Error fetching news detail:', error);
        } finally {
            setLoading(false);
        }
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center min-h-screen">
                <Spin size="large" />
            </div>
        );
    }

    if (!news) {
        return <div>Không tìm thấy tin tức</div>;
    }

    return (
        <div className="container mx-auto px-4 py-8">
            <Breadcrumb className="mb-6">
                <Breadcrumb.Item>
                    <Link to="/">Trang chủ</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <Link to="/news">Tin tức</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>{news.title}</Breadcrumb.Item>
            </Breadcrumb>

            <article className="max-w-4xl mx-auto">
                <header className="mb-8">
                    <Tag color={getCategoryColor(news.category)} className="mb-4">
                        {news.category_name}
                    </Tag>
                    <h1 className="text-4xl font-bold mb-4">{news.title}</h1>
                    <div className="text-gray-600 mb-4">
                        <span>{new Date(news.created_at).toLocaleDateString()}</span>
                        <span className="mx-2">•</span>
                        <span>Tác giả: {news.author_name}</span>
                    </div>
                    {news.description && (
                        <p className="text-xl text-gray-600 mb-6">{news.description}</p>
                    )}
                    {news.thumbnail && (
                        <img
                            src={`https://thuenhadedang.io.vn${news.thumbnail}`}
                            alt={news.title}
                            className="w-full rounded-lg mb-8"
                        />
                    )}
                </header>

                <div 
                    className="prose prose-lg max-w-none"
                    dangerouslySetInnerHTML={{ __html: news.content }}
                />
            </article>
        </div>
    );
};

export default NewsDetail;