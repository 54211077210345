import React, { useState, useEffect } from 'react';
import axios from 'axios';
import ListingForm from './ListingForm';
import { useNavigate } from 'react-router-dom';
import { Form, Input, InputNumber, Select, Upload, Button, message, Row, Col, Modal, Alert } from 'antd';
import { UploadOutlined, PlusOutlined, LoadingOutlined } from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
const { Option } = Select; // Thêm dòng này

const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full" id="my-modal">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">Xác nhận xóa</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">
              Bạn có chắc chắn muốn xóa tin đăng này? Hành động này không thể hoàn tác.
            </p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              id="ok-btn"
              className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
              onClick={onConfirm}
            >
              Xóa
            </button>
            <button
              id="cancel-btn"
              className="mt-3 px-4 py-2 bg-white text-gray-800 text-base font-medium rounded-md w-full shadow-sm border border-gray-300 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300"
              onClick={onClose}
            >
              Hủy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EditListingModal = ({ isOpen, onClose, onSubmit, listing }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [editorData, setEditorData] = useState('');
  
  // Thêm state cho preview ảnh
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState('');
  const [previewTitle, setPreviewTitle] = useState('');
  
  // State cho địa chỉ
  const [provinces, setProvinces] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);

  // Thêm state cho video và danh sách ảnh đã xóa
  const [videoFile, setVideoFile] = useState(null);
  const [videoPreview, setVideoPreview] = useState('');
  const [deletedImages, setDeletedImages] = useState([]);

  // Fetch data khi modal mở
  useEffect(() => {
    if (isOpen && listing) {
      fetchProvinces();
      initializeFormData();
    }
  }, [isOpen, listing]);

  // Fetch provinces từ API địa giới hành chính
  const fetchProvinces = async () => {
    try {
      const response = await fetch('https://raw.githubusercontent.com/kenzouno1/DiaGioiHanhChinhVN/master/data.json');
      const data = await response.json();
      setProvinces(data);
      
      if (listing) {
        const currentProvince = data.find(p => p.Name === listing.city);
        if (currentProvince) {
          setDistricts(currentProvince.Districts);
          const currentDistrict = currentProvince.Districts.find(d => d.Name === listing.district);
          if (currentDistrict) {
            setWards(currentDistrict.Wards);
          }
        }
      }
    } catch (error) {
      message.error('Lỗi khi tải danh sách tỉnh thành');
    }
  };

  // Khởi tạo form data
  const initializeFormData = () => {
    if (!listing) return;
    console.log('Initializing form with listing:', listing);

    let images = [];
    
    // Xử lý thumbnail (ảnh đại diện)
    if (listing.thumbnail) {
      const thumbnailUrl = listing.thumbnail.startsWith('http') 
        ? listing.thumbnail 
        : `https://thuenhadedang.io.vn${listing.thumbnail}`;
      
      images.push({
        uid: 'thumbnail',
        name: 'Ảnh đại diện',
        status: 'done',
        url: thumbnailUrl,
        isMain: true
      });
    }

    // Xử lý ảnh bổ sung
    if (listing.images && Array.isArray(listing.images)) {
      const additionalImages = listing.images.map((image, index) => {
        // Xử lý cả trường hợp image là object hoặc string
        const imageUrl = typeof image === 'object' ? image.image_url : image;
        const fullUrl = imageUrl.startsWith('http') 
          ? imageUrl 
          : `https://thuenhadedang.io.vn${imageUrl}`;

        return {
          uid: `image-${index}`,
          name: `Ảnh bổ sung ${index + 1}`,
          status: 'done',
          url: fullUrl,
          imageId: typeof image === 'object' ? image.id : null
        };
      });
      
      images = [...images, ...additionalImages];
    }

    console.log('Initialized images:', images);
    setFileList(images);

    // Set các giá trị khác của form
    form.setFieldsValue({
      title: listing.title,
      price: parseFloat(listing.price),
      area: parseFloat(listing.area),
      address: listing.address,
      ward: listing.ward,
      district: listing.district,
      city: listing.city,
      type: listing.type
    });
    
    setEditorData(listing.description || '');
  };

  // Handlers cho địa chỉ
  const handleProvinceChange = (value, option) => {
    const province = provinces.find(p => p.Name === option.children);
    form.setFieldsValue({ district: undefined, ward: undefined, city: option.children });
    if (province) {
      setDistricts(province.Districts);
      setWards([]);
    }
  };

  const handleDistrictChange = (value, option) => {
    const district = districts.find(d => d.Name === option.children);
    form.setFieldsValue({ ward: undefined, district: option.children });
    if (district) {
      setWards(district.Wards);
    }
  };

  const handleWardChange = (value, option) => {
    form.setFieldsValue({ ward: option.children });
  };

  // Handlers cho ảnh
  const handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewOpen(true);
    setPreviewImage(file.url || file.preview);
    setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
  };

  const handleImageChange = ({ fileList: newFileList }) => {
    console.log('New file list:', newFileList); // Debug log
    setFileList(newFileList);
  };

  const handlePreviewCancel = () => setPreviewOpen(false);

  // Upload component props
  const uploadProps = {
    listType: 'picture-card',
    fileList: fileList,
    onPreview: async (file) => {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      setPreviewImage(file.url || file.preview);
      setPreviewOpen(true);
      setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    },
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
    },
    onRemove: (file) => {
      if (file.url) {
        setDeletedImages(prev => [...prev, file.url]);
      }
      return true;
    },
    beforeUpload: (file) => {
      return false; // Ngăn upload tự động
    }
  };

  // Video upload props
  const videoUploadProps = {
    accept: 'video/mp4,video/webm',
    beforeUpload: (file) => {
      const isVideo = file.type === 'video/mp4' || file.type === 'video/webm';
      const isLt50M = file.size / 1024 / 1024 < 50;

      if (!isVideo) {
        message.error('Chỉ hỗ trợ định dạng MP4 hoặc WebM!');
        return false;
      }
      if (!isLt50M) {
        message.error('Video phải nhỏ hơn 50MB!');
        return false;
      }

      setVideoFile(file);
      // Tạo preview URL cho video
      const videoUrl = URL.createObjectURL(file);
      setVideoPreview(videoUrl);
      return false;
    },
    onRemove: () => {
      setVideoFile(null);
      setVideoPreview('');
    }
  };

  // Submit form
  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      console.log('Form values:', values);

      const formData = new FormData();
      
      // Thêm các trường cơ bản từ form
      if (values && typeof values === 'object') {
        for (const [key, value] of Object.entries(values)) {
          if (key !== 'images' && value !== undefined) {
            formData.append(key, value);
          }
        }
      }

      // Thêm mô tả từ CKEditor
      if (editorData) {
        formData.append('description', editorData);
      }

      // Thêm danh sách ảnh đã xóa
      if (deletedImages && Array.isArray(deletedImages) && deletedImages.length > 0) {
        formData.append('deleted_images', JSON.stringify(deletedImages));
      }

      // Thêm ảnh mới
      if (fileList && Array.isArray(fileList)) {
        for (let i = 0; i < fileList.length; i++) {
          const file = fileList[i];
          if (file.originFileObj) {
            formData.append(`new_images[${i}]`, file.originFileObj);
          }
        }
      }

      // Thêm video mới nếu có
      if (videoFile) {
        formData.append('video', videoFile);
      }

      // Debug log
      console.log('FormData entries:');
      for (let pair of formData.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      // Kiểm tra listing ID
      if (!listing?.id) {
        throw new Error('Listing ID not found');
      }

      // Gọi API cập nhật
      const success = await onSubmit(formData, listing.id);
      
      if (success) {
        message.success('Cập nhật tin đăng thành công');
        // Reset form và states
        form.resetFields();
        setFileList([]);
        setVideoFile(null);
        setVideoPreview('');
        setDeletedImages([]);
        setEditorData('');
        onClose();
      }

    } catch (error) {
      console.error('Error submitting listing:', error);
      message.error('Có lỗi xảy ra khi cập nhật tin đăng: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  // Thêm component hiển thị thông tin gói tin hiện tại
  const CurrentPackageInfo = ({ listing }) => {
    if (!listing || !listing.current_package) return null;

    const packageInfo = {
      ...listing.current_package,
      is_vip: Boolean(listing.current_package.is_vip)
    };

    return (
      <div className="mb-4 p-4 bg-blue-50 rounded-md">
        <h3 className="font-medium text-blue-700 mb-2">Gói tin hiện tại</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-gray-600">Tên gói:</p>
            <p className="font-medium">{packageInfo.name}</p>
          </div>
          <div>
            <p className="text-gray-600">Giá:</p>
            <p className="font-medium text-blue-600">
              {Number(packageInfo.price).toLocaleString()}đ
            </p>
          </div>
          <div>
            <p className="text-gray-600">Ngày bắt đầu:</p>
            <p className="font-medium">
              {new Date(packageInfo.start_date).toLocaleDateString('vi-VN')}
            </p>
          </div>
          <div>
            <p className="text-gray-600">Ngày kết thúc:</p>
            <p className="font-medium">
              {new Date(packageInfo.end_date).toLocaleDateString('vi-VN')}
            </p>
          </div>
          <div>
            <p className="text-gray-600">Thời gian còn lại:</p>
            <p className="font-medium text-green-600">
              {packageInfo.remaining_days} ngày
            </p>
          </div>
          <div>
            <p className="text-gray-600">Loại gói:</p>
            <p className="font-medium">
              {packageInfo.is_vip ? 'VIP' : 'Thường'}
            </p>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <Modal
        title="Chỉnh sửa tin đăng"
        open={isOpen}
        onCancel={onClose}
        footer={null}
        width={800}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={listing} // Thêm initialValues
        >
          {/* Hiển thị thông tin gói tin hiện tại */}
          <CurrentPackageInfo listing={listing} />

          {/* Form fields */}
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="title"
                label="Tiêu đề"
                rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Mô tả chi tiết">
                <CKEditor
                  editor={ClassicEditor}
                  data={editorData}
                  onChange={(event, editor) => setEditorData(editor.getData())}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name="price"
                label="Giá thuê (VNĐ/tháng)"
                rules={[{ required: true, message: 'Vui lòng nhập giá thuê' }]}
              >
                <InputNumber
                  style={{ width: '100%' }}
                  formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={value => value.replace(/\$\s?|(,*)/g, '')}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="area"
                label="Diện tích (m²)"
                rules={[{ required: true, message: 'Vui lòng nhập diện tích' }]}
              >
                <InputNumber style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="type"
            label="Loại phòng"
            rules={[{ required: true, message: 'Vui lòng chọn loại phòng' }]}
          >
            <Select>
              <Option value="Phòng trọ">Phòng trọ</Option>
              <Option value="Căn hộ">Căn hộ</Option>
              <Option value="Nhà nguyên căn">Nhà nguyên căn</Option>
              <Option value="Mặt bằng">Mặt bằng</Option>
              <Option value="Ở ghép">Ở ghép</Option>
            </Select>
          </Form.Item>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="city"
                label="Tỉnh/Thành phố"
                rules={[{ required: true }]}
              >
                <Select onChange={handleProvinceChange}>
                  {provinces.map(province => (
                    <Option key={province.Id} value={province.Name}>
                      {province.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="district"
                label="Quận/Huyện"
                rules={[{ required: true }]}
              >
                <Select onChange={handleDistrictChange}>
                  {districts.map(district => (
                    <Option key={district.Id} value={district.Name}>
                      {district.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="ward"
                label="Phường/Xã"
                rules={[{ required: true }]}
              >
                <Select onChange={handleWardChange}>
                  {wards.map(ward => (
                    <Option key={ward.Id} value={ward.Name}>
                      {ward.Name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item
            name="address"
            label="Địa chỉ cụ thể"
            rules={[{ required: true }]}
          >
            <Input />
          </Form.Item>

          {/* Upload ảnh */}
          <Form.Item label="Hình ảnh">
            <Upload
              listType="picture-card"
              fileList={fileList}
              onPreview={async (file) => {
                if (!file.url && !file.preview) {
                  file.preview = await getBase64(file.originFileObj);
                }
                setPreviewImage(file.url || file.preview);
                setPreviewOpen(true);
                setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
              }}
              onChange={({ fileList: newFileList }) => {
                console.log('New file list:', newFileList);
                setFileList(newFileList);
              }}
              onRemove={(file) => {
                if (file.imageId) {
                  setDeletedImages(prev => [...prev, file.imageId]);
                }
                return true;
              }}
              beforeUpload={() => false}
            >
              <div>
                <PlusOutlined />
                <div style={{ marginTop: 8 }}>Tải ảnh</div>
              </div>
            </Upload>
          </Form.Item>

          {/* Upload video */}
          <Form.Item
            label="Video"
            extra="Hỗ trợ MP4, WebM dưới 50MB"
          >
            <Upload {...videoUploadProps}>
              <Button icon={<UploadOutlined />}>Tải video</Button>
            </Upload>
            {videoPreview && (
              <video
                src={videoPreview}
                controls
                style={{ marginTop: 8, maxWidth: '100%', maxHeight: 200 }}
              />
            )}
            {listing && listing.video_url && !videoPreview && (
              <video
                src={`https://thuenhadedang.io.vn${listing.video_url}`}
                controls
                style={{ marginTop: 8, maxWidth: '100%', maxHeight: 200 }}
              />
            )}
          </Form.Item>

          {/* Submit button */}
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-full"
            >
              Cập nhật
            </Button>
          </Form.Item>
        </Form>

        {/* Image preview modal */}
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={() => setPreviewOpen(false)}
        >
          <img alt="preview" style={{ width: '100%' }} src={previewImage} />
        </Modal>
      </Modal>
    </>
  );
};

const ManageListings = () => {
  // Thêm state cho user
  const [currentUser, setCurrentUser] = useState(null);
  const [listings, setListings] = useState([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentListing, setCurrentListing] = useState(null);
  const [listingToDelete, setListingToDelete] = useState(null);
  const [isRenewalModalOpen, setIsRenewalModalOpen] = useState(false);
  const [listingToRenew, setListingToRenew] = useState(null);
  // Thêm state cho packages
  const [packages, setPackages] = useState([]);
  const navigate = useNavigate();

  // Thêm state cho ảnh placeholder
  const [placeholderImage] = useState('https://via.placeholder.com/300x200?text=No+Image');

  // Thêm loading state
  const [isLoading, setIsLoading] = useState(true);

  // Thêm useEffect để theo dõi thông tin user
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      // Chuyển đổi các giá trị is_ thành boolean
      user.is_landlord = Boolean(user.is_landlord);
      user.is_admin = Boolean(user.is_admin);
      user.is_verified = Boolean(user.is_verified);
      setCurrentUser(user);
    }

    // Thêm event listener để cập nhật user khi có thay đổi
    const handleStorageChange = () => {
      const updatedUser = JSON.parse(localStorage.getItem('user'));
      if (updatedUser) {
        // Chuyển đổi các giá trị is_ thành boolean
        updatedUser.is_landlord = Boolean(updatedUser.is_landlord);
        updatedUser.is_admin = Boolean(updatedUser.is_admin);
        updatedUser.is_verified = Boolean(updatedUser.is_verified);
        setCurrentUser(updatedUser);
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, []);

  // Sửa lại hàm handleEditListing
  const handleEditListing = (listing) => {
    if (!currentUser?.is_landlord) {
      message.error('Bạn cần là chủ trọ để có thể sửa tin đăng');
      return;
    }
    setCurrentListing(listing);
    setIsEditModalOpen(true);
  };

  useEffect(() => {
    fetchListings();
  }, []);

  const fetchListings = async () => {
    try {
      setIsLoading(true); // Bắt đầu loading
      const response = await axios.get('https://thuenhadedang.io.vn/api/listings/user', {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`
        }
      });
      setListings(Array.isArray(response.data) ? response.data : []);
    } catch (error) {
      console.error('Error fetching listings:', error);
      setListings([]);
    } finally {
      setIsLoading(false); // Kết thúc loading bất kể thành công hay thất bại
    }
  };

  const handleClose = () => {
    setIsEditModalOpen(false);
    setCurrentListing(null);
  };
  const handleDeleteClick = (listing) => {
    setListingToDelete(listing);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (listingToDelete) {
      try {
        await axios.delete(`https://thuenhadedang.io.vn/api/listings/${listingToDelete.id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`
          }
        });
        fetchListings();
        setIsDeleteModalOpen(false);
      } catch (error) {
        console.error('Error deleting listing:', error);
      }
    }
  };

  const handleSubmit = async (formData, listingId) => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        message.error('Vui lòng đăng nhập lại');
        return false;
      }

      const response = await axios.post(
        `https://thuenhadedang.io.vn/api/listings/update/${listingId}`, 
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            'Authorization': `Bearer ${token}`
          },
          withCredentials: true
        }
      );

      if (response.data.success) {
        message.success('Cập nhật tin đăng thành công');
        fetchListings();
        return true;
      } else {
        throw new Error(response.data.error || 'Có lỗi xảy ra');
      }
    } catch (error) {
      console.error("Error submitting listing:", error);
      if (error.response) {
        message.error(error.response.data.error || "Có lỗi xảy ra khi xử lý tin đăng");
      } else if (error.request) {
        message.error("Không thể kết nối đến server");
      } else {
        message.error("Có lỗi xảy ra: " + error.message);
      }
      return false;
    }
  };

  const handleAddListing = () => {
    navigate('/create-listing');
  };

  const handleToggleStatus = async (listing) => {
    try {
        // Kiểm tra nếu tin đã hết hạn và đang cố gắng hiện tin
        if (listing.status === 'inactive' && listing.package_expires_at && new Date(listing.package_expires_at) < new Date()) {
            message.error('Không thể hiện tin đã hết hạn. Vui lòng tạo gói tin mới để tiếp tục hiển thị.');
            return;
        }

        const newStatus = listing.status === 'active' ? 'inactive' : 'active';
        await axios.post(`https://thuenhadedang.io.vn/api/listings/toggle-status/${listing.id}`, 
            { status: newStatus }, 
            {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            }
        );
        fetchListings(); // Refresh listings after toggling
    } catch (error) {
        console.error('Error toggling status:', error);
        message.error(error.response?.data?.error || 'Có lỗi xảy ra khi thay đổi trạng thái');
    }
  };

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 pt-20">
      <div className="flex justify-between items-center mb-8">
        
        {/* <button
          onClick={handleAddListing}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
        >
          <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
          </svg>
          Đăng tin mới
        </button> */}
      </div>

      <div className="grid gap-6">
        {listings.length > 0 ? (
          listings.map((listing) => (
            <div key={listing.id} className="bg-white rounded-lg shadow-lg overflow-hidden transition-shadow hover:shadow-xl">
              <div className="flex flex-col md:flex-row h-[280px]">
                {/* Phần ảnh */}
                <div className="md:w-[320px] relative">
                  <img
                    src={listing.thumbnail 
                      ? (listing.thumbnail.startsWith('http') 
                          ? listing.thumbnail 
                          : `https://thuenhadedang.io.vn${listing.thumbnail}`)
                      : placeholderImage
                    }
                    alt={listing.title}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                      e.target.onerror = null;
                      e.target.src = placeholderImage;
                    }}
                  />
                  {/* Badges góc phải */}
                  <div className="absolute top-3 right-3 flex flex-col gap-2">
                    {Boolean(listing.is_vip) && (
                      <span className="px-3 py-1.5 bg-yellow-500 text-white text-xs font-semibold rounded-full shadow-md">
                        VIP
                      </span>
                    )}
                    <span className={`px-3 py-1.5 text-xs font-semibold rounded-full shadow-md ${
                      listing.status === 'active' ? 'bg-green-500 text-white' : 
                      listing.status === 'pending' ? 'bg-yellow-500 text-white' : 
                      'bg-red-500 text-white'
                    }`}>
                      {listing.status === 'active' ? 'Đang hiển thị' : 
                       listing.status === 'pending' ? 'Chờ duyệt' : 
                       'Đã ẩn'}
                    </span>
                  </div>
                  {/* Badge loại phòng góc trái */}
                  <div className="absolute top-3 left-3">
                    <span className="px-3 py-1.5 bg-blue-600 text-white text-xs font-semibold rounded-md shadow-md">
                      {listing.type}
                    </span>
                  </div>
                </div>

                {/* Phần thông tin */}
                <div className="flex-1 p-6 flex flex-col justify-between overflow-hidden">
                  <div>
                    <h2 className="text-xl font-bold text-gray-900 mb-3 line-clamp-1">{listing.title}</h2>
                    
                    <div className="space-y-3">
                      {/* Địa chỉ */}
                      <p className="flex items-center text-gray-600 text-sm line-clamp-1">
                        <svg className="h-4 w-4 mr-2 flex-shrink-0" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M5.05 4.05a7 7 0 119.9 9.9L10 18.9l-4.95-4.95a7 7 0 010-9.9zM10 11a2 2 0 100-4 2 2 0 000 4z" clipRule="evenodd" />
                        </svg>
                        <span className="line-clamp-1">{listing.address}, {listing.ward}, {listing.district}, {listing.city}</span>
                      </p>

                      {/* Thông tin chính */}
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex items-center">
                          <svg className="h-4 w-4 text-gray-500 mr-2 flex-shrink-0" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M4 8h16M4 16h16" />
                          </svg>
                          <span className="text-gray-900 text-sm">{listing.area} m²</span>
                        </div>
                        <div className="flex items-center">
                          <svg className="h-4 w-4 text-green-500 mr-2 flex-shrink-0" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                          <span className="text-green-600 font-bold text-sm">{Number(listing.price).toLocaleString()}đ/tháng</span>
                        </div>
                      </div>

                      {/* Thống kê */}
                      <div className="grid grid-cols-2 gap-4">
                        <div className="flex items-center">
                          <svg className="h-5 w-5 text-gray-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                            <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                          </svg>
                          <span>
                            {isLoading ? (
                              <span className="animate-pulse bg-gray-200 rounded h-4 w-16 inline-block"></span>
                            ) : (
                              listing.views > 0 ? `${listing.views} lượt xem` : "Chưa có lượt xem"
                            )}
                          </span>
                        </div>
                        {/* <div className="flex items-center">
                          <svg className="h-5 w-5 text-gray-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                          </svg>
                          <span>
                            {isLoading ? (
                              <span className="animate-pulse bg-gray-200 rounded h-4 w-16 inline-block"></span>
                            ) : (
                              listing.contact_count > 0 ? `${listing.contact_count} lượt gọi` : "Chưa có lượt gọi"
                            )}
                          </span>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  {/* Buttons */}
                  <div className="flex justify-end gap-2 mt-4 pt-3 border-t">
                    {listing.status === 'active' && currentUser?.is_landlord && (
                      <button
                        onClick={() => handleEditListing(listing)}
                        className="inline-flex items-center px-4 py-2 border border-blue-600 rounded-md text-sm font-medium text-blue-600 hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        <svg className="h-4 w-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                        </svg>
                        Sửa tin
                      </button>
                    )}

                    <button
                      onClick={() => handleToggleStatus(listing)}
                      className="inline-flex items-center px-4 py-2 border border-gray-600 rounded-md text-sm font-medium text-gray-600 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                      {listing.status === 'active' ? 'Ẩn tin' : 'Hiện tin'}
                    </button>

                    <button
                      onClick={() => handleDeleteClick(listing)}
                      className="inline-flex items-center px-4 py-2 border border-red-600 rounded-md text-sm font-medium text-red-600 hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
                    >
                      <svg className="h-4 w-4 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                      </svg>
                      Xóa
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center py-12 bg-white rounded-lg shadow">
            <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 13h6m-3-3v6m5 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">Chưa có tin đăng</h3>
            <p className="mt-1 text-sm text-gray-500">Bt đầu bằng việc tạo tin đăng mới.</p>
            <div className="mt-6">
              <button
                onClick={handleAddListing}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
              >
                <svg className="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                </svg>
                Đăng tin ngay
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Modals */}
      <EditListingModal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setCurrentListing(null);
        }}
        onSubmit={handleSubmit}
        listing={currentListing}
      />

      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteConfirm}
      />
    </div>
  );
};

const getBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
};

export default ManageListings;
