import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Tag, Pagination, Input, Select, Spin } from 'antd';
import { Link } from 'react-router-dom';
import api from '../api';

const { Search } = Input;
const { Option } = Select;

const NewsPage = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchText, setSearchText] = useState('');
    const [category, setCategory] = useState('all');
    const pageSize = 9;

    useEffect(() => {
        fetchNews();
    }, [currentPage, searchText, category]);

    const fetchNews = async () => {
        setLoading(true);
        try {
            const response = await api.get('/news/public', {
                params: {
                    page: currentPage,
                    limit: pageSize,
                    search: searchText,
                    category: category !== 'all' ? category : ''
                }
            });

            if (response.data && response.data.success) {
                setNews(response.data.data);
                setTotal(response.data.total);
            }
        } catch (error) {
            console.error('Error fetching news:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleCategoryChange = (value) => {
        setCategory(value);
        setCurrentPage(1);
    };

    const getCategoryLabel = (category) => {
        switch(category) {
            case 'tin-tuc': return 'Tin tức';
            case 'huong-dan': return 'Hướng dẫn';
            case 'thong-bao': return 'Thông báo';
            case 'khac': return 'Tin khác';
            default: return 'Tin khác';
        }
    };

    const getCategoryColor = (category) => {
        switch (category) {
            case 'tin-tuc':
                return 'blue'; // Màu cho Tin tức
            case 'huong-dan':
                return 'green'; // Màu cho Hướng dẫn
            case 'thong-bao':
                return 'orange'; // Màu cho Thông báo
            case 'khac':
                return 'purple'; // Màu cho các danh mục khác
            default:
                return 'gray'; // Màu mặc định
        }
    };

    return (
        <div className="max-w-6xl mx-auto px-4 py-6">
            <div className="text-center mb-8">
                <h1 className="text-3xl font-bold text-gray-900 mb-6">Tin Tức</h1>
                
                {/* <div className="flex flex-col sm:flex-row justify-center gap-4 max-w-2xl mx-auto">
                    <Select 
                        defaultValue="all" 
                        onChange={handleCategoryChange}
                        className="w-full sm:w-48"
                    >
                        <Option value="all">Tất cả</Option>
                        <Option value="tin-tuc">Tin tức</Option>
                        <Option value="huong-dan">Hướng dẫn</Option>
                        <Option value="thong-bao">Thông báo</Option>
                        <Option value="khac">Tin khác</Option>
                    </Select>
                </div> */}
            </div>

            <Spin spinning={loading}>
                <Row gutter={[16, 16]}>
                    {news.map(item => (
                        <Col xs={24} sm={12} lg={8} key={item.id}>
                            <Link to={`/news/${item.id}`}>
                                <Card
                                    hoverable
                                    className="h-full"
                                    cover={
                                        <div className="h-48 overflow-hidden">
                                            <img
                                                alt={item.title}
                                                src={item.thumbnail?.startsWith('http') 
                                                    ? item.thumbnail 
                                                    : `https://thuenhadedang.io.vn${item.thumbnail}`}
                                                className="w-full h-full object-cover"
                                                onError={(e) => {
                                                    e.target.src = '/placeholder-news.jpg';
                                                }}
                                            />
                                        </div>
                                    }
                                >
                                    <div className="flex flex-col h-full">
                                        <Tag className={`mb-2 self-start tag-${item.category}`} color={getCategoryColor(item.category)}>
                                            {item.category_name}
                                        </Tag>
                                        
                                        <h2 className="text-lg font-semibold mb-2 line-clamp-2 hover:text-blue-600">
                                            {item.title}
                                        </h2>
                                        
                                        <p className="text-gray-600 text-sm line-clamp-2 mb-3">
                                            {item.description}
                                        </p>
                                        
                                        <div className="text-xs text-gray-500 mt-auto">
                                            {new Date(item.created_at).toLocaleDateString('vi-VN')}
                                            <span className="mx-2">•</span>
                                            {item.author_name}
                                        </div>
                                    </div>
                                </Card>
                            </Link>
                        </Col>
                    ))}
                </Row>
            </Spin>

            {total > pageSize && (
                <div className="mt-6 flex justify-center">
                    <Pagination
                        current={currentPage}
                        total={total}
                        pageSize={pageSize}
                        onChange={setCurrentPage}
                        showSizeChanger={false}
                    />
                </div>
            )}
        </div>
    );
};

export default NewsPage;
