import React from 'react';
import Header from '../Header';
import Footer from '../Footer';

const MainLayout = ({ children, onSearchResults, onSearchTypeChange }) => {
  return (
    <div className="flex flex-col min-h-screen">
      <Header 
        onSearchResults={onSearchResults} 
        onSearchTypeChange={onSearchTypeChange}
      />
      <main className="flex-grow">
        {children}
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;