import React, { useState, useEffect } from 'react';
import { Table, Button, message, Modal, Input, Tag, Image, Tabs, Space, Typography, Alert, Upload } from 'antd';
import { EyeOutlined, CheckOutlined, CloseOutlined, ReloadOutlined, UploadOutlined } from '@ant-design/icons';
import { getAdminTransactions, approveTransaction, rejectTransaction } from '../../api';

const { Text } = Typography;
const { TabPane } = Tabs;

const AdminTransactions = () => {
    const [transactions, setTransactions] = useState({
        deposits: [],
        packages: []
    });
    const [loading, setLoading] = useState(false);
    const [actionLoading, setActionLoading] = useState({});
    const [rejectModalVisible, setRejectModalVisible] = useState(false);
    const [rejectReason, setRejectReason] = useState('');
    const [selectedTransactionId, setSelectedTransactionId] = useState(null);
    const [viewProofModal, setViewProofModal] = useState(false);
    const [selectedTransaction, setSelectedTransaction] = useState(null);

    const fetchTransactions = async () => {
        try {
            setLoading(true);
            const response = await getAdminTransactions();
            console.log('Fetched transactions:', response);

            if (response.success && response.data) {
                // Format dữ liệu trước khi set vào state
                const formattedDeposits = (response.data.deposits || []).map(t => ({
                    ...t,
                    key: t.id,
                    isDeposit: true,
                    amount: parseFloat(t.amount || 0)
                }));

                const formattedPackages = (response.data.packages || []).map(t => ({
                    ...t,
                    key: t.id,
                    isDeposit: false,
                    amount: parseFloat(t.amount || 0)
                }));

                setTransactions({
                    deposits: formattedDeposits,
                    packages: formattedPackages
                });
            }
        } catch (error) {
            console.error('Error fetching transactions:', error);
            message.error('Không thể tải danh sách giao dịch');
        } finally {
            setLoading(false);
        }
    };

    const handleApprove = async (id) => {
        try {
            setActionLoading(prev => ({ ...prev, [id]: true }));
            const response = await approveTransaction(id);
            
            if (response.success) {
                message.success('Đã duyệt giao dịch thành công');
                fetchTransactions(); // Refresh danh sách
            } else {
                message.error('Không thể duyệt giao dịch');
            }
        } catch (error) {
            console.error('Error approving transaction:', error);
            message.error('Có lỗi xảy ra khi duyệt giao dịch');
        } finally {
            setActionLoading(prev => ({ ...prev, [id]: false }));
        }
    };

    const handleReject = async () => {
        try {
            if (!rejectReason.trim()) {
                message.error('Vui lòng nhập lý do từ chối');
                return;
            }

            setActionLoading(prev => ({ ...prev, [selectedTransactionId]: true }));
            const response = await rejectTransaction(selectedTransactionId, rejectReason);
            
            if (response.success) {
                message.success('Đã từ chối giao dịch');
                setRejectModalVisible(false);
                setRejectReason('');
                fetchTransactions(); // Refresh danh sách
            } else {
                message.error('Không thể từ chối giao dịch');
            }
        } catch (error) {
            console.error('Error rejecting transaction:', error);
            message.error('Có lỗi xảy ra khi từ chối giao dịch');
        } finally {
            setActionLoading(prev => ({ ...prev, [selectedTransactionId]: false }));
        }
    };

    const showRejectModal = (id) => {
        setSelectedTransactionId(id);
        setRejectModalVisible(true);
    };

    useEffect(() => {
        fetchTransactions();
        const interval = setInterval(fetchTransactions, 30000); // Refresh every 30 seconds
        return () => clearInterval(interval);
    }, []);

    const columns = [
        {
            title: 'Mã GD',
            dataIndex: 'id',
            key: 'id',
            width: 100,
            render: id => <Tag color="blue">#{id}</Tag>
        },
        {
            title: 'Thời gian',
            dataIndex: 'created_at',
            key: 'created_at',
            width: 180,
            render: (text) => new Date(text).toLocaleString('vi-VN')
        },
        {
            title: 'Người dùng',
            dataIndex: ['user', 'full_name'],
            key: 'user_name',
            width: 200,
            render: (text, record) => (
                <div>
                    <div className="font-medium">{record.user?.full_name || 'N/A'}</div>
                    <Text type="secondary" className="text-sm">
                        {record.user?.email || 'N/A'}
                    </Text>
                </div>
            ),
        },
        {
            title: 'Số tiền',
            dataIndex: 'amount',
            key: 'amount',
            width: 150,
            render: (amount, record) => (
                <div>
                    <Text className={record.isDeposit ? 'text-green-500' : 'text-red-500'}>
                        <span className="font-medium">
                            {record.isDeposit ? '+' : '-'} {Number(amount).toLocaleString()}đ
                        </span>
                    </Text>
                    <div className="text-gray-400 text-xs">
                        {record.isDeposit ? '(Nạp tiền)' : '(Thanh toán dịch vụ)'}
                    </div>
                </div>
            )
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            render: status => {
                const statusConfig = {
                    pending: { color: 'gold', text: 'Chờ duyệt' },
                    approved: { color: 'green', text: 'Đã duyệt' },
                    rejected: { color: 'red', text: 'Từ chối' }
                };
                return (
                    <Tag color={statusConfig[status]?.color || 'default'}>
                        {statusConfig[status]?.text || status}
                    </Tag>
                );
            }
        },
        {
            title: 'Biên lai',
            key: 'proof',
            width: 120,
            render: (_, record) => (
                <Space>
                    {record.proof_image ? (
                        <Button
                            type="link"
                            size="small"
                            icon={<EyeOutlined />}
                            onClick={() => {
                                setSelectedTransaction(record);
                                setViewProofModal(true);
                            }}
                        >
                            Xem biên lai
                        </Button>
                    ) : (
                        <Tag color="default">Chưa có biên lai</Tag>
                    )}
                </Space>
            )
        },
        {
            title: 'Thao tác',
            key: 'action',
            width: 200,
            render: (_, record) => (
                <Space>
                    {record.status === 'pending' && (
                        <>
                            <Button
                                type="primary"
                                size="small"
                                icon={<CheckOutlined />}
                                onClick={() => handleApprove(record.id)}
                                loading={actionLoading[record.id]}
                                disabled={!record.proof_image}
                            >
                                Duyệt
                            </Button>
                            <Button
                                danger
                                size="small"
                                icon={<CloseOutlined />}
                                onClick={() => showRejectModal(record.id)}
                                loading={actionLoading[record.id]}
                            >
                                Từ chối
                            </Button>
                        </>
                    )}
                </Space>
            )
        }
    ];

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png' || file.type === 'image/jpg';
        if (!isJpgOrPng) {
            message.error('Chỉ chấp nhận file ảnh định dạng JPG, JPEG hoặc PNG');
            return false;
        }

        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('Kích thước file không được vượt quá 2MB');
            return false;
        }

        return true;
    };

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Quản lý giao dịch</h1>
                <Button
                    icon={<ReloadOutlined />}
                    onClick={fetchTransactions}
                    loading={loading}
                >
                    Làm mới
                </Button>
            </div>

            <Tabs defaultActiveKey="all">
                <TabPane tab="Tất cả" key="all">
                    <Table
                        columns={columns}
                        dataSource={[...transactions.deposits, ...transactions.packages]}
                        loading={loading}
                        pagination={{
                            pageSize: 10,
                            showTotal: (total) => `Tổng ${total} giao dịch`
                        }}
                    />
                </TabPane>
                <TabPane tab="Nạp tiền" key="deposits">
                    <Table
                        columns={columns}
                        dataSource={transactions.deposits}
                        loading={loading}
                        pagination={{
                            pageSize: 10,
                            showTotal: (total) => `Tổng ${total} giao dịch`
                        }}
                    />
                </TabPane>
            </Tabs>

            <Modal
                title="Từ chối giao dịch"
                open={rejectModalVisible}
                onOk={handleReject}
                onCancel={() => {
                    setRejectModalVisible(false);
                    setRejectReason('');
                }}
                okText="Xác nhận"
                cancelText="Hủy"
                confirmLoading={loading}
            >
                <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700 mb-2">
                        Lý do từ chối
                    </label>
                    <Input.TextArea
                        value={rejectReason}
                        onChange={(e) => setRejectReason(e.target.value)}
                        placeholder="Nhập lý do từ chối giao dịch"
                        rows={4}
                    />
                </div>
            </Modal>

            <Modal
                title={
                    <div>
                        <div>Biên lai thanh toán</div>
                        <div className="text-sm text-gray-500">
                            Mã giao dịch: #{selectedTransaction?.id}
                        </div>
                    </div>
                }
                open={viewProofModal}
                onCancel={() => setViewProofModal(false)}
                footer={null}
                width={800}
            >
                {selectedTransaction?.proof_image ? (
                    <div>
                        <div className="mb-4">
                            <p><strong>Người nạp:</strong> {selectedTransaction?.user?.full_name}</p>
                            <p><strong>Số tiền:</strong> {Number(selectedTransaction?.amount).toLocaleString()}đ</p>
                            <p><strong>Thời gian:</strong> {new Date(selectedTransaction?.created_at).toLocaleString('vi-VN')}</p>
                        </div>
                        <Image
                            src={selectedTransaction.proof_image}
                            alt="Biên lai thanh toán"
                            style={{ width: '100%' }}
                        />
                    </div>
                ) : (
                    <Alert
                        message="Không có biên lai"
                        description="Giao dịch này chưa có biên lai thanh toán được tải lên."
                        type="warning"
                    />
                )}
            </Modal>
        </div>
    );
};

export default AdminTransactions;
