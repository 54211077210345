import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Form, Input, Button, Card, Divider, message as antMessage, Modal } from 'antd';
import { UserOutlined, LockOutlined, GoogleOutlined, MailOutlined } from '@ant-design/icons';
import { useAuth } from '../contexts/AuthContext';
import { resendVerification, forgotPassword, resetPassword, googleLogin } from '../api';
import { GoogleLogin } from '@react-oauth/google';

function Login() {
  const [loading, setLoading] = useState(false);
  const [showResendEmail, setShowResendEmail] = useState(false);
  const [unverifiedEmail, setUnverifiedEmail] = useState('');
  const navigate = useNavigate();
  const { login, updateUser } = useAuth();
  const [form] = Form.useForm();
  const [resendLoading, setResendLoading] = useState(false);
  const [forgotPasswordVisible, setForgotPasswordVisible] = useState(false);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const [forgotPasswordForm] = Form.useForm();
  const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
  const [resetPasswordLoading, setResetPasswordLoading] = useState(false);
  const [resetPasswordForm] = Form.useForm();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const verified = params.get('verified');
    const messageText = params.get('message');
    const error = params.get('error');
    
    if (verified === 'true' && messageText) {
      antMessage.success(messageText);
      params.delete('verified');
      params.delete('message');
      params.delete('error');
      const newUrl = window.location.pathname + (params.toString() ? `?${params.toString()}` : '');
      window.history.replaceState({}, document.title, newUrl);
    } else if (verified === 'false' && error) {
      antMessage.error(error);
      params.delete('verified');
      params.delete('message');
      params.delete('error');
      const newUrl = window.location.pathname + (params.toString() ? `?${params.toString()}` : '');
      window.history.replaceState({}, document.title, newUrl);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (token) {
      console.log('Found reset password token:', token);
      setResetPasswordVisible(true);
    }
  }, []);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      const response = await login(values);
      
      if (!response.success) {
        if (response.error === 'email_not_verified') {
          setUnverifiedEmail(values.email);
          setShowResendEmail(true);
          antMessage.warning('Email chưa được xác thực. Vui lòng kiểm tra email hoặc gửi lại email xác thực.');
          return;
        }
        throw new Error(response.message);
      }

      antMessage.success('Đăng nhập thành công!');
      
      // Kiểm tra và xử lý redirect
      const redirectPath = localStorage.getItem('redirectAfterLogin');
      if (redirectPath) {
        localStorage.removeItem('redirectAfterLogin'); // Xóa path đã lưu
        navigate(redirectPath, { replace: true });
      } else {
        navigate('/', { replace: true });
      }

    } catch (error) {
      console.error('Login error:', error);
      antMessage.error(error.message || 'Đăng nhập thất bại');
    } finally {
      setLoading(false);
    }
  };

  const handleResendEmail = async () => {
    try {
      setResendLoading(true);
      const response = await resendVerification(unverifiedEmail);
      if (response.success) {
        antMessage.success({
          content: 'Email xác thực đã được gửi lại. Vui lòng kiểm tra hộp thư của bạn.',
          className: 'custom-message-success',
        });
        setShowResendEmail(false);
        setTimeout(() => setShowResendEmail(true), 30000);
      } else {
        throw new Error(response.error || 'Không thể gửi lại email xác thực');
      }
    } catch (error) {
      antMessage.error({
        content: error.message,
        className: 'custom-message-error',
      });
    } finally {
      setResendLoading(false);
    }
  };

  const handleForgotPassword = async (values) => {
    try {
      setForgotPasswordLoading(true);
      await forgotPassword(values.email);
      antMessage.success('Email hướng dẫn đặt lại mật khẩu đã được gửi!');
      setForgotPasswordVisible(false);
      forgotPasswordForm.resetFields();
    } catch (error) {
      antMessage.error(error.message || 'Có lỗi xảy ra');
    } finally {
      setForgotPasswordLoading(false);
    }
  };

  const handleResetPassword = async (values) => {
    try {
      setResetPasswordLoading(true);
      const token = new URLSearchParams(window.location.search).get('token');
      if (!token) {
        throw new Error('Token không hợp lệ');
      }
      
      await resetPassword({
        token,
        new_password: values.new_password
      });
      
      antMessage.success('Đặt lại mật khẩu thành công!');
      setResetPasswordVisible(false);
      resetPasswordForm.resetFields();
      
      // Xóa token khỏi URL
      window.history.replaceState({}, document.title, window.location.pathname);
    } catch (error) {
      antMessage.error(error.message || 'Có lỗi xảy ra khi đặt lại mật khẩu');
    } finally {
      setResetPasswordLoading(false);
    }
  };

  const handleGoogleSuccess = async (credentialResponse) => {
    try {
      setLoading(true);
      console.log('Google credential:', credentialResponse);
      
      const response = await googleLogin(credentialResponse.credential);
      
      if (response.success && response.user) {
        updateUser({
          id: response.user.id,
          email: response.user.email,
          username: response.user.username,
          full_name: response.user.full_name,
          is_admin: response.user.is_admin,
          is_landlord: response.user.is_landlord,
          is_verified: response.user.is_verified,
          token: response.token
        });
        
        navigate('/');
        antMessage.success('Đăng nhập thành công!');
      } else {
        throw new Error('Đăng nhập không thành công');
      }
    } catch (error) {
      console.error('Google login error:', error);
      antMessage.error(error.message || 'Đăng nhập thất bại');
    } finally {
      setLoading(false);
    }
  };

  const handleGoogleError = () => {
    antMessage.error('Đăng nhập Google thất bại');
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-indigo-100 py-12 px-4 sm:px-6 lg:px-8 flex items-center justify-center">
      <Card className="w-full max-w-md shadow-2xl">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-800">
            Chào mừng trở lại!
          </h2>
          <p className="mt-2 text-gray-600">
            Đăng nhập để tiếp tục
          </p>
        </div>

        <Form
          form={form}
          name="login"
          onFinish={handleSubmit}
          layout="vertical"
          size="large"
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Vui lòng nhập email' },
              { type: 'email', message: 'Email không hợp lệ' }
            ]}
          >
            <Input 
              prefix={<UserOutlined className="text-gray-400" />}
              placeholder="Email"
              className="rounded-lg"
            />
          </Form.Item>

          <Form.Item
            name="password"
            rules={[
              { required: true, message: 'Vui lòng nhập mật khẩu' },
              { min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự' }
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="text-gray-400" />}
              placeholder="Mật khẩu" 
              className="rounded-lg"
            />
          </Form.Item>

          {showResendEmail && (
            <div className="bg-orange-50 border border-orange-200 rounded-lg p-4 mb-4 transition-all duration-300 ease-in-out">
              <div className="flex flex-col items-center space-y-2">
                <div className="text-orange-600 text-center">
                  <svg 
                    className="w-6 h-6 inline-block mr-2" 
                    fill="none" 
                    stroke="currentColor" 
                    viewBox="0 0 24 24"
                  >
                    <path 
                      strokeLinecap="round" 
                      strokeLinejoin="round" 
                      strokeWidth={2} 
                      d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" 
                    />
                  </svg>
                  <span className="font-medium">Email chưa được xác thực</span>
                </div>
                <p className="text-sm text-orange-600 text-center">
                  Vui lòng kiểm tra email hoặc gửi lại email xác thực
                </p>
                <Button 
                  onClick={handleResendEmail}
                  loading={resendLoading}
                  disabled={resendLoading}
                  className={`
                    transition-all duration-300 ease-in-out
                    ${resendLoading 
                      ? 'bg-gray-100 text-gray-400' 
                      : 'bg-orange-100 hover:bg-orange-200 text-orange-600 hover:text-orange-700'}
                    border-none rounded-lg px-4 py-2 flex items-center space-x-2
                  `}
                >
                  {!resendLoading && (
                    <svg 
                      className="w-4 h-4" 
                      fill="none" 
                      stroke="currentColor" 
                      viewBox="0 0 24 24"
                    >
                      <path 
                        strokeLinecap="round" 
                        strokeLinejoin="round" 
                        strokeWidth={2} 
                        d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15" 
                      />
                    </svg>
                  )}
                  <span>
                    {resendLoading ? 'Đang gửi...' : 'Gửi lại email xác thực'}
                  </span>
                </Button>
              </div>
            </div>
          )}

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={loading}
              className="w-full h-12 rounded-lg bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 border-0"
            >
              {loading ? 'Đang xử lý...' : 'Đăng nhập'}
            </Button>
          </Form.Item>

          <div className="text-center">
            <Button 
              type="link" 
              onClick={() => setForgotPasswordVisible(true)}
              className="text-blue-500 hover:text-blue-700"
            >
              Quên mật khẩu?
            </Button>
          </div>

          <Divider plain>Hoặc</Divider>

          <Form.Item>
            <div className="flex justify-center">
              <GoogleLogin
                onSuccess={handleGoogleSuccess}
                onError={handleGoogleError}
                theme="filled_blue"
                shape="circle"
                text="signin_with"
                locale="vi"
              />
            </div>
          </Form.Item>

          <div className="text-center mt-6">
            Chưa có tài khoản?{' '}
            <Link to="/register" className="text-blue-500 hover:text-blue-700 font-medium">
              Đăng ký ngay
            </Link>
          </div>
        </Form>
      </Card>

      {/* Modal Quên mật khẩu */}
      <Modal
        title={
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-800">Quên mật khẩu?</h3>
            <p className="text-sm text-gray-600 mt-1">
              Nhập email của bạn để nhận hướng dẫn đặt lại mật khẩu
            </p>
          </div>
        }
        open={forgotPasswordVisible}
        onCancel={() => {
          setForgotPasswordVisible(false);
          forgotPasswordForm.resetFields();
        }}
        footer={null}
        centered
        className="forgot-password-modal"
      >
        <Form
          form={forgotPasswordForm}
          onFinish={handleForgotPassword}
          layout="vertical"
          className="mt-4"
        >
          <Form.Item
            name="email"
            rules={[
              { required: true, message: 'Vui lòng nhập email' },
              { type: 'email', message: 'Email không hợp lệ' }
            ]}
          >
            <Input
              prefix={<MailOutlined className="text-gray-400" />}
              placeholder="Email"
              size="large"
              className="rounded-lg"
            />
          </Form.Item>

          <Form.Item className="mb-0">
            <div className="flex gap-2">
              <Button
                onClick={() => {
                  setForgotPasswordVisible(false);
                  forgotPasswordForm.resetFields();
                }}
                size="large"
                className="flex-1"
              >
                Hủy
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={forgotPasswordLoading}
                size="large"
                className="flex-1 bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 border-0"
              >
                {forgotPasswordLoading ? 'Đang xử lý...' : 'Gửi yêu cầu'}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>

      {/* Modal Reset Password */}
      <Modal
        title={
          <div className="text-center">
            <h3 className="text-xl font-semibold text-gray-800">Đặt lại mật khẩu</h3>
            <p className="text-sm text-gray-600 mt-1">
              Nhập mật khẩu mới cho tài khoản của bạn
            </p>
          </div>
        }
        open={resetPasswordVisible}
        onCancel={() => {
          setResetPasswordVisible(false);
          resetPasswordForm.resetFields();
          window.history.replaceState({}, document.title, window.location.pathname);
        }}
        footer={null}
        centered
        className="reset-password-modal"
        maskClosable={false}
      >
        <Form
          form={resetPasswordForm}
          onFinish={handleResetPassword}
          layout="vertical"
          className="mt-4"
        >
          <Form.Item
            name="new_password"
            rules={[
              { required: true, message: 'Vui lòng nhập mật khẩu mới' },
              { min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự' }
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="text-gray-400" />}
              placeholder="Mật khẩu mới"
              size="large"
              className="rounded-lg"
            />
          </Form.Item>

          <Form.Item
            name="confirm_password"
            rules={[
              { required: true, message: 'Vui lòng xác nhận mật khẩu' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('new_password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('Mật khẩu xác nhận không khớp'));
                },
              }),
            ]}
          >
            <Input.Password
              prefix={<LockOutlined className="text-gray-400" />}
              placeholder="Xác nhận mật khẩu"
              size="large"
              className="rounded-lg"
            />
          </Form.Item>

          <Form.Item className="mb-0">
            <div className="flex gap-2">
              <Button
                onClick={() => {
                  setResetPasswordVisible(false);
                  resetPasswordForm.resetFields();
                  window.history.replaceState({}, document.title, window.location.pathname);
                }}
                size="large"
                className="flex-1"
              >
                Hủy
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={resetPasswordLoading}
                size="large"
                className="flex-1 bg-gradient-to-r from-blue-500 to-indigo-600 hover:from-blue-600 hover:to-indigo-700 border-0"
              >
                {resetPasswordLoading ? 'Đang xử lý...' : 'Đặt lại mật khẩu'}
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}

export default Login;
