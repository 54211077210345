import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { FaPhone, FaStar, FaComments, FaEdit, FaTrash, FaMapMarkerAlt, FaRulerCombined, FaTag, FaCalendarAlt, FaArrowLeft, FaArrowRight, FaImage, FaVideo, FaEye } from 'react-icons/fa';
import { getListingDetail, getSimilarListings, updateListingViews } from '../api';
import { useAuth } from '../contexts/AuthContext';
import { message } from 'antd';

const ListingDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [listing, setListing] = useState(null);
  const [similarListings, setSimilarListings] = useState([]);
  const [showImageModal, setShowImageModal] = useState(false);
  const [mediaType, setMediaType] = useState('image');
  const [allImages, setAllImages] = useState([]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [showLoginPrompt, setShowLoginPrompt] = useState(false);
  const { user } = useAuth();

  const fetchSimilarListings = async () => {
    if (listing) {
      try {
        console.log('Fetching similar listings with params:', {
          type: listing.type,
          district: listing.district,
          city: listing.city,
          currentId: listing.id
        });
        
        const response = await getSimilarListings({
          type: listing.type,
          district: listing.district,
          city: listing.city,
          currentId: listing.id,
          limit: 5
        });
        
        console.log('Similar listings response:', response);
        
        if (response.success && response.data && response.data.listings) {
          setSimilarListings(response.data.listings);
        } else if (response.listings && Array.isArray(response.listings)) {
          setSimilarListings(response.listings);
        } else {
          console.error('Invalid response format:', response);
        }
      } catch (error) {
        console.error('Error fetching similar listings:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const data = await getListingDetail(id);
        setListing(data);
        
        // Tạo mảng ảnh bao gồm thumbnail và ảnh bổ sung
        const thumbnailImage = data.thumbnail || '';
        const additionalImages = data.images || [];
        const allImagesArray = [thumbnailImage, ...additionalImages].filter(Boolean);
        
        setAllImages(allImagesArray);
        
        // Cập nhật lượt xem
        await updateListingViews(id);
        
        setLoading(false);
      } catch (error) {
        console.error('Error fetching listing:', error);
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);

  useEffect(() => {
    if (listing && Object.keys(listing).length > 0) {
      fetchSimilarListings();
    }
  }, [listing]);

  const handlePrevImage = () => {
    setCurrentImageIndex((prev) => (prev === 0 ? allImages.length - 1 : prev - 1));
  };

  const handleNextImage = () => {
    setCurrentImageIndex((prev) => (prev === allImages.length - 1 ? 0 : prev + 1));
  };

  const formatTimeAgo = (date) => {
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    
    if (diffDays === 0) {
      const diffHours = Math.floor(diffTime / (1000 * 60 * 60));
      if (diffHours === 0) {
        const diffMinutes = Math.floor(diffTime / (1000 * 60));
        return `${diffMinutes} phút trước`;
      }
      return `${diffHours} giờ trước`;
    } else if (diffDays < 7) {
      return `${diffDays} ngày trước`;
    } else if (diffDays < 30) {
      const weeks = Math.floor(diffDays / 7);
      return `${weeks} tuần trước`;
    } else {
      return date.toLocaleDateString('vi-VN');
    }
  };

  // Thêm hàm xử lý click vào tin đăng tương tự
  const handleSimilarListingClick = (listingId) => {
    // Thay đổi URL và reload data
    navigate(`/listing/${listingId}`);
    // Sau khi navigate, gọi lại các hàm fetch data
    window.scrollTo(0, 0); // Scroll to top
  };

  // Render similar listings section
  const renderSimilarListings = () => {
    if (loading) {
      return (
        <div className="bg-white rounded-xl shadow-sm p-6">
          <h2 className="text-xl font-semibold mb-4">Tin đăng tương tự</h2>
          <div className="animate-pulse space-y-4">
            {[1, 2, 3].map((i) => (
              <div key={i} className="flex gap-4">
                <div className="w-24 h-24 bg-gray-200 rounded-lg"></div>
                <div className="flex-1">
                  <div className="h-4 bg-gray-200 rounded w-3/4 mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded w-1/2"></div>
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    }

    if (!similarListings.length) {
      return null;
    }

    return (
      <div className="bg-white rounded-xl shadow-sm p-6">
        <h2 className="text-xl font-semibold mb-4">Tin đăng tương tự</h2>
        <div className="space-y-4">
          {similarListings.map((item) => (
            <div 
              key={item.id}
              onClick={() => handleSimilarListingClick(item.id)}
              className="flex gap-4 p-4 hover:bg-gray-50 rounded-lg transition-colors cursor-pointer"
            >
              <div className="w-24 h-24 flex-shrink-0">
                <img 
                  src={item.thumbnail} 
                  alt={item.title}
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
              <div className="flex-1 min-w-0"> {/* Thêm min-w-0 để tránh overflow */}
                <h3 className="font-medium text-gray-900 line-clamp-2 mb-2">
                  {item.title}
                </h3>
                <p className="text-blue-600 font-medium">
                  {new Intl.NumberFormat('vi-VN').format(item.price)} đ/tháng
                </p>
                <p className="text-gray-500 text-sm mt-1 truncate">
                  {item.district}, {item.city}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderImageModal = () => (
    <div 
      className="fixed inset-0 z-50 bg-black/90 flex items-center justify-center"
      onClick={() => setShowImageModal(false)}
    >
      <div className="relative max-w-7xl w-full mx-4">
        <img
          src={allImages[currentImageIndex]}
          alt={`Listing ${currentImageIndex + 1}`}
          className="w-full h-auto max-h-[90vh] object-contain"
        />
        
        {/* Navigation Arrows */}
        {allImages.length > 1 && (
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handlePrevImage();
              }}
              className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/10 hover:bg-white/20 text-white p-4 rounded-full transition-all"
            >
              <FaArrowLeft className="w-6 h-6" />
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleNextImage();
              }}
              className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/10 hover:bg-white/20 text-white p-4 rounded-full transition-all"
            >
              <FaArrowRight className="w-6 h-6" />
            </button>
          </>
        )}
      </div>
    </div>
  );

  // Render media section
  const renderMediaSection = () => (
    <div className="bg-white rounded-xl shadow-lg overflow-hidden">
      <div className="bg-black">
        <div className="relative max-w-5xl mx-auto">
          {/* Media Type Switcher */}
          {listing?.video_url && (
            <div className="absolute top-6 right-6 z-20 flex gap-3">
              <button
                onClick={() => setMediaType('image')}
                className={`px-5 py-2.5 rounded-full text-sm font-medium transition-all
                  ${mediaType === 'image'
                    ? 'bg-white text-gray-900 shadow-lg ring-2 ring-white/50'
                    : 'bg-black/20 text-white hover:bg-black/40 backdrop-blur'
                  }`}
              >
                <span className="flex items-center gap-2">
                  <FaImage className="w-4 h-4" />
                  Ảnh ({allImages.length})
                </span>
              </button>
              <button
                onClick={() => setMediaType('video')}
                className={`px-5 py-2.5 rounded-full text-sm font-medium transition-all
                  ${mediaType === 'video'
                    ? 'bg-white text-gray-900 shadow-lg ring-2 ring-white/50'
                    : 'bg-black/20 text-white hover:bg-black/40 backdrop-blur'
                  }`}
              >
                <span className="flex items-center gap-2">
                  <FaVideo className="w-4 h-4" />
                  Video
                </span>
              </button>
            </div>
          )}

          {/* Media Container */}
          <div className="relative bg-gray-900">
            <div className="aspect-[16/9]">
              <div className="absolute inset-0">
                {mediaType === 'image' ? (
                  // Image View
                  <div className="w-full h-full">
                    {allImages.length > 0 && (
                      <div className="relative w-full h-full">
                        <div className="absolute inset-0 flex items-center justify-center p-8">
                          <img
                            src={allImages[currentImageIndex]}
                            alt={`Listing ${currentImageIndex + 1}`}
                            className="max-w-full max-h-full object-contain 
                              transition-all duration-300 hover:scale-105"
                            onClick={() => setShowImageModal(true)}
                          />
                        </div>

                        {/* Gradient Overlays */}
                        <div className="absolute inset-0 bg-gradient-to-t from-black/30 via-transparent to-black/30 opacity-0 hover:opacity-100 transition-opacity duration-300" />

                        {/* Navigation Controls */}
                        {allImages.length > 1 && (
                          <div className="absolute inset-x-0 top-1/2 -translate-y-1/2 flex justify-between items-center px-4 opacity-0 hover:opacity-100 transition-opacity duration-300">
                            <button
                              onClick={handlePrevImage}
                              className="p-3 rounded-full bg-black/30 text-white hover:bg-black/50 backdrop-blur transform hover:scale-110 transition-all"
                            >
                              <FaArrowLeft className="w-5 h-5" />
                            </button>
                            <button
                              onClick={handleNextImage}
                              className="p-3 rounded-full bg-black/30 text-white hover:bg-black/50 backdrop-blur transform hover:scale-110 transition-all"
                            >
                              <FaArrowRight className="w-5 h-5" />
                            </button>
                          </div>
                        )}

                        {/* Image Counter */}
                        <div className="absolute bottom-6 right-6 px-4 py-2 rounded-full bg-black/30 backdrop-blur text-white text-sm font-medium">
                          {currentImageIndex + 1} / {allImages.length}
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  // Video View
                  listing?.video_url && (
                    <div className="absolute inset-0 flex items-center justify-center bg-black">
                      <video
                        src={`https://thuenhadedang.io.vn${listing.video_url}`}
                        controls
                        className="max-w-full max-h-full rounded-lg shadow-2xl"
                      />
                    </div>
                  )
                )}
              </div>
            </div>
          </div>

          {/* Thumbnails */}
          {mediaType === 'image' && allImages.length > 1 && (
            <div className="bg-gray-100 border-t border-gray-200">
              <div className="max-w-5xl mx-auto py-4">
                <div className="flex gap-3 px-4 overflow-x-auto 
                  scrollbar-thin scrollbar-thumb-gray-400 
                  scrollbar-track-transparent">
                  {allImages.map((image, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentImageIndex(index)}
                      className={`relative flex-shrink-0 group`}
                    >
                      <div className={`w-20 h-20 rounded-lg overflow-hidden
                        transition-all duration-300 transform
                        ${index === currentImageIndex 
                          ? 'ring-2 ring-blue-500 shadow-lg scale-105' 
                          : 'opacity-60 hover:opacity-100 hover:scale-105'}`}
                      >
                        <img
                          src={image}
                          alt={`Thumbnail ${index + 1}`}
                          className="w-full h-full object-cover"
                        />
                      </div>
                      {index === currentImageIndex && (
                        <div className="absolute inset-0 ring-2 ring-blue-500 rounded-lg" />
                      )}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );

  // Render loading state while data is being fetched
  if (!listing) {
    return (
      <div className="container mx-auto px-4 py-6 max-w-7xl">
        <div className="animate-pulse">
          <div className="h-96 bg-gray-200 rounded-xl mb-6"></div>
          <div className="space-y-4">
            <div className="h-8 bg-gray-200 rounded w-3/4"></div>
            <div className="h-4 bg-gray-200 rounded w-1/2"></div>
            <div className="h-4 bg-gray-200 rounded w-2/3"></div>
          </div>
        </div>
      </div>
    );
  }

  const handleLoginRedirect = () => {
    // Lưu current path vào localStorage
    localStorage.setItem('redirectAfterLogin', window.location.pathname);
    navigate('/login');
    setShowLoginPrompt(false); // Đóng modal login prompt
  };

  const renderLoginPrompt = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
      <div className="bg-white rounded-lg p-6 max-w-md w-full mx-4">
        <h3 className="text-xl font-semibold mb-4">Đăng nhập để đánh giá</h3>
        <p className="text-gray-600 mb-6">
          Bạn cần đăng nhập để có thể đánh giá tin đăng này.
        </p>
        <div className="flex gap-4">
          <button
            onClick={handleLoginRedirect}
            className="flex-1 bg-blue-500 text-white py-2 px-4 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Đăng nhập
          </button>
          <button
            onClick={() => setShowLoginPrompt(false)}
            className="flex-1 border border-gray-300 py-2 px-4 rounded-lg hover:bg-gray-50 transition-colors"
          >
            Để sau
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="container mx-auto px-4 py-6 max-w-7xl">
      {/* Breadcrumb */}
      {/* <div className="mb-6 text-sm text-gray-600">
        <span>Trang chủ</span> {' > '} 
        <span>{listing.type}</span> {' > '} 
        <span>{listing.district}, {listing.city}</span>
      </div> */}

      <div className="flex flex-col lg:flex-row gap-6">
        {/* Left Column - 70% width */}
        <div className="lg:w-[70%]">
          {/* Image Gallery Section */}
          <div className="bg-white rounded-xl shadow-sm overflow-hidden mb-6">
            {renderMediaSection()}
          </div>

          {/* Image Modal */}
          {showImageModal && renderImageModal()}

          {/* Listing Info Section */}
          <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
            {/* Title & Badge */}
            <div className="flex items-start justify-between mb-4">
              <div className="flex-1">
                {/* Stars and Title Container */}
                <div className="flex items-start gap-3">
                  {/* Package Stars */}
                  <div className="flex items-center mt-1.5">
                    {[...Array(listing.package_info?.stars || 0)].map((_, index) => (
                      <FaStar key={index} className="w-4 h-4 text-yellow-400" />
                    ))}
                  </div>

                  {/* Title */}
                  <h1 
                    className={`text-2xl font-bold ${
                      listing.package_info?.color 
                        ? `text-[${listing.package_info.color}]` 
                        : 'text-gray-900'
                    }`}
                    style={{ 
                      color: listing.package_info?.color || '#1F2937',
                      textTransform: listing.title_style === 'uppercase' ? 'uppercase' : 'none'
                    }}
                  >
                    {listing.title}
                  </h1>
                </div>

                {/* Listing Info */}
                <div className="flex items-center gap-2 mt-2 text-sm text-gray-500">
                  <FaCalendarAlt className="w-4 h-4" />
                  <span>
                    Đăng ngày: {new Date(listing.created_at).toLocaleDateString('vi-VN')}
                  </span>
                  {listing.package_expires_at && (
                    <span className="text-orange-500">
                      • Hết hạn: {new Date(listing.package_expires_at).toLocaleDateString('vi-VN')}
                    </span>
                  )}
                </div>
              </div>
            </div>

            {/* Key Details */}
            <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
              <div className="flex items-center">
                <FaTag className="text-blue-500 mr-2 w-5 h-5" />
                <div>
                  <div className="text-sm text-gray-500">Giá thuê</div>
                  <div className="font-semibold text-green-600">
                    {Number(listing.price).toLocaleString()} đ/tháng
                  </div>
                </div>
              </div>
              <div className="flex items-center">
                <FaRulerCombined className="text-blue-500 mr-2 w-5 h-5" />
                <div>
                  <div className="text-sm text-gray-500">Diện tích</div>
                  <div className="font-semibold">{listing.area}m²</div>
                </div>
              </div>
              <div className="flex items-center">
                <FaMapMarkerAlt className="text-blue-500 mr-2 w-5 h-5" />
                <div>
                  <div className="text-sm text-gray-500">Khu vực</div>
                  <div className="font-semibold">{listing.district}</div>
                </div>
              </div>
              <div className="flex items-center">
                <FaCalendarAlt className="text-blue-500 mr-2 w-5 h-5" />
                <div>
                  <div className="text-sm text-gray-500">Đăng ngày</div>
                  <div className="font-semibold">
                    {formatTimeAgo(new Date(listing.created_at))}
                  </div>
                </div>
              </div>
            </div>

            {/* Full Address */}
            <div className="flex items-start gap-2 p-4 bg-gray-50 rounded-lg mb-6">
              <FaMapMarkerAlt className="text-gray-500 mt-1" />
              <span className="text-gray-600">
                {listing.address}, {listing.ward}, {listing.district}, {listing.city}
              </span>
            </div>

            {/* Description */}
            <div className="prose max-w-none">
              <h2 className="text-xl font-semibold mb-4">Thông tin mô tả</h2>
              <div 
                className="text-gray-600 leading-relaxed"
                dangerouslySetInnerHTML={{ __html: listing.description }} 
              />
            </div>
          </div>

          {/* Map Section */}
          <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
            <h2 className="text-xl font-semibold mb-4">Vị trí trên bản đồ</h2>
            <div className="w-full h-[400px] rounded-lg overflow-hidden">
              <iframe
                src={`https://www.google.com/maps?q=${encodeURIComponent(
                  `${listing.address}, ${listing.ward}, ${listing.district}, ${listing.city}`
                )}&output=embed`}
                width="100%"
                height="100%"
                frameBorder="0"
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
              ></iframe>
            </div>
          </div>

          {/* Login Prompt Modal */}
          {showLoginPrompt && renderLoginPrompt()}
        </div>

        {/* Right Column - 30% width */}
        <div className="lg:w-[30%]">
          <div className="sticky top-6">
            <div className="bg-white rounded-xl shadow-sm p-6 mb-6">
              <div className="flex flex-col items-center">
                <img 
                  src="https://phongtro123.com/images/default-user.png" 
                  alt="Author Avatar" 
                  className="w-20 h-20 rounded-full mb-4"
                />
                <h3 className="font-semibold text-lg mb-2">
                  {listing.owner_name || 'Không xác định'}
                </h3>
                <div className="flex items-center text-sm text-green-500 mb-6">
                  <span className="w-2 h-2 bg-green-500 rounded-full mr-2"></span>
                  Đang hoạt động
                </div>
                
                <div className="w-full space-y-3">
                  <a 
                    href={`tel:${listing.owner_phone}`}
                    className="flex items-center justify-center gap-2 w-full bg-blue-500 hover:bg-blue-600 text-white py-3 rounded-lg transition-colors"
                  >
                    <FaPhone />
                    <span>{listing.owner_phone || 'Không xác định'}</span>
                  </a>
                  <a
                    href={`https://zalo.me/${listing.owner_phone}`}
                    target="_blank"
                    rel="nofollow"
                    className="flex items-center justify-center gap-2 w-full bg-green-500 hover:bg-green-600 text-white py-3 rounded-lg transition-colors"
                  >
                    <FaComments />
                    <span>Nhắn Zalo</span>
                  </a>
                </div>
              </div>
            </div>

            {/* Similar Listings Section */}
            {renderSimilarListings()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListingDetail;
