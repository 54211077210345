import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
    Form, Input, Button, Select, Upload, 
    message, Space 
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import api from '../../api';
import '../../styles/editor.css';

const { Option } = Select;

const NewsForm = () => {
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [editorData, setEditorData] = useState('');
    const [fileList, setFileList] = useState([]); // Thêm state cho file upload
    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        if (id) {
            fetchNews();
        }
    }, [id]);

    const fetchNews = async () => {
        try {
            const response = await api.get(`/news/${id}`);
            if (response.data.success) {
                const news = response.data.data;
                
                // Lấy category slug từ category_id
                const categoryResponse = await api.get('/news/categories');
                const categories = categoryResponse.data.data;
                const category = categories.find(c => c.id === news.category_id);
                
                form.setFieldsValue({
                    title: news.title,
                    description: news.description,
                    category: category?.slug || 'tin-tuc', // Sử dụng slug của category
                    status: news.status || 'draft'
                });
                
                setEditorData(news.content || '');
                
                // Xử lý thumbnail nếu có
                if (news.thumbnail) {
                    setFileList([{
                        uid: '-1',
                        name: 'thumbnail',
                        status: 'done',
                        url: `https://thuenhadedang.io.vn${news.thumbnail}`
                    }]);
                }
            } else {
                message.error('Không thể tải thông tin tin tức');
            }
        } catch (error) {
            console.error('Error fetching news:', error);
            message.error('Lỗi khi tải tin tức');
        }
    };

    const onFinish = async (values) => {
        try {
            setLoading(true);
            const formData = new FormData();
            
            formData.append('title', values.title);
            formData.append('content', editorData);
            formData.append('description', values.description || '');
            formData.append('category', values.category);
            formData.append('status', values.status);

            if (fileList.length > 0 && fileList[0].originFileObj) {
                formData.append('thumbnail', fileList[0].originFileObj);
            }

            // Log để debug
            for (let pair of formData.entries()) {
                console.log('FormData:', pair[0], pair[1]);
            }

            let response;
            if (id) {
                // Nếu có id thì gọi API cập nhật
                response = await api.post(`/news/${id}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            } else {
                // Nếu không có id thì gọi API thêm mới
                response = await api.post('/news', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
            }

            if (response.data.success) {
                message.success(id ? 'Cập nhật tin tức thành công' : 'Thêm tin tức thành công');
                navigate('/admin/news');
            }
        } catch (error) {
            console.error('API Error:', error.response?.data || error);
            message.error(error.response?.data?.error || 'Có lỗi xảy ra');
        } finally {
            setLoading(false);
        }
    };

    const uploadProps = {
        beforeUpload: (file) => {
            const isImage = file.type.startsWith('image/');
            if (!isImage) {
                message.error('Chỉ có thể tải lên file ảnh!');
                return false;
            }
            return false;
        },
        onChange: ({ fileList: newFileList }) => {
            setFileList(newFileList);
        },
        fileList,
        maxCount: 1
    };

    // Thêm biến cho ảnh placeholder
    const PLACEHOLDER_IMAGE = 'https://via.placeholder.com/300x200';

    // Sửa lại phần hiển thị ảnh
    const getImageUrl = (thumbnail) => {
        if (!thumbnail) return PLACEHOLDER_IMAGE;
        if (thumbnail.startsWith('http')) return thumbnail;
        return `https://thuenhadedang.io.vn${thumbnail}`;
    };

    return (
        <div style={{ padding: '20px' }}>
            <h2>{id ? 'Cập nhật tin tức' : 'Thêm tin tức mới'}</h2>
            <Form form={form} layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="title"
                    label="Tiêu đề"
                    rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="description"
                    label="Mô tả ngắn"
                >
                    <Input.TextArea rows={3} />
                </Form.Item>

                <Form.Item
                    label="Nội dung"
                    required
                >
                    <CKEditor
                        editor={ClassicEditor}
                        data={editorData}
                        config={{
                            toolbar: ['heading', '|', 'bold', 'italic', 'link', 'bulletedList', 'numberedList', '|', 'undo', 'redo']
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            setEditorData(data);
                        }}
                    />
                </Form.Item>

                <Form.Item
                    name="category"
                    label="Danh mục"
                    rules={[{ required: true, message: 'Vui lòng chọn danh mục' }]}
                >
                    <Select>
                        <Option value="tin-tuc">Tin tức</Option>
                        <Option value="huong-dan">Hướng dẫn</Option>
                        <Option value="thong-bao">Thông báo</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    name="status"
                    label="Trạng thái"
                    rules={[{ required: true, message: 'Vui lòng chọn trạng thái' }]}
                >
                    <Select>
                        <Option value="draft">Nháp</Option>
                        <Option value="published">Đăng ngay</Option>
                    </Select>
                </Form.Item>

                <Form.Item label="Ảnh thumbnail">
                    <Upload {...uploadProps} listType="picture">
                        <Button icon={<UploadOutlined />}>Chọn ảnh</Button>
                    </Upload>
                </Form.Item>

                <Form.Item>
                    <Space>
                        <Button type="primary" htmlType="submit" loading={loading}>
                            {id ? 'Cập nhật' : 'Thêm mới'}
                        </Button>
                        <Button onClick={() => navigate('/admin/news')}>
                            Hủy
                        </Button>
                    </Space>
                </Form.Item>
            </Form>
        </div>
    );
};

export default NewsForm;
