import React, { useState, useEffect } from 'react';
import { Row, Col, Card, Statistic, Table, Spin } from 'antd';
import { Line, Pie, Column } from '@ant-design/charts';
import {
    UserOutlined,
    HomeOutlined,
    DollarOutlined,
    RiseOutlined,
    FallOutlined,
    EyeOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import api from '../../api';
import moment from 'moment';

const AdminHome = () => {
    const [data, setData] = useState({
        overview: {},
        charts: {}
    });
    const [loading, setLoading] = useState(true);

    // Tính tổng số tin đăng
    const totalListings = React.useMemo(() => {
        const listingsData = data.charts?.listings_chart;
        if (!Array.isArray(listingsData)) return 0;
        return listingsData.reduce((sum, item) => sum + (item?.count || 0), 0);
    }, [data.charts?.listings_chart]);

    useEffect(() => {
        fetchDashboardData();
    }, []);

    const fetchDashboardData = async () => {
        try {
            const response = await api.get('/admin/dashboard/statistics');
            console.log('Dữ liệu trả về:', response.data.data);
            setData(response.data.data);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        } finally {
            setLoading(false);
        }
    };

    // Config cho các biểu đồ
    const revenueConfig = {
        data: data.charts?.revenue_chart || [],
        height: 300,
        xField: 'month',
        yField: 'total_amount',
        xAxis: {
            type: 'time',
            tickCount: 6,
            label: {
                formatter: (v) => moment(v).format('MM/YYYY')
            }
        },
        yAxis: {
            label: {
                formatter: (v) => `${Number(v / 1000000).toFixed(1)}tr`
            }
        },
        point: {
            size: 4,
            shape: 'circle'
        }
    };

    const listingsConfig = {
        data: data.charts?.listings_chart || [],
        height: 300,
        angleField: 'count',
        colorField: 'status',
        radius: 0.8,
        label: false,
        legend: {
            layout: 'horizontal',
            position: 'bottom'
        }
    };

    const usersConfig = {
        data: data.charts?.users_chart || [],
        height: 300,
        xField: 'date',
        yField: 'count',
        xAxis: {
            type: 'time',
            tickCount: 7,
            label: {
                formatter: (v) => moment(v).format('DD/MM')
            }
        },
        yAxis: {
            min: 0,
            label: {
                formatter: (v) => `${Math.floor(v)}`
            }
        },
        meta: {
            count: {
                alias: 'Người dùng mới'
            }
        }
    };

    const growthConfig = {
        data: data.charts?.user_growth || [],
        height: 300,
        xField: 'month',
        yField: 'total_users',
        seriesField: 'type',
        smooth: true
    };

    // Thêm thống kê doanh thu chi tiết
    const RevenueStats = () => {
        const currentRevenue = data.overview?.total_revenue || 0;

        return (
            <Card hoverable className="h-full">
                <Statistic
                    title={
                        <span className="text-base">Tổng doanh thu</span>
                    }
                    value={currentRevenue}
                    prefix={
                        <DollarOutlined className="text-yellow-500 text-xl" />
                    }
                    suffix="đ"
                    formatter={(value) => `${Number(value).toLocaleString('vi-VN')}`}
                    className="mb-4"
                />
            </Card>
        );
    };

    // Thêm component hiển thị chi tiết bên cạnh biểu đồ
    const ListingStats = () => {
        const listingsData = data.charts?.listings_chart || [];
        const total = listingsData.reduce((sum, item) => sum + (item.count || 0), 0);

        return (
            <div className="mt-4 space-y-2">
                {listingsData.map((item, index) => (
                    <div key={index} className="flex justify-between items-center">
                        <span className="text-sm text-gray-600">{item.status}:</span>
                        <div className="text-right">
                            <span className="text-sm font-medium">{item.count.toLocaleString()}</span>
                            <span className="text-xs text-gray-500 ml-1">
                                ({((item.count / total) * 100).toFixed(1)}%)
                            </span>
                        </div>
                    </div>
                ))}
                <div className="pt-2 mt-2 border-t border-gray-200">
                    <div className="flex justify-between items-center">
                        <span className="text-sm font-medium">Tổng tin đăng:</span>
                        <span className="text-sm font-medium">{total.toLocaleString()}</span>
                    </div>
                </div>
            </div>
        );
    };

    if (loading) {
        return <Spin size="large" className="flex justify-center items-center h-screen" />;
    }

    return (
        <div className="p-6">
            <h1 className="text-2xl font-bold mb-6">Tổng quan hệ thống</h1>
            
            <Row gutter={[16, 16]} className="mb-6">
                <Col xs={24} sm={12} md={8}>
                    <Card hoverable>
                        <Statistic
                            title="Tổng người dùng"
                            value={data.overview.total_users}
                            prefix={<UserOutlined className="text-blue-500" />}
                        />
                        <div className="text-xs text-gray-500 mt-2">
                            +{data.overview.new_users_today} hôm nay
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <Card hoverable>
                        <Statistic
                            title="Tin đăng hoạt động"
                            value={data.overview.active_listings}
                            prefix={<HomeOutlined className="text-green-500" />}
                        />
                        <div className="text-xs text-gray-500 mt-2">
                            Tổng: {data.overview.total_listings}
                        </div>
                    </Card>
                </Col>
                <Col xs={24} sm={12} md={8}>
                    <RevenueStats />
                </Col>
            </Row>

            <Row gutter={[16, 16]}>
                <Col span={24} lg={12}>
                    <Card title="Phân bố tin đăng" className="h-full">
                        <Pie {...listingsConfig} />
                        <ListingStats />
                    </Card>
                </Col>
                <Col span={24} lg={12}>
                    <Card title="Người dùng mới" className="h-full">
                        <Column {...usersConfig} />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default AdminHome;
