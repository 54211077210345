import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useFavorites } from '../contexts/FavoriteContext';
import { FaMapMarkerAlt, FaRulerCombined, FaBed, FaUser, FaPhone, FaClock, FaStar } from 'react-icons/fa';
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';
import { useAuth } from '../contexts/AuthContext';
import { message } from 'antd';

const ListingItem = ({ listing }) => {
  const { favorites, addFavorite, removeFavorite, isFavorite } = useFavorites();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [isCurrentlyFavorite, setIsCurrentlyFavorite] = useState(isFavorite(listing.id));

  useEffect(() => {
    setIsCurrentlyFavorite(isFavorite(listing.id));
  }, [listing.id, isFavorite, favorites]);

  // Xác định style cho từng loại gói tin
  const getPackageStyles = () => {
    switch (listing.package_id) {
      case 1: // VIP nổi bật
        return {
          titleColor: '#E13427',
          badge: {
            text: 'THUÊ NHANH',
            color: 'bg-[#E13427]',
            stars: 5,
            ribbon: true
          },
          isUppercase: true,
          showPhone: true,
          showBadge: true
        };
      case 2: // VIP 1
        return {
          titleColor: '#ea2e9d',
          badge: {
            text: 'VIP 1',
            color: 'bg-[#ea2e9d]',
            stars: 4
          },
          isUppercase: true,
          showPhone: false,
          showBadge: false
        };
      case 3: // VIP 2
        return {
          titleColor: '#FF6600',
          badge: {
            text: 'VIP 2',
            color: 'bg-[#FF6600]',
            stars: 3
          },
          isUppercase: true,
          showPhone: false,
          showBadge: false
        };
      case 4: // VIP 3
        return {
          titleColor: '#007BFF',
          badge: {
            text: 'VIP 3',
            color: 'bg-[#007BFF]',
            stars: 2
          },
          isUppercase: true,
          showPhone: false,
          showBadge: false
        };
      default: // Tin thường
        return {
          titleColor: '#055699',
          badge: {
            text: 'Tin thường',
            color: 'bg-[#055699]',
            stars: 0
          },
          isUppercase: false,
          showPhone: false,
          showBadge: false
        };
    }
  };

  const packageStyles = getPackageStyles();

  const handleToggleFavorite = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isAuthenticated) {
      message.error('Vui lòng đăng nhập để sử dụng tính năng này');
      navigate('/login');
      return;
    }

    try {
      if (isCurrentlyFavorite) {
        const success = await removeFavorite(listing.id);
        if (success) {
          setIsCurrentlyFavorite(false);
        }
      } else {
        const success = await addFavorite(listing.id);
        if (success) {
          setIsCurrentlyFavorite(true);
        }
      }
    } catch (error) {
      console.error('Error toggling favorite:', error);
      message.error('Có lỗi xảy ra khi thực hiện thao tác');
    }
  };

  const formatTimeAgo = (dateString) => {
    try {
      const date = new Date(dateString);
      return formatDistanceToNow(date, { addSuffix: true, locale: vi });
    } catch (error) {
      return 'Không xác định';
    }
  };

  // Render stars based on package
  const renderStars = (count) => {
    return [...Array(count)].map((_, index) => (
      <FaStar key={index} className="text-yellow-400 text-sm" />
    ));
  };

  return (
    <li className="bg-white shadow-sm rounded-lg overflow-hidden hover:shadow-md transition-all duration-300 list-none relative">
      <Link to={`/listing/${listing.id}`} className="block">
        <div className="md:flex">
          <div className="relative md:w-1/3 h-[200px] md:h-[250px] overflow-hidden">
            <img
              src={listing.thumbnail}
              alt={listing.title}
              className="w-full h-full object-cover"
            />
            
            {/* Ribbon THUÊ NHANH cho gói VIP nổi bật */}
            {packageStyles.badge?.ribbon && (
              <div className="absolute -left-[40px] top-[32px] w-[170px] transform -rotate-45">
                <div className={`py-1 text-center bg-[#E13427] text-white font-bold text-sm tracking-wider shadow-lg`}>
                  THUÊ NHANH
                </div>
              </div>
            )}

            {/* Thêm loại phòng ở góc trái dưới */}
            <div className="absolute bottom-2 left-2 px-2 py-1 bg-black/50 backdrop-blur-sm rounded text-white text-sm">
              {listing.type}
            </div>

            {/* Nút yêu thích */}
            <div 
              onClick={handleToggleFavorite}
              className="absolute bottom-2 right-2 p-2 bg-white/90 backdrop-blur-sm rounded-full 
                shadow-md hover:bg-white transition-colors duration-200 z-10 cursor-pointer"
            >
              <svg 
                className={`w-5 h-5 ${isCurrentlyFavorite ? 'text-red-500' : 'text-gray-400'} 
                  transition-colors duration-200`} 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path 
                  fillRule="evenodd" 
                  d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z" 
                  clipRule="evenodd" 
                />
              </svg>
            </div>
          </div>

          <div className="p-4 md:w-2/3">
            <div className="flex justify-between items-start gap-4 mb-3">
              <div className="flex-1">
                {/* Stars trên một dòng riêng */}
                {packageStyles.badge.stars > 0 && (
                  <div className="flex gap-0.5 mb-2">
                    {/* {renderStars(packageStyles.badge.stars)} */}
                  </div>
                )}
                
                {/* Title với font size nhỏ hơn */}
                <h3 
                  className={`text-base font-medium ${packageStyles.isUppercase ? 'uppercase' : ''} line-clamp-2`}
                  style={{ color: packageStyles.titleColor }}
                >
                  {listing.title}
                </h3>
              </div>
              
              {/* Giá */}
              <div className="text-lg font-bold text-primary-600 whitespace-nowrap">
                {listing.price ? `${Number(listing.price).toLocaleString()}đ/tháng` : 'Liên hệ'}
              </div>
            </div>

            {/* Thông tin người đăng và thời gian */}
            <div className="flex items-center justify-between text-sm mb-3">
              <div className="flex items-center gap-4">
                {/* Người đăng */}
                <div className="flex items-center">
                  <FaUser className="mr-1.5 text-gray-400" />
                  <span className="text-gray-600">{listing.owner_name || 'Không xác định'}</span>
                </div>
                {/* Thời gian đăng */}
                <div className="flex items-center text-gray-500">
                  <FaClock className="mr-1.5 text-gray-400" />
                  <span>{formatTimeAgo(listing.created_at)}</span>
                </div>
              </div>
            </div>

            {/* Địa chỉ */}
            <div className="flex items-center text-sm text-gray-600 mb-3">
              <FaMapMarkerAlt className="mr-1.5 text-primary-500 flex-shrink-0" />
              <span className="line-clamp-1">
                {[listing.address, listing.ward, listing.district, listing.city]
                  .filter(Boolean).join(', ')}
              </span>
            </div>

            {/* Thông tin chi tiết */}
            <div className="flex flex-wrap gap-4 mb-3">
              <div className="flex items-center text-sm">
                <FaRulerCombined className="mr-1.5 text-primary-500" />
                <span>{listing.area}m²</span>
              </div>
              {listing.bedrooms && (
                <div className="flex items-center text-sm">
                  <FaBed className="mr-1.5 text-primary-500" />
                  <span>{listing.bedrooms} phòng ngủ</span>
                </div>
              )}
            </div>

            {/* Số điện thoại (nếu là gói VIP nổi bật) */}
            {packageStyles.showPhone && (
              <div className="flex items-center mt-3 pt-3 border-t border-gray-100">
                <FaPhone className="mr-1.5 text-green-500" />
                <span className="font-medium text-green-600">
                  {listing.owner_phone || 'Không xác định'}
                </span>
              </div>
            )}
          </div>
        </div>
      </Link>
    </li>
  );
};

export default ListingItem;
