import React, { createContext, useState, useContext, useEffect } from 'react';
import { message } from 'antd';
import api from '../api';
import { useAuth } from './AuthContext';

const FavoriteContext = createContext();

export const useFavorites = () => {
  const context = useContext(FavoriteContext);
  if (!context) {
    throw new Error('useFavorites must be used within a FavoriteProvider');
  }
  return context;
};

export const FavoriteProvider = ({ children }) => {
  const [favorites, setFavorites] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { isAuthenticated } = useAuth();

  const fetchFavorites = async () => {
    if (!isAuthenticated) {
      setFavorites([]);
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      const response = await api.get('/favorites');
      if (response.data?.success) {
        setFavorites(response.data.data);
      }
    } catch (error) {
      console.error('Error fetching favorites:', error);
      message.error('Không thể tải danh sách yêu thích');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFavorites();
  }, [isAuthenticated]);

  const addFavorite = async (listingId) => {
    if (!isAuthenticated) {
      message.error('Vui lòng đăng nhập để thêm vào yêu thích');
      return { success: false };
    }

    try {
      const response = await api.post('/favorites', { listing_id: listingId });
      if (response.data?.success) {
        await fetchFavorites(); // Refresh the favorites list
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      console.error('Error adding favorite:', error);
      message.error('Không thể thêm vào yêu thích');
      return { success: false };
    }
  };

  const removeFavorite = async (listingId) => {
    if (!isAuthenticated) {
      message.error('Vui lòng đăng nhập để xóa khỏi yêu thích');
      return { success: false };
    }

    try {
      const response = await api.delete(`/favorites/${listingId}`);
      if (response.data?.success) {
        setFavorites(prev => prev.filter(fav => fav.id !== listingId));
        return { success: true };
      }
      return { success: false };
    } catch (error) {
      console.error('Error removing favorite:', error);
      message.error('Không thể xóa khỏi yêu thích');
      return { success: false };
    }
  };

  const isFavorite = (listingId) => {
    return favorites.some(fav => fav.id === listingId);
  };

  return (
    <FavoriteContext.Provider value={{
      favorites,
      isLoading,
      addFavorite,
      removeFavorite,
      isFavorite,
      refreshFavorites: fetchFavorites
    }}>
      {children}
    </FavoriteContext.Provider>
  );
};
