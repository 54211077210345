import React, { useState, useEffect, useContext } from 'react';
import { Form, Input, InputNumber, Select, Upload, Button, message, Row, Col, Card, Radio, Tag, Space, Alert } from 'antd';
import { UploadOutlined, PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import api from '../api';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';

const { Option } = Select;

const RequiredLabel = ({ children }) => (
    <span>
        <span className="text-red-500 mr-1">*</span>
        {children}
    </span>
);

const CreateListing = () => {
    const { user, isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [packages, setPackages] = useState([]);
    const [fileList, setFileList] = useState([]);
    const [editorData, setEditorData] = useState('');
    
    // State cho địa chỉ
    const [provinces, setProvinces] = useState([]);
    const [districts, setDistricts] = useState([]);
    const [wards, setWards] = useState([]);
    const [selectedProvince, setSelectedProvince] = useState('');
    const [selectedDistrict, setSelectedDistrict] = useState('');

    // Thêm state cho ảnh
    const [previewImages, setPreviewImages] = useState([]);
    const [uploadedImages, setUploadedImages] = useState([]);

    // Thêm state
    const [userBalance, setUserBalance] = useState(0);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [duration, setDuration] = useState('day'); // day, week, month

    // Thêm state cho video
    const [videoFile, setVideoFile] = useState(null);

    // Thêm state cho video preview
    const [videoPreview, setVideoPreview] = useState(null);

    // Initial form values
    const initialValues = {
        title: '',
        description: '',
        price: undefined,
        area: undefined,
        address: '',
        ward: '',
        district: '',
        city: '',
        type: undefined,
        package_id: undefined
    };

    // Thêm state để theo dõi lỗi của CKEditor
    const [editorError, setEditorError] = useState('');

    // Thêm state để theo dõi ID của form items
    const [formItemRefs] = useState({
        description: React.createRef(),
        images: React.createRef()
    });

    // Thêm state để theo dõi đã submit form chưa
    const [isSubmitted, setIsSubmitted] = useState(false);

    useEffect(() => {
        fetchPackages();
        fetchProvinces();
        fetchUserBalance();
    }, []);

    // Thêm hàm fetchPackages
    const fetchPackages = async () => {
        try {
            const response = await api.get('/packages');
            if (response.data && response.data.success) {
                setPackages(response.data.data);
            }
        } catch (error) {
            message.error('Lỗi khi tải danh sách gói tin');
        }
    };

    // Sửa lại API địa chỉ từ Github
    const fetchProvinces = async () => {
        try {
            const response = await fetch('https://raw.githubusercontent.com/kenzouno1/DiaGioiHanhChinhVN/master/data.json');
            const data = await response.json();
            setProvinces(data);
        } catch (error) {
            message.error('Lỗi khi tải danh sách tỉnh thành');
        }
    };

    // Sửa lại hàm fetch districts
    const fetchDistricts = async (provinceCode) => {
        try {
            const selectedProvince = provinces.find(p => p.Id === provinceCode);
            if (selectedProvince) {
                setDistricts(selectedProvince.Districts);
            }
        } catch (error) {
            message.error('Lỗi khi tải danh sách quận huyện');
        }
    };

    // Sửa lại hàm fetch wards
    const fetchWards = async (districtCode) => {
        try {
            const selectedDistrict = districts.find(d => d.Id === districtCode);
            if (selectedDistrict) {
                setWards(selectedDistrict.Wards);
            }
        } catch (error) {
            message.error('Lỗi khi tải danh sách phường xã');
        }
    };

    // Sửa lại các hàm xử lý onChange
    const handleProvinceChange = (value, option) => {
        setSelectedProvince(option.children);
        form.setFieldsValue({ 
            district: undefined, 
            ward: undefined,
            city: option.children // Lưu tên thành phố thay vì ID
        });
        setSelectedDistrict('');
        setWards([]);
        fetchDistricts(value);
    };

    const handleDistrictChange = (value, option) => {
        setSelectedDistrict(option.children);
        form.setFieldsValue({ 
            ward: undefined,
            district: option.children // Lưu tên quận/huyện thay vì ID
        });
        fetchWards(value);
    };

    const handleWardChange = (value, option) => {
        form.setFieldsValue({
            ward: option.children // Lưu tên phường/xã thay vì ID
        });
    };

    // Hàm lấy số dư tài khoản
    const fetchUserBalance = async () => {
        try {
            const response = await api.get('/user/balance');
            if (response.data && response.data.balance) {
                setUserBalance(response.data.balance);
            }
        } catch (error) {
            message.error('Lỗi khi lấy thng tin số dư');
        }
    };

    // Thêm hàm xử lý khi chọn gói tin
    const handlePackageChange = (value) => {
        const selected = packages.find(pkg => pkg.id === value);
        setSelectedPackage(selected);
        
        // Reset duration về mặc định khi đổi gói
        setDuration('day');
    };

    const onFinish = async (values) => {
        setIsSubmitted(true); // Đánh dấu form đã được submit
        
        // Validate mô tả chi tiết
        if (!editorData || editorData.trim() === '') {
            setEditorError('Vui lòng nhập mô tả chi tiết');
            formItemRefs.description.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }
        if (editorData.length < 50) {
            setEditorError('Mô tả chi tiết phải có ít nhất 50 ký tự');
            formItemRefs.description.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }

        // Validate ảnh
        if (fileList.length === 0) {
            formItemRefs.images.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
            return;
        }

        // Kiểm tra số điện thoại
        if (!user || !user.phone_number) {
            message.error('Bạn cần cung cấp số điện thoại trong quản lý thông tin cá nhân để đăng tin.');
            return;
        }

        try {
            setLoading(true);
            
            // Kiểm tra số lượng ảnh
            if (fileList.length === 0) {
                message.error('Vui lòng tải lên ít nhất một ảnh');
                return;
            }

            const formData = new FormData();
            
            // Thêm các trường cơ bản
            Object.keys(values).forEach(key => {
                if (values[key] !== undefined && values[key] !== null) {
                    // Đối với địa chỉ, lưu tên thay vì ID
                    if (key === 'city') {
                        formData.append(key, selectedProvince);
                    } else if (key === 'district') {
                        formData.append(key, selectedDistrict);
                    } else if (key === 'ward') {
                        formData.append(key, values[key]);
                    } else {
                        formData.append(key, values[key]);
                    }
                }
            });

            // Tạo địa chỉ đầy đủ
            const fullAddress = `${values.address}, ${values.ward}, ${selectedDistrict}, ${selectedProvince}`;
            formData.append('full_address', fullAddress);

            // Thêm mô tả và các thông tin khác
            formData.append('description', editorData);
            formData.append('package_id', values.package_id);
            formData.append('duration', getDuration());
            
            // Xử lý ảnh
            fileList.forEach((file, index) => {
                if (file.originFileObj) {
                    if (index === 0) {
                        formData.append('thumbnail', file.originFileObj);
                    } else {
                        formData.append('images[]', file.originFileObj);
                    }
                }
            });

            // Xử lý video
            if (videoFile) {
                formData.append('video', videoFile);
            }

            // Log để kiểm tra formData
            for (let pair of formData.entries()) {
                console.log(pair[0], pair[1]);
            }

            const response = await api.post('/listings', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.data.success) {
                message.success('Đăng tin thành công!');
                navigate('/');
            }
        } catch (error) {
            console.error('Error uploading:', error);
            message.error('Có lỗi xảy ra khi đăng tin');
        } finally {
            setLoading(false);
        }
    };

    // Cập nhật hàm onFinishFailed
    const onFinishFailed = (errorInfo) => {
        setIsSubmitted(true); // Đánh dấu form đã được submit
        
        // Kiểm tra lỗi mô tả chi tiết
        if (!editorData || editorData.trim() === '' || editorData.length < 50) {
            const descriptionElement = formItemRefs.description.current;
            if (descriptionElement) {
                descriptionElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return;
            }
        }

        // Kiểm tra lỗi ảnh
        if (fileList.length === 0) {
            const imagesElement = formItemRefs.images.current;
            if (imagesElement) {
                imagesElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                return;
            }
        }

        // Xử lý các lỗi khác
        const firstError = errorInfo.errorFields[0];
        if (firstError) {
            const element = document.querySelector(`[id="${firstError.name.join('_')}"]`);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                setTimeout(() => {
                    element.focus();
                }, 500);
            }
        }
    };

    // Cập nhật hàm handleEditorChange
    const handleEditorChange = (event, editor) => {
        const data = editor.getData();
        setEditorData(data);
        
        // Chỉ validate và hiển thị lỗi nếu form đã được submit
        if (isSubmitted) {
            if (!data || data.trim() === '') {
                setEditorError('Vui lòng nhập mô tả chi tiết');
            } else if (data.length < 50) {
                setEditorError('Mô tả chi tiết phải có ít nhất 50 ký tự');
            } else {
                setEditorError('');
            }
        }
    };

    const uploadProps = {
        listType: "picture-card",
        fileList: fileList,
        onChange: ({ fileList: newFileList, file }) => {
            // Kiểm tra file mới upload
            if (file.status === 'uploading') {
                const isImage = file.type?.startsWith('image/');
                if (!isImage) {
                    message.error('Chỉ chấp nhận file ảnh (jpg, jpeg, png)');
                    return;
                }
                const isLt2M = file.size / 1024 / 1024 < 2;
                if (!isLt2M) {
                    message.error(`Ảnh ${file.name} phải nhỏ hơn 2MB`);
                    return;
                }
            }
            setFileList(newFileList);
        },
        beforeUpload: file => {
            const isImage = file.type?.startsWith('image/');
            if (!isImage) {
                message.error('Chỉ chấp nhận file ảnh (jpg, jpeg, png)');
                return Upload.LIST_IGNORE;
            }
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                message.error(`Ảnh ${file.name} phải nhỏ hơn 2MB`);
                return Upload.LIST_IGNORE;
            }
            return false;
        },
        multiple: true,
        maxCount: 8,
        accept: '.jpg,.jpeg,.png'
    };

    // Add image upload handlers
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const uploadButton = (
        <div>
            <PlusOutlined />
            <div style={{ marginTop: 8 }}>Tải ảnh lên</div>
        </div>
    );

    // Thêm hàm tính giá theo duration
    const getPackagePrice = (pkg) => {
        switch(duration) {
            case 'week':
                return pkg.prices.week;
            case 'month':
                return pkg.prices.month;
            default:
                return pkg.prices.day * pkg.min_days;
        }
    };

    // Sửa lại hàm format tiền
    const formatCurrency = (amount) => {
        // Làm trn số và chuyển thành số nguyên
        const roundedAmount = Math.round(amount);
        // Format với dấu chấm và không có số thập phân
        return roundedAmount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    };

    // Sửa lại phần render gói tin
    const renderPackageOption = (pkg) => (
        <Option 
            key={pkg.id} 
            value={pkg.id}
            label={renderPackageLabel(pkg)}
        >
            <div className="py-2">
                <div className="flex justify-between items-start gap-2">
                    <div className="flex-1 min-w-0">
                        <div className="flex items-center gap-2">
                            <span className="font-medium truncate" style={{color: pkg.title_color}}>
                                {pkg.name}
                            </span>
                            {pkg.is_vip && (
                                <Tag color="gold">VIP</Tag>
                            )}
                        </div>
                        <div className="text-gray-500 text-sm mt-1 line-clamp-2">
                            {pkg.description}
                        </div>
                        <div className="text-blue-500 text-sm mt-1">
                            {duration === 'month' ? '30' : duration === 'week' ? '7' : pkg.min_days} ngày
                        </div>
                    </div>
                    
                    <div className="text-right flex-shrink-0">
                        <div className="flex items-center gap-2">
                            <span className="text-blue-600 font-medium whitespace-nowrap">
                                {formatCurrency(getPackagePrice(pkg))}đ
                            </span>
                            {duration === 'month' && pkg.prices.discount > 0 && (
                                <Tag color="red">-{pkg.prices.discount}%</Tag>
                            )}
                        </div>
                        {duration === 'month' && pkg.prices.discount > 0 && (
                            <div className="text-gray-400 line-through text-sm mt-1 whitespace-nowrap">
                                {formatCurrency(pkg.prices.month_original)}đ
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Option>
    );

    // Thêm hàm tính thời hạn
    const getDuration = () => {
        switch(duration) {
            case 'week':
                return 7;
            case 'month':
                return 30;
            default:
                const selectedPkg = packages.find(p => p.id === form.getFieldValue('package_id'));
                return selectedPkg?.min_days || 3;
        }
    };

    // Cập nhật li videoUploadProps
    const videoUploadProps = {
        name: 'video',
        accept: 'video/*',
        maxCount: 1,
        showUploadList: false,
        beforeUpload: file => {
            const isVideo = file.type.startsWith('video/');
            if (!isVideo) {
                message.error('Chỉ được upload file video!');
                return false;
            }
            const isLt50M = file.size / 1024 / 1024 < 50;
            if (!isLt50M) {
                message.error('Video phải nhỏ hơn 50MB!');
                return false;
            }
            
            // Tạo URL preview cho video
            const videoUrl = URL.createObjectURL(file);
            setVideoPreview(videoUrl);
            setVideoFile(file);
            return false; // Prevent auto upload
        },
        onRemove: () => {
            setVideoFile(null);
            setVideoPreview(null);
            URL.revokeObjectURL(videoPreview); // Clean up
        }
    };

    // Sửa lại phần render label cho Option
    const renderPackageLabel = (pkg) => (
        <div className="flex items-center gap-2">
            <span className="font-medium" style={{color: pkg.title_color}}>
                {pkg.name}
            </span>
            {pkg.is_vip && <Tag color="gold">VIP</Tag>}
            <span className="text-blue-600 font-medium">
                {formatCurrency(getPackagePrice(pkg))}đ
            </span>
        </div>
    );

    // Thêm hàm xử lý chuyển hướng đến trang nạp tiền
    const handleNavigateToDeposit = () => {
        navigate('/profile?tab=2');
    };

    return (
        <div className="container mx-auto px-4 py-6 max-w-4xl">
            <h1 className="text-xl md:text-2xl font-bold mb-6">Đăng tin mới</h1>
            <Form
                form={form}
                layout="vertical"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                initialValues={initialValues}
                requiredMark="optional"
                className="space-y-6"
            >
                <Card title="Thông tin cơ bản" className="shadow-sm">
                    <Form.Item
                        name="title"
                        label={<RequiredLabel>Tiêu đề</RequiredLabel>}
                        rules={[{ required: true, message: 'Vui lòng nhập tiêu đề' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={<RequiredLabel>Mô tả chi tiết</RequiredLabel>}
                        required
                        validateStatus={editorError ? 'error' : ''}
                        help={editorError}
                    >
                        <div ref={formItemRefs.description}>
                            <CKEditor
                                editor={ClassicEditor}
                                data={editorData}
                                onChange={handleEditorChange}
                            />
                        </div>
                    </Form.Item>
                </Card>

                <Card title="Giá và Diện tích" className="shadow-sm">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item 
                                name="price" 
                                label={<RequiredLabel>Giá thuê (VNĐ/tháng)</RequiredLabel>}
                                rules={[{ required: true, message: 'Vui lòng nhập giá thuê' }]}
                            >
                                <InputNumber
                                    className="w-full"
                                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} md={8}>
                            <Form.Item 
                                name="area" 
                                label={<RequiredLabel>Diện tích (m²)</RequiredLabel>}
                                rules={[{ required: true, message: 'Vui lòng nhập diện tích' }]}
                            >
                                <InputNumber className="w-full" />
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item 
                                name="type" 
                                label={<RequiredLabel>Loại phòng</RequiredLabel>}
                                rules={[{ required: true, message: 'Vui lòng chọn loại phòng' }]}
                            >
                                <Select>
                                    <Option value="Phòng trọ">Phòng trọ</Option>
                                    <Option value="Căn hộ">Căn hộ</Option>
                                    <Option value="Nhà nguyên căn">Nhà nguyên căn</Option>
                                    <Option value="Mặt bằng">Mặt bằng</Option>
                                    <Option value="Ở ghép">Ở ghép</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card 
                    title={
                        <div className="flex flex-col sm:flex-row sm:items-center justify-between">
                            <span>Chọn gói tin</span>
                            <Radio.Group 
                                value={duration} 
                                onChange={e => setDuration(e.target.value)}
                                className="mt-2 sm:mt-0"
                            >
                                <Radio.Button value="day">Ngày</Radio.Button>
                                <Radio.Button value="week">Tuần</Radio.Button>
                                <Radio.Button value="month">Tháng</Radio.Button>
                            </Radio.Group>
                        </div>
                    }
                    className="shadow-sm"
                >
                    <div className="mb-4">
                        <Alert
                            message={
                                <div className="flex items-center justify-between flex-wrap gap-2">
                                    <span>Số dư hiện tại: <strong>{formatCurrency(userBalance)}đ</strong></span>
                                    <Button 
                                        type="primary" 
                                        onClick={handleNavigateToDeposit}
                                        size="small"
                                    >
                                        Nạp tiền
                                    </Button>
                                </div>
                            }
                            type="info"
                            showIcon
                        />
                    </div>

                    <Form.Item
                        name="package_id"
                        rules={[{ required: true, message: 'Vui lòng chọn gói tin' }]}
                    >
                        <Select
                            placeholder="Chọn gói tin"
                            optionLabelProp="label"
                            className="w-full"
                        >
                            {packages.map(pkg => (
                                <Option 
                                    key={pkg.id} 
                                    value={pkg.id}
                                    label={
                                        <div className="flex items-center justify-between">
                                            <span style={{color: pkg.title_color}}>{pkg.name}</span>
                                            <span className="text-blue-600">
                                                {formatCurrency(getPackagePrice(pkg))}đ
                                            </span>
                                        </div>
                                    }
                                >
                                    <div className="p-2">
                                        <div className="flex items-start justify-between gap-4">
                                            <div className="flex-1 min-w-0">
                                                <div className="flex items-center gap-2">
                                                    <span className="font-medium" style={{color: pkg.title_color}}>
                                                        {pkg.name}
                                                    </span>
                                                    {pkg.is_vip && <Tag color="gold">VIP</Tag>}
                                                </div>
                                                <div className="text-gray-500 text-sm mt-1">
                                                    {pkg.description}
                                                </div>
                                                <div className="text-blue-500 text-sm">
                                                    {duration === 'month' ? '30' : duration === 'week' ? '7' : pkg.min_days} ngày
                                                </div>
                                            </div>
                                            <div className="text-right whitespace-nowrap">
                                                <div className="text-blue-600 font-medium">
                                                    {formatCurrency(getPackagePrice(pkg))}đ
                                                </div>
                                                {duration === 'month' && pkg.prices.discount > 0 && (
                                                    <>
                                                        <Tag color="red" className="mt-1">-{pkg.prices.discount}%</Tag>
                                                        <div className="text-gray-400 line-through text-sm">
                                                            {formatCurrency(pkg.prices.month_original)}đ
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </Option>
                            ))}
                        </Select>
                    </Form.Item>
                </Card>

                <Card title="Địa chỉ" className="shadow-sm">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={8}>
                            <Form.Item
                                name="city"
                                label={<RequiredLabel>Tỉnh/Thành phố</RequiredLabel>}
                                rules={[{ required: true, message: 'Vui lòng chọn tỉnh/thành phố' }]}
                            >
                                <Select
                                    placeholder="Chọn tỉnh/thành phố"
                                    onChange={handleProvinceChange}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {provinces.map(province => (
                                        <Option key={province.Id} value={province.Id}>
                                            {province.Name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={8}>
                            <Form.Item
                                name="district"
                                label={<RequiredLabel>Quận/Huyện</RequiredLabel>}
                                rules={[{ required: true, message: 'Vui lòng chọn quận/huyện' }]}
                            >
                                <Select
                                    placeholder="Chọn quận/huyện"
                                    onChange={handleDistrictChange}
                                    disabled={!selectedProvince}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {districts.map(district => (
                                        <Option key={district.Id} value={district.Id}>
                                            {district.Name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col xs={24} md={8}>
                            <Form.Item
                                name="ward"
                                label={<RequiredLabel>Phường/Xã</RequiredLabel>}
                                rules={[{ required: true, message: 'Vui lòng chọn phường/xã' }]}
                            >
                                <Select
                                    placeholder="Chọn phường/xã"
                                    onChange={handleWardChange}
                                    disabled={!selectedDistrict}
                                    showSearch
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                >
                                    {wards.map(ward => (
                                        <Option key={ward.Id} value={ward.Id}>
                                            {ward.Name}
                                        </Option>
                                    ))}
                                </Select>
                            </Form.Item>
                        </Col>

                        <Col span={24}>
                            <Form.Item
                                name="address"
                                label={<RequiredLabel>Địa chỉ cụ thể</RequiredLabel>}
                                rules={[{ required: true, message: 'Vui lòng nhập địa chỉ cụ thể' }]}
                            >
                                <Input.TextArea 
                                    placeholder="Nhập số nhà, tên đường..."
                                    autoSize={{ minRows: 2, maxRows: 4 }}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Card title="Hình ảnh và Video" className="shadow-sm">
                    <Row gutter={[16, 16]}>
                        <Col xs={24} md={16}>
                            <Form.Item 
                                label={
                                    <div className="flex items-center justify-between w-full">
                                        <RequiredLabel>Hình ảnh</RequiredLabel>
                                        <span className="text-gray-500 text-sm">
                                            {fileList.length}/8 ảnh
                                        </span>
                                    </div>
                                }
                                required
                                validateStatus={isSubmitted && fileList.length === 0 ? 'error' : ''}
                                help={isSubmitted && fileList.length === 0 ? 'Vui lòng tải lên ít nhất một ảnh' : ''}
                            >
                                <Upload {...uploadProps}>
                                    {fileList.length >= 8 ? null : uploadButton}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col xs={24} md={8}>
                            <Form.Item label="Video giới thiệu">
                                <Upload {...videoUploadProps}>
                                    <Button icon={<UploadOutlined />}>Tải video lên</Button>
                                </Upload>
                                {videoPreview && (
                                    <div className="mt-4">
                                        <video 
                                            src={videoPreview} 
                                            controls 
                                            className="w-full rounded-lg"
                                        />
                                    </div>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>

                <Form.Item className="text-right">
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={loading}
                        size="large"
                        className="min-w-[150px]"
                    >
                        Đăng tin
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};

export default CreateListing;
