import React, { useState, useEffect, useMemo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import SearchBar from './SearchBar';
import ListingItem from './ListingItem';
import api from '../api';
import { FaMoneyBillWave, FaRulerCombined, FaHome, FaBuilding, FaWarehouse, FaUserFriends, FaStar } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { formatDistanceToNow } from 'date-fns';
import { vi } from 'date-fns/locale';
import { Spin } from 'antd';
import { Pagination } from 'antd';

const HomePage = ({ initialSearchType }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [latestListings, setLatestListings] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [searchParams, setSearchParams] = useState({
    type: initialSearchType || '',
    city: '',
    district: null,
    ward: null,
    minPrice: '',
    maxPrice: '',
    minArea: '',
    maxArea: ''
  });
  const [isShowingSearchResults, setIsShowingSearchResults] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [latestNews, setLatestNews] = useState([]);

  // Thêm state cho phân trang
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0
  });

  // Tính toán các items cho trang hiện tại
  const indexOfLastItem = pagination.current * pagination.pageSize;
  const indexOfFirstItem = indexOfLastItem - pagination.pageSize;
  const currentItems = (isShowingSearchResults ? searchResults : latestListings)
    .sort((a, b) => {
      if (a.is_vip && !b.is_vip) return -1;
      if (!a.is_vip && b.is_vip) return 1;
      return new Date(b.created_at) - new Date(a.created_at);
    })
    .slice(indexOfFirstItem, indexOfLastItem);

  // Xử lý thay đổi trang
  const handlePageChange = (page) => {
    setPagination(prev => ({ ...prev, current: page }));
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Format số để ẩn số 0
  const formatNumber = (number) => {
    if (!number) return '';
    return number.toString().replace(/^0+/, '');
  };

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchLatestListings = async () => {
      setIsLoading(true);
      try {
        const response = await api.get('/listings/latest', {
          params: {
            page: pagination.current,
            pageSize: pagination.pageSize
          }
        });
        
        console.log('Latest listings response:', response); // Debug log
        
        if (response.data && response.data.success) {
          setLatestListings(response.data.data || []);
          setPagination(prev => ({
            ...prev,
            total: response.data.pagination.total
          }));
        } else {
          console.error('Failed to fetch listings:', response.data?.error);
          setLatestListings([]);
        }
      } catch (error) {
        console.error('Error fetching latest listings:', error);
        setLatestListings([]);
      } finally {
        setIsLoading(false);
      }
    };

    fetchLatestListings();
  }, [pagination.current, pagination.pageSize]);

  useEffect(() => {
    if (location.state && location.state.searchType) {
      setSearchParams(prevParams => ({ ...prevParams, type: location.state.searchType }));
      handleSearch({ type: location.state.searchType });
    }
  }, [location.state]);

  useEffect(() => {
    if (searchParams.city) {
      handleSearch(searchParams);
    }
  }, [searchParams.city]);

  useEffect(() => {
    const fetchLatestNews = async () => {
      try {
        const response = await api.get('/news/public', {
          params: { 
            limit: 5,
            page: 1
          }
        });
        if (response.data && response.data.success) {
          setLatestNews(response.data.data || []);
        }
      } catch (error) {
        console.error('Error fetching latest news:', error);
      }
    };

    fetchLatestNews();
  }, []);

  const handleSearch = async (conditions) => {
    setIsSearching(true);
    setIsShowingSearchResults(true);
    setPagination(prev => ({ ...prev, current: 1 }));

    try {
      const searchConditions = {
        ...conditions,
        page: pagination.current,
        pageSize: pagination.pageSize
      };

      const response = await api.get('/listings/search', { 
        params: searchConditions
      });

      if (response.data && response.data.success) {
        setSearchResults(response.data.data || []);
        setPagination(prev => ({
          ...prev,
          total: response.data.pagination.total
        }));
        
        // Scroll to search results
        const searchResults = document.getElementById('search-results');
        if (searchResults) {
          searchResults.scrollIntoView({ behavior: 'smooth' });
        }
      } else {
        console.error('Search failed:', response.data?.error);
        setSearchResults([]);
      }
    } catch (error) {
      console.error('Error searching listings:', error);
      setSearchResults([]);
    } finally {
      setIsSearching(false);
    }
  };

  const handleSearchResults = (results) => {
    setSearchResults(results);
    setIsShowingSearchResults(true);
    setMainListingsPage(1);
  };

  const handleSearchTypeChange = (type) => {
    setSearchParams(prevParams => ({ ...prevParams, type }));
    handleSearch({ type });
  };

  const handleAsideSearch = async (params) => {
    const updatedParams = {
      ...searchParams,
      ...params,
      // Reset district và ward khi thay đổi city
      ...(params.city && {
        district: null,
        ward: null
      })
    };
    
    setSearchParams(updatedParams);
    await handleSearch(updatedParams);

    // Scroll sau khi có kết quả tìm kiếm
    setTimeout(() => {
      const searchResults = document.getElementById('search-results');
      if (searchResults) {
        searchResults.scrollIntoView({ behavior: 'smooth' });
      }
    }, 100);
  };

  const handleFeaturedAreaClick = async (city) => {
    const updatedParams = { 
      ...searchParams,
      city,
      district: null,  // Reset về null
      ward: null       // Reset về null
    };
    
    setSearchParams(updatedParams);
    await handleSearch(updatedParams);
  };

  const handleCategorySearch = (category) => {
    handleSearch({ type: category });
  };

  const listingsToShow = useMemo(() => {
    const listings = isShowingSearchResults ? searchResults : latestListings;
    return listings
        .sort((a, b) => {
            // Thứ tự ưu tiên: VIP nổi bật (packageId = 1) > VIP 1 (packageId = 2) > VIP 2 (packageId = 3) > VIP 3 (packageId = 4) > Tin thường (packageId = 5)
            const getPackagePriority = (packageId) => {
                switch (packageId) {
                    case 1: return 1; // VIP nổi bật
                    case 2: return 2; // VIP 1
                    case 3: return 3; // VIP 2
                    case 4: return 4; // VIP 3
                    case 5: return 5; // Tin thường
                    default: return 6; // Các tin khác
                }
            };

            const priorityA = getPackagePriority(a.package_id);
            const priorityB = getPackagePriority(b.package_id);

            // Sắp xếp theo priority (số nhỏ hơn = ưu tiên cao hơn)
            if (priorityA !== priorityB) {
                return priorityA - priorityB;
            }

            // Nếu cùng priority thì sắp xếp theo thời gian đăng (mới nhất lên trước)
            return new Date(b.created_at) - new Date(a.created_at);
        })
        .slice(indexOfFirstItem, indexOfLastItem);
  }, [isShowingSearchResults, searchResults, latestListings, indexOfFirstItem, indexOfLastItem]);

  const resetToLatestListings = () => {
    setIsShowingSearchResults(false);
    setSearchResults([]);
  };

  // Debug log để kiểm tra dữ liệu
  useEffect(() => {
    console.log('Listings to show:', isShowingSearchResults ? searchResults : latestListings);
  }, [isShowingSearchResults, searchResults, latestListings]);

  // Hàm sắp xếp tin theo thứ tự ưu tiên gói tin
  const sortListingsByPackage = (listings) => {
    return [...listings].sort((a, b) => {
      // Thứ tự ưu tiên: VIP nổi bật (1) > VIP 1 (2) > VIP 2 (3) > VIP 3 (4) > Tin thường (5)
      const getPackagePriority = (packageId) => {
        switch (packageId) {
          case 1: return 1; // VIP nổi bật
          case 2: return 2; // VIP 1
          case 3: return 3; // VIP 2
          case 4: return 4; // VIP 3
          case 5: return 5; // Tin thường
          default: return 6; // Các tin khác
        }
      };

      const priorityA = getPackagePriority(a.package_id);
      const priorityB = getPackagePriority(b.package_id);

      // Sắp xếp theo priority (số nhỏ hơn = ưu tiên cao hơn)
      if (priorityA !== priorityB) {
        return priorityA - priorityB;
      }

      // Nếu cùng priority thì sắp xếp theo thời gian đăng (mới nhất lên trước)
      return new Date(b.created_at) - new Date(a.created_at);
    });
  };

  // Thêm states cho phân trang
  const [mainListingsPage, setMainListingsPage] = useState(1);
  const [mainListingsPerPage] = useState(10);
  const [totalListings, setTotalListings] = useState(0);

  // Tính toán phân trang cho danh sách chính
  const indexOfLastListing = mainListingsPage * mainListingsPerPage;
  const indexOfFirstListing = indexOfLastListing - mainListingsPerPage;
  const currentListings = sortListingsByPackage(listingsToShow).slice(
    indexOfFirstListing,
    indexOfLastListing
  );

  // Cập nht renderListings
  const renderListings = () => {
    // Sắp xếp tin đăng theo thứ tự ưu tiên
    const sortedListings = sortListingsByPackage(isShowingSearchResults ? searchResults : latestListings);
    
    if (isLoading) {
      return (
        <div className="flex justify-center py-8">
          <Spin size="large" />
        </div>
      );
    }

    if (sortedListings.length === 0) {
      return (
        <div className="text-center py-8">
          <p className="text-gray-500">Không tìm thấy kết quả phù hợp</p>
        </div>
      );
    }

    return (
      <div className="space-y-6">
        {sortedListings.map((listing) => (
          <ListingItem 
            key={listing.id} 
            listing={{
              ...listing,
              thumbnail: listing.thumbnail?.startsWith('http') 
                ? listing.thumbnail 
                : `https://thuenhadedang.io.vn${listing.thumbnail}`,
              bedrooms: formatNumber(listing.bedrooms),
              area: formatNumber(listing.area)
            }} 
          />
        ))}

        <div className="mt-8">
          <Pagination
            current={pagination.current}
            total={pagination.total}
            pageSize={pagination.pageSize}
            onChange={handlePageChange}
            showSizeChanger={false}
            className="flex justify-center"
          />
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (searchResults.length > 0) {
      console.log('Updated search results:', searchResults.length, 'items');
    }
  }, [searchResults]);

  const latestListingsToShow = useMemo(() => {
    return latestListings
        .sort((a, b) => {
            // Thứ tự ưu tiên: VIP nổi bật (packageId = 1) > VIP 1 (packageId = 2) > VIP 2 (packageId = 3) > VIP 3 (packageId = 4) > Tin thường (packageId = 5)
            const getPackagePriority = (packageId) => {
                switch (packageId) {
                    case 1: return 1; // VIP nổi bật
                    case 2: return 2; // VIP 1
                    case 3: return 3; // VIP 2
                    case 4: return 4; // VIP 3
                    case 5: return 5; // Tin thường
                    default: return 6; // Các tin khác
                }
            };

            const priorityA = getPackagePriority(a.package_id);
            const priorityB = getPackagePriority(b.package_id);

            // Sắp xếp theo priority (số nhỏ hơn = ưu tiên cao hơn)
            if (priorityA !== priorityB) {
                return priorityA - priorityB;
            }

            // Nếu cùng priority thì sắp xếp theo thời gian đăng (mới nhất lên trước)
            return new Date(b.created_at) - new Date(a.created_at);
        });
  }, [latestListings]);

  return (
    <div className="min-h-screen w-full bg-gray-100">
      {/* Hero Section với SearchBar */}
      <div className="w-full bg-primary-100 pt-16">
        <div className="w-full py-8 md:py-16">
          <div className="max-w-6xl mx-auto px-4">
            <h1 className="text-2xl md:text-4xl font-bold text-center mb-4">Tìm kiếm chỗ thuê ưng ý</h1>
            <p className="text-base md:text-lg text-center mb-8 max-w-3xl mx-auto">
              Kênh thông tin Phòng trọ số 1 Việt Nam
            </p>
          </div>
          {/* SearchBar container */}
          <div className="w-full bg-white py-4 md:py-8 shadow-md rounded-lg">
            <div className="max-w-6xl mx-auto px-2 md:px-4">
              <SearchBar 
                onSearchResults={handleSearchResults}
                onClearSearch={resetToLatestListings}
                initialSearchType={searchParams.type} 
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                onSearch={handleSearch}
                className="w-full"
              />
            </div>
          </div>
        </div>
      </div>

      {/* Featured Areas Section */}
      <section className="w-full bg-white py-12 shadow-md">
        <div className="max-w-6xl mx-auto px-4">
          <h2 className="text-3xl font-bold mb-8 text-center">Khu vực nổi bật</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
            {/* Hà Nội */}
            <div 
              className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer transition-transform duration-300 hover:scale-105"
              onClick={() => handleFeaturedAreaClick('Thành phố Hà Nội')}
            >
              <div 
                className="absolute inset-0 bg-cover bg-center" 
                style={{backgroundImage: "url('https://thuenhadedang.io.vn/uploads/hanoi.jpg')"}}
              ></div>
              <div className="absolute inset-0 bg-black opacity-40"></div>
              <div className="relative p-6">
                <h3 className="text-xl font-semibold text-white mb-2"></h3>
                <p className="text-2xl font-bold text-white">Hà Nội</p>
              </div>
            </div>

            {/* Đà Nẵng */}
            <div 
              className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer transition-transform duration-300 hover:scale-105"
              onClick={() => handleFeaturedAreaClick('Thành phố Đà Nẵng')}
            >
              <div 
                className="absolute inset-0 bg-cover bg-center" 
                style={{backgroundImage: "url('https://thuenhadedang.io.vn/uploads/danang.jpg')"}}
              ></div>
              <div className="absolute inset-0 bg-black opacity-40"></div>
              <div className="relative p-6">
                <h3 className="text-xl font-semibold text-white mb-2"></h3>
                <p className="text-2xl font-bold text-white">Đà Nẵng</p>
              </div>
            </div>

            {/* Hồ Chí Minh */}
            <div 
              className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer transition-transform duration-300 hover:scale-105"
              onClick={() => handleFeaturedAreaClick('Thành phố Hồ Chí Minh')}
            >
              <div 
                className="absolute inset-0 bg-cover bg-center" 
                style={{backgroundImage: "url('https://thuenhadedang.io.vn/uploads/hochiminh.jpg')"}}
              ></div>
              <div className="absolute inset-0 bg-black opacity-40"></div>
              <div className="relative p-6">
                <h3 className="text-xl font-semibold text-white mb-2"></h3>
                <p className="text-2xl font-bold text-white">Hồ Chí Minh</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Main Content */}
      <div className="max-w-6xl mx-auto px-4 py-8">
        <div className="flex flex-col lg:flex-row gap-8">
          {/* Main Listings */}
          <div className="w-full lg:w-2/3">
            <section id="search-results" className="mb-8">
              <h2 className="text-2xl font-semibold mb-6">
                {isShowingSearchResults 
                  ? `Kết quả tìm kiếm `
                  : 'Tin đăng mới nhất'
                }
              </h2>
              {isLoading ? (
                <div className="flex justify-center py-8">
                  <Spin size="large" />
                </div>
              ) : (
                renderListings()
              )}
            </section>
          </div>

          {/* Aside */}
          <aside className="w-full lg:w-1/3 space-y-6">
            {/* Danh mục loại nhà */}
            <div className="bg-white shadow-md rounded-lg p-6 border border-gray-200">
              <h3 className="text-xl font-semibold mb-4">Danh mục cho thuê</h3>
              <div className="flex flex-col space-y-3">
                <a 
                  href="#" 
                  onClick={(e) => {
                    e.preventDefault();
                    handleAsideSearch({ type: 'Phòng trọ' });
                  }}
                  className="flex items-center text-primary-600 hover:underline text-sm"
                >
                  <div className="flex items-center">
                    <FaHome className="mr-2 text-primary-500" />
                    <span>Phòng trọ</span>
                  </div>
                </a>

                <a 
                  href="#" 
                  onClick={(e) => {
                    e.preventDefault();
                    handleAsideSearch({ type: 'Căn hộ' });
                  }}
                  className="flex items-center text-primary-600 hover:underline text-sm"
                >
                  <div className="flex items-center">
                    <FaBuilding className="mr-2 text-primary-500" />
                    <span>Căn hộ</span>
                  </div>
                </a>

                <a 
                  href="#" 
                  onClick={(e) => {
                    e.preventDefault();
                    handleAsideSearch({ type: 'Nhà nguyên căn' });
                  }}
                  className="flex items-center text-primary-600 hover:underline text-sm"
                >
                  <div className="flex items-center">
                    <FaHome className="mr-2 text-primary-500" />
                    <span>Nhà nguyên căn</span>
                  </div>
                </a>

                <a 
                  href="#" 
                  onClick={(e) => {
                    e.preventDefault();
                    handleAsideSearch({ type: 'Mặt bằng' });
                  }}
                  className="flex items-center text-primary-600 hover:underline text-sm"
                >
                  <div className="flex items-center">
                    <FaWarehouse className="mr-2 text-primary-500" />
                    <span>Mặt bằng</span>
                  </div>
                </a>

                <a 
                  href="#" 
                  onClick={(e) => {
                    e.preventDefault();
                    handleAsideSearch({ type: 'Ở ghép' });
                  }}
                  className="flex items-center text-primary-600 hover:underline text-sm"
                >
                  <div className="flex items-center">
                    <FaUserFriends className="mr-2 text-primary-500" />
                    <span>Tìm người ở ghép</span>
                  </div>
                </a>
              </div>
            </div>

            {/* Lọc theo giá */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-4">Xem theo giá</h3>
              <div className="grid grid-cols-2 gap-3">
                <a href="#" onClick={() => handleAsideSearch({ minPrice: 0, maxPrice: 3000000 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaMoneyBillWave className="mr-2 text-primary-500" />
                  <span>Dưới 3 triệu</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minPrice: 3000000, maxPrice: 5000000 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaMoneyBillWave className="mr-2 text-primary-500" />
                  <span>3 - 5 triệu</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minPrice: 5000000, maxPrice: 7000000 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaMoneyBillWave className="mr-2 text-primary-500" />
                  <span>5 - 7 triệu</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minPrice: 7000000, maxPrice: 10000000 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaMoneyBillWave className="mr-2 text-primary-500" />
                  <span>7 - 10 triệu</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minPrice: 10000000, maxPrice: 15000000 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaMoneyBillWave className="mr-2 text-primary-500" />
                  <span>10 - 15 triệu</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minPrice: 15000000 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaMoneyBillWave className="mr-2 text-primary-500" />
                  <span>Trên 15 triệu</span>
                </a>
              </div>
            </div>

            {/* Lọc theo diện tích */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <h3 className="text-xl font-semibold mb-4">Xem theo diện tích</h3>
              <div className="grid grid-cols-2 gap-3">
                <a href="#" onClick={() => handleAsideSearch({ minArea: 0, maxArea: 20 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaRulerCombined className="mr-2 text-primary-500" />
                  <span>Dưới 20m²</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minArea: 20, maxArea: 30 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaRulerCombined className="mr-2 text-primary-500" />
                  <span>20 - 30m²</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minArea: 30, maxArea: 50 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaRulerCombined className="mr-2 text-primary-500" />
                  <span>30 - 50m²</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minArea: 50, maxArea: 70 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaRulerCombined className="mr-2 text-primary-500" />
                  <span>50 - 70m²</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minArea: 70, maxArea: 90 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaRulerCombined className="mr-2 text-primary-500" />
                  <span>70 - 90m²</span>
                </a>
                <a href="#" onClick={() => handleAsideSearch({ minArea: 90 })} className="flex items-center text-primary-600 hover:underline text-sm">
                  <FaRulerCombined className="mr-2 text-primary-500" />
                  <span>Trên 90m²</span>
                </a>
              </div>
            </div>

            {/* Latest Listings Aside */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold">Tin đăng mới nhất</h3>
                {/* <Link to="/listings" className="text-primary-600 hover:text-primary-700 text-sm font-medium">
                  Xem tất cả →
                </Link> */}
              </div>
              <div className="space-y-4">
                {latestListings.slice(0, 5).map((listing) => (
                  <AsideListingItem key={listing.id} listing={listing} />
                ))}
              </div>
            </div>

            {/* Latest News Aside */}
            <div className="bg-white shadow-md rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold">Tin tức mới nhất</h3>
                <Link to="/news" className="text-primary-600 hover:text-primary-700 text-sm font-medium">
                  Xem tất cả →
                </Link>
              </div>
              <div className="space-y-4">
                {latestNews.slice(0, 5).map((news) => (
                  <AsideNewsItem key={news.id} news={news} />
                ))}
              </div>
            </div>
          </aside>
        </div>
      </div>
    </div>
  );
};

// Component cho tin đăng trong aside
const AsideListingItem = ({ listing }) => (
  <Link to={`/listing/${listing.id}`} className="flex items-start hover:bg-gray-50 p-2 rounded-lg transition-colors">
    <img
      src={listing.thumbnail?.startsWith('http') ? listing.thumbnail : `https://thuenhadedang.io.vn${listing.thumbnail}`}
      alt={listing.title}
      className="w-20 h-20 object-cover rounded-lg"
      onError={(e) => { e.target.src = '/placeholder-image.jpg'; }}
    />
    <div className="ml-3 flex-1 min-w-0">
      <h4 className="text-sm font-medium text-gray-900 line-clamp-2">{listing.title}</h4>
      <p className="text-sm text-green-600 font-medium mt-1">
        {listing.price ? `${Number(listing.price).toLocaleString()}đ/tháng` : 'Liên hệ'}
      </p>
      <p className="text-xs text-gray-500 mt-1">
        {formatDistanceToNow(new Date(listing.created_at), { addSuffix: true, locale: vi })}
      </p>
    </div>
  </Link>
);

// Component cho tin tức trong aside
const AsideNewsItem = ({ news }) => (
  <Link to={`/news/${news.id}`} className="flex items-start hover:bg-gray-50 p-2 rounded-lg transition-colors">
    <img
      src={news.thumbnail?.startsWith('http') ? news.thumbnail : `https://thuenhadedang.io.vn${news.thumbnail}`}
      alt={news.title}
      className="w-20 h-20 object-cover rounded-lg"
      onError={(e) => { e.target.src = '/placeholder-image.jpg'; }}
    />
    <div className="ml-3 flex-1 min-w-0">
      <h4 className="text-sm font-medium text-gray-900 line-clamp-2">
        {news.title}
      </h4>
      <span className="block text-xs text-gray-500 mt-1">
        {formatDistanceToNow(new Date(news.created_at), { addSuffix: true, locale: vi })}
      </span>
      {/* <span className="block text-xs text-gray-600 mt-1 line-clamp-2">
        {news.excerpt || (news.content && news.content.replace(/<\/?p>/g, '').substring(0, 100))}...
      </span> */}
    </div>
  </Link>
);

export default HomePage;
