import React, { useState, useEffect } from 'react';
import { Table, Button, message, Modal, Input, Image, Tag, Tabs, Space, Tooltip } from 'antd';
import { EyeOutlined, CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { getAdminTransactions, approveTransaction, rejectTransaction } from '../../api';

const { TabPane } = Tabs;

const TransactionManagement = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [rejectModalVisible, setRejectModalVisible] = useState(false);
  const [rejectReason, setRejectReason] = useState('');
  const [selectedTransactionId, setSelectedTransactionId] = useState(null);
  const [viewProofModal, setViewProofModal] = useState(false);
  const [selectedTransaction, setSelectedTransaction] = useState(null);

  useEffect(() => {
    fetchTransactions();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
        fetchTransactions();
    }, 30000); // Refresh mỗi 30 giây

    return () => clearInterval(interval);
  }, []);

  const fetchTransactions = async () => {
    setLoading(true);
    try {
      const response = await getAdminTransactions();
      if (response.success) {
        const formattedTransactions = response.data.map(transaction => ({
          ...transaction,
          key: transaction.id,
          amount: parseFloat(transaction.amount)
        }));
        setTransactions(formattedTransactions);
      } else {
        throw new Error(response.error || 'Có lỗi xảy ra khi tải dữ liệu');
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
      message.error(error.message || 'Có lỗi xảy ra khi tải dữ liệu');
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (id) => {
    try {
      const confirm = window.confirm('Bạn có chắc chắn muốn duyệt giao dịch này?');
      if (!confirm) return;

      setLoading(true);
      const response = await approveTransaction(id);
      
      if (response.success) {
        message.success('Duyệt giao dịch thành công');
        await fetchTransactions();
      } else {
        throw new Error(response.error || 'Có lỗi xảy ra');
      }
    } catch (error) {
      console.error('Error approving transaction:', error);
      message.error(error.message || 'Có lỗi xảy ra khi duyệt giao dịch');
    } finally {
      setLoading(false);
    }
  };

  const showRejectModal = (id) => {
    setSelectedTransactionId(id);
    setRejectModalVisible(true);
  };

  const handleReject = async () => {
    if (!rejectReason.trim()) {
      message.error('Vui lòng nhập lý do từ chối');
      return;
    }

    try {
      setLoading(true);
      const response = await rejectTransaction(selectedTransactionId, rejectReason);
      
      if (response.success) {
        message.success('Từ chối giao dịch thành công');
        setRejectModalVisible(false);
        setRejectReason('');
        await fetchTransactions();
      } else {
        throw new Error(response.error || 'Có lỗi xảy ra');
      }
    } catch (error) {
      console.error('Error rejecting transaction:', error);
      message.error(error.message || 'Có lỗi xảy ra khi từ chối giao dịch');
    } finally {
      setLoading(false);
    }
  };

  const showProofImage = (transaction) => {
    setSelectedTransaction(transaction);
    setViewProofModal(true);
  };

  const columns = [
    {
        title: 'Mã GD',
        dataIndex: 'id',
        key: 'id',
        render: id => <Tag color="blue">#{id}</Tag>
    },
    {
        title: 'Thời gian',
        dataIndex: 'created_at',
        key: 'created_at',
        render: date => new Date(date).toLocaleString('vi-VN')
    },
    {
        title: 'Người dùng',
        dataIndex: 'user',
        key: 'user',
        render: (_, record) => (
            <div>
                <div>{record.user?.full_name}</div>
                <div className="text-gray-500 text-sm">{record.user?.email}</div>
            </div>
        )
    },
    {
        title: 'Loại giao dịch',
        key: 'type',
        render: (_, record) => {
            const isDeposit = record.description?.toLowerCase().includes('nạp tiền');
            return (
                <Tag color={isDeposit ? 'green' : 'red'}>
                    {isDeposit ? 'Nạp tiền' : 'Thanh toán'}
                </Tag>
            );
        }
    },
    {
        title: 'Số tiền',
        dataIndex: 'amount',
        key: 'amount',
        render: (amount, record) => {
            const isDeposit = record.description?.toLowerCase().includes('nạp tiền');
            return (
                <div className={`font-medium ${isDeposit ? 'text-green-500' : 'text-red-500'}`}>
                    <span className="mr-1">{isDeposit ? '+' : '-'}</span>
                    <span>{Number(amount).toLocaleString()}đ</span>
                    <div className="text-gray-500 text-xs">
                        {isDeposit ? '(Nạp tiền)' : '(Thanh toán)'}
                    </div>
                </div>
            );
        }
    },
    {
        title: 'Phương thức',
        dataIndex: 'payment_method',
        key: 'payment_method',
        render: method => {
            if (!method) return '-';
            return method === 'bank_transfer' ? 'Chuyển khoản' : 'Ví MoMo';
        }
    },
    {
        title: 'Nội dung',
        dataIndex: 'description',
        key: 'description',
        render: (desc, record) => (
            <div>
                <div>{desc}</div>
                {record.transfer_content && (
                    <div className="text-gray-500 text-sm">
                        Mã GD: {record.transfer_content}
                    </div>
                )}
            </div>
        )
    },
    {
        title: 'Trạng thái',
        dataIndex: 'status',
        key: 'status',
        render: (status, record) => (
            <div>
                <Tag color={
                    status === 'pending' ? 'gold' :
                    status === 'approved' ? 'green' :
                    status === 'rejected' ? 'red' : 'default'
                }>
                    {status === 'pending' ? 'Chờ duyệt' :
                     status === 'approved' ? 'Đã duyệt' :
                     status === 'rejected' ? 'Đã từ chối' : status}
                </Tag>
                {record.proof_image && (
                    <Button
                        type="link"
                        size="small"
                        icon={<EyeOutlined />}
                        onClick={() => showProofImage(record)}
                    >
                        Xem biên lai
                    </Button>
                )}
                {status === 'rejected' && record.reject_reason && (
                    <div className="text-sm text-red-500 mt-1">
                        Lý do: {record.reject_reason}
                    </div>
                )}
            </div>
        )
    },
    {
        title: 'Thời gian',
        dataIndex: 'created_at',
        render: (date) => new Date(date).toLocaleString('vi-VN')
    },
    {
        title: 'Thao tác',
        key: 'action',
        render: (_, record) => (
            <Space>
                {record.status === 'pending' && (
                    <>
                        <Button
                            type="primary"
                            size="small"
                            icon={<CheckOutlined />}
                            onClick={() => handleApprove(record.id)}
                        >
                            Duyệt
                        </Button>
                        <Button
                            danger
                            size="small"
                            icon={<CloseOutlined />}
                            onClick={() => showRejectModal(record.id)}
                        >
                            Từ chối
                        </Button>
                    </>
                )}
            </Space>
        )
    }
];

  const filterTransactions = (type) => {
    return transactions.filter(t => {
        if (type === 'deposit') {
            return t.description?.toLowerCase().includes('nạp tiền');
        }
        return t.description?.toLowerCase().includes('thanh toán');
    });
  };

  const items = [
    {
        key: 'all',
        label: 'Tất cả',
        children: <Table columns={columns} dataSource={transactions} />
    },
    {
        key: 'deposit',
        label: 'Nạp tiền',
        children: <Table columns={columns} dataSource={filterTransactions('deposit')} />
    },
    {
        key: 'payment',
        label: 'Thanh toán',
        children: <Table columns={columns} dataSource={filterTransactions('payment')} />
    }
];

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Quản lý giao dịch</h1>
      <Tabs items={items} defaultActiveKey="all" />

      <Modal
        title="Từ chối giao dịch"
        open={rejectModalVisible}
        onOk={handleReject}
        onCancel={() => {
          setRejectModalVisible(false);
          setRejectReason('');
        }}
        okText="Xác nhận"
        cancelText="Hủy"
      >
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-2">
            Lý do từ chối
          </label>
          <Input.TextArea
            value={rejectReason}
            onChange={(e) => setRejectReason(e.target.value)}
            placeholder="Nhập lý do từ chối giao dịch"
            rows={4}
          />
        </div>
      </Modal>

      <Modal
        title="Bằng chứng thanh toán"
        open={viewProofModal}
        onCancel={() => setViewProofModal(false)}
        footer={null}
        width={800}
      >
        {selectedTransaction?.proof_image && (
          <Image
            src={selectedTransaction.proof_image}
            alt="Bằng chứng thanh toán"
            style={{ width: '100%' }}
          />
        )}
      </Modal>
    </div>
  );
};

export default TransactionManagement;
