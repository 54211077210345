// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ck-editor__editable {
    min-height: 300px !important;
    max-height: 500px;
    overflow-y: auto;
}

.ck-editor__editable_inline {
    padding: 0 1rem !important;
}

.ck.ck-editor__main > .ck-editor__editable {
    background-color: #fff;
}

.ck.ck-toolbar {
    border-radius: 4px 4px 0 0;
}

.ck.ck-editor__main > .ck-editor__editable {
    border-radius: 0 0 4px 4px;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
    .ck.ck-editor__main > .ck-editor__editable {
        background-color: #1f2937;
        color: #fff;
    }
    
    .ck.ck-toolbar {
        background-color: #374151;
        border-color: #4b5563;
    }
}

/* Custom styles for editor container */
.editor-container {
    border: 1px solid #d9d9d9;
    border-radius: 4px;
}

.editor-container:focus-within {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);
}
`, "",{"version":3,"sources":["webpack://./src/styles/editor.css"],"names":[],"mappings":"AAAA;IACI,4BAA4B;IAC5B,iBAAiB;IACjB,gBAAgB;AACpB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;AAC9B;;AAEA,sBAAsB;AACtB;IACI;QACI,yBAAyB;QACzB,WAAW;IACf;;IAEA;QACI,yBAAyB;QACzB,qBAAqB;IACzB;AACJ;;AAEA,uCAAuC;AACvC;IACI,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,qBAAqB;IACrB,6CAA6C;AACjD","sourcesContent":[".ck-editor__editable {\r\n    min-height: 300px !important;\r\n    max-height: 500px;\r\n    overflow-y: auto;\r\n}\r\n\r\n.ck-editor__editable_inline {\r\n    padding: 0 1rem !important;\r\n}\r\n\r\n.ck.ck-editor__main > .ck-editor__editable {\r\n    background-color: #fff;\r\n}\r\n\r\n.ck.ck-toolbar {\r\n    border-radius: 4px 4px 0 0;\r\n}\r\n\r\n.ck.ck-editor__main > .ck-editor__editable {\r\n    border-radius: 0 0 4px 4px;\r\n}\r\n\r\n/* Dark mode support */\r\n@media (prefers-color-scheme: dark) {\r\n    .ck.ck-editor__main > .ck-editor__editable {\r\n        background-color: #1f2937;\r\n        color: #fff;\r\n    }\r\n    \r\n    .ck.ck-toolbar {\r\n        background-color: #374151;\r\n        border-color: #4b5563;\r\n    }\r\n}\r\n\r\n/* Custom styles for editor container */\r\n.editor-container {\r\n    border: 1px solid #d9d9d9;\r\n    border-radius: 4px;\r\n}\r\n\r\n.editor-container:focus-within {\r\n    border-color: #40a9ff;\r\n    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.2);\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
