import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AuthProvider } from './contexts/AuthContext';
import { FavoriteProvider } from './contexts/FavoriteContext';
import MainLayout from './components/layouts/MainLayout';
import AdminLayout from './components/admin/AdminLayout';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './components/Home';
import Login from './components/Login';
import Register from './components/Register';
import ListingDetail from './pages/ListingDetail';
import CreateListing from './components/CreateListing';
import ManageListings from './components/ManageListings';
import FavoriteListings from './components/FavoriteListings';

import AdminHome from './pages/admin/AdminHome';
import NewsManagement from './pages/admin/NewsManagement';
import NewsForm from './pages/admin/NewsForm';
import UserManagement from './pages/admin/UserManagement';

import ListingManagement from './pages/admin/ListingManagement';

// import NewsCategories from './pages/admin/NewsCategories';

// import Settings from './pages/admin/Settings';
import NewsPage from './components/NewsPage';
import NewsDetail from './components/NewsDetail';
import Profile from './pages/Profile';
import TransactionManagement from './pages/admin/TransactionManagement';
import AdminTransactions from './pages/admin/AdminTransactions';

import 'antd/dist/reset.css';
import PrivateRoute from './components/PrivateRoute';
import AdminRoute from './components/AdminRoute';
import { GoogleOAuthProvider } from '@react-oauth/google';

const App = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchType, setSearchType] = useState('');

  const handleSearchResults = (results) => {
    setSearchResults(results);
  };

  const handleSearchTypeChange = (type) => {
    setSearchType(type);
  };

  return (
    <GoogleOAuthProvider clientId="847342268116-5ipv37diet03576goldecfmsdp17ha73.apps.googleusercontent.com">
      <AuthProvider>
        <FavoriteProvider>
          <Router>
            <Routes>
              {/* Admin Routes */}
              <Route path="/admin/*" element={
                <PrivateRoute>
                  <AdminRoute>
                    <AdminLayout>
                      <Routes>
                        <Route index element={<AdminHome />} />
                        <Route path="news" element={<NewsManagement />} />
                        <Route path="news/create" element={<NewsForm />} />
                        <Route path="news/edit/:id" element={<NewsForm />} />
                        <Route path="transactions" element={<AdminTransactions />} />
                        <Route path="listings" element={<ListingManagement />} />
                        <Route path="users" element={<UserManagement />} />
                      </Routes>
                    </AdminLayout>
                  </AdminRoute>
                </PrivateRoute>
              } />

              {/* Main Routes */}
              <Route path="/*" element={
                <MainLayout 
                  onSearchResults={handleSearchResults} 
                  onSearchTypeChange={handleSearchTypeChange}
                >
                  <Routes>
                    <Route index element={<Home initialSearchType={searchType} />} />
                    <Route path="login" element={<Login />} />
                    <Route path="register" element={<Register />} />
                    <Route path="profile" element={
                      <PrivateRoute>
                        <Profile />
                      </PrivateRoute>
                    } />
                    <Route path="listing/:id" element={<ListingDetail />} />
                    <Route path="create-listing" element={
                      <PrivateRoute>
                        <CreateListing />
                      </PrivateRoute>
                    } />
                    <Route path="manage-listings" element={
                      <PrivateRoute>
                        <ManageListings />
                      </PrivateRoute>
                    } />
                    <Route path="favorites" element={
                      <PrivateRoute>
                        <FavoriteListings />
                      </PrivateRoute>
                    } />
                    
                    <Route path="news" element={<NewsPage />} />
                    <Route path="news/:id" element={<NewsDetail />} />
                    <Route path="*" element={<div>404 Not Found</div>} />
                  </Routes>
                </MainLayout>
              } />
            </Routes>
          </Router>
        </FavoriteProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
