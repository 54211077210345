import axios from 'axios';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'https://thuenhadedang.io.vn/api';

const api = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

// Interceptor cho requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Interceptor cho responses
api.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error('API Error:', error);
    return Promise.reject(handleError(error));
  }
);

// Hàm helper để log errors
const logError = (action, error) => {
  console.error(`Error in ${action}:`, error.response?.data || error.message);
};

// Wrap all API calls with error handling
const apiWrapper = (apiCall, name) => async (...args) => {
  try {
    const response = await apiCall(...args);
    console.log(`${name} API response:`, response);
    
    // Nếu response là response.data (đã được xử lý), trả về luôn
    if (response.success !== undefined) {
      return response;
    }
    
    // Nếu response là response axios gốc, lấy data
    if (response.data) {
      return response.data;
    }
    
    // Trường hợp khác, throw error
    throw new Error('Invalid response format');
  } catch (error) {
    console.error(`Error in ${name}:`, error);
    throw error;
  }
};

// Auth APIs
export const register = apiWrapper((userData) => api.post('/register', userData), 'register');
export const login = apiWrapper((credentials) => api.post('/login', credentials), 'login');
export const verifyEmail = apiWrapper((token) => api.post('/verify-email', { token }), 'verifyEmail');
export const getCurrentUser = apiWrapper(() => api.get('/user'), 'getCurrentUser');
export const resendVerification = apiWrapper(
    (email) => api.post('/resend-verification', { email }),
    'resendVerification'
);

// Listing APIs
const safelyAccessData = (response) => {
  if (response && response.data && response.data.data) {
    return response.data.data;
  }
  if (response && response.data && response.data.error) {
    throw new Error(response.data.error);
  }
  return null;
};

export const getListingDetail = apiWrapper(async (listingId) => {
  const response = await api.get(`/listings/${listingId}`);
  return response;
}, 'getListingDetail');

export const createListing = apiWrapper(async (listingData) => {
  try {
    const response = await api.post('/listings', listingData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return response;
  } catch (error) {
    throw handleError(error);
  }
}, 'createListing');
export const updateListing = apiWrapper(async (listingId, listingData) => {
  try {
    const response = await api.post(`/listings/update/${listingId}`, listingData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    return response;
  } catch (error) {
    console.error('Error updating listing:', error);
    throw error;
  }
}, 'updateListing');
export const deleteListing = apiWrapper((listingId) => api.delete(`/listings/${listingId}`), 'deleteListing');

// Review APIs
export const getReviews = async (listingId) => {
  try {
    const response = await axios.get(`${API_BASE_URL}/listings/${listingId}/reviews`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const createReview = async (listingId, reviewData) => {
  try {
    const token = localStorage.getItem('token');
    const response = await api.post(`/listings/${listingId}/reviews`, reviewData, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return response.data;
  } catch (error) {
    if (error.response?.status === 403) {
      throw new Error('Bạn chỉ có thể đánh giá những tin đăng mà bạn đã thuê');
    }
    console.error('Error creating review:', error);
    throw error;
  }
};

export const updateReview = async (reviewId, reviewData) => {
  try {
    const response = await axios.put(`${API_BASE_URL}/reviews/${reviewId}`, reviewData);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

export const deleteReview = async (reviewId) => {
  try {
    const response = await axios.delete(`${API_BASE_URL}/reviews/${reviewId}`);
    return response.data;
  } catch (error) {
    handleError(error);
  }
};

// Favorite APIs
export const getFavorites = apiWrapper(() => api.get('/favorites'), 'getFavorites');

export const addFavorite = async (listingId) => {
  try {
    const response = await api.post('/favorites', { listing_id: listingId });
    console.log('Add favorite response:', response);
    return response.data;
  } catch (error) {
    console.error('Error adding favorite:', error.response?.data || error.message);
    throw error;
  }
};

export const removeFavorite = apiWrapper(async (listingId) => {
  try {
    const response = await api.delete(`/api/favorites/${listingId}`);
    console.log('Remove favorite response:', response);
    
    if (response?.data?.success) {
      return response.data;
    }
    throw new Error(response?.data?.message || 'Không thể xóa tin yêu thích');
  } catch (error) {
    console.error('Error removing favorite:', error);
    throw error;
  }
}, 'removeFavorite');

// Message APIs
export const getConversations = apiWrapper(() => api.get('/conversations'), 'getConversations');
export const getMessages = apiWrapper((listingId, otherUserId) => 
  api.get(`/messages/${listingId}/${otherUserId}`), 'getMessages');
export const sendMessage = apiWrapper((receiverId, message, listingId) => 
  api.post('/messages', { receiver_id: receiverId, message, listing_id: listingId }), 'sendMessage');
export const markMessageAsRead = apiWrapper((messageId) => api.put(`/messages/${messageId}/read`), 'markMessageAsRead');

// Utility APIs
export const checkDbConnection = apiWrapper(() => api.get('/check-db-connection'), 'checkDbConnection');

// Thêm hàm xử lý lỗi
const handleError = (error) => {
  if (error.response) {
    if (error.response.status === 401) {
      localStorage.removeItem('token');
      window.location.href = '/login';
    } else if (error.response.status === 403) {
      throw new Error(error.response.data.message || 'Bạn không có quyền thực hiện hành động này');
    }
    throw new Error(error.response.data.message || 'Có lỗi xảy ra');
  } else if (error.request) {
    throw new Error('Không thể kết nối đến server');
  } else {
    throw new Error('Có lỗi xảy ra');
  }
};

// Transaction APIs
export const getTransactions = apiWrapper(
    () => api.get('/transactions'),
    'getTransactions'
);

export const createTransaction = apiWrapper(
    (transactionData) => api.post('/transactions', transactionData),
    'createTransaction'
);

// Admin Transaction APIs
export const getAdminTransactions = apiWrapper(
  async () => {
      try {
          const response = await api.get('/admin/transactions');
          console.log('Raw API response:', response);
          
          // Kiểm tra và xử lý response
          if (response.data && response.data.success) {
              return {
                  success: true,
                  data: response.data.data || {
                      deposits: [],
                      packages: []
                  }
              };
          }
          
          throw new Error('Invalid response format');
      } catch (error) {
          console.error('Error in getAdminTransactions:', error);
          throw error;
      }
  },
  'getAdminTransactions'
);

export const approveTransaction = apiWrapper(
    async (id) => {
        try {
            const response = await api.put(`/admin/transactions/${id}/approve`);
            return response.data;
        } catch (error) {
            console.error('Error in approveTransaction:', error);
            throw error;
        }
    },
    'approveTransaction'
);

export const rejectTransaction = apiWrapper(
    async (id, reason) => {
        try {
            const response = await api.put(`/admin/transactions/${id}/reject`, { reason });
            return response.data;
        } catch (error) {
            console.error('Error in rejectTransaction:', error);
            throw error;
        }
    },
    'rejectTransaction'
);

// Thêm constant cho kích thước file tối đa (2MB)
const MAX_FILE_SIZE = 2 * 1024 * 1024; // 2MB in bytes

export const uploadProofImage = apiWrapper(
    async (file) => {
        // Kiểm tra kích thước file
        if (file.size > MAX_FILE_SIZE) {
            throw new Error('Kích thước file không được vượt quá 2MB');
        }

        // Kiểm tra định dạng file
        const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg'];
        if (!allowedTypes.includes(file.type)) {
            throw new Error('Chỉ chấp nhận file ảnh định dạng JPG, JPEG hoặc PNG');
        }

        const formData = new FormData();
        formData.append('image', file);
        
        try {
            const response = await api.post('/transactions/upload-proof', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                // Thêm timeout để tránh upload quá lâu
                timeout: 30000 // 30 seconds
            });
            
            console.log('Upload API response:', response);
            
            if (response.data && response.data.success) {
                return {
                    success: true,
                    data: {
                        url: response.data.data.url
                    }
                };
            } else if (response.data && response.data.url) {
                return {
                    success: true,
                    data: {
                        url: response.data.url
                    }
                };
            }
            
            throw new Error(response.data?.error || 'Không thể tải lên ảnh biên lai');
        } catch (error) {
            if (error.code === 'ECONNABORTED') {
                throw new Error('Tải lên ảnh quá thời gian, vui lòng thử lại');
            }
            throw error;
        }
    },
    'uploadProofImage'
);

export const getAdminListings = apiWrapper(
    () => api.get('/admin/listings'),
    'getAdminListings'
);

export const deleteAdminListing = apiWrapper(
    (id) => api.delete(`/admin/listings/${id}`),
    'deleteAdminListing'
);

// Add new API function
export const getSimilarListings = apiWrapper(
    async ({ type, district, city, currentId, limit = 5 }) => {
        try {
            const queryParams = new URLSearchParams({
                type,
                district,
                city,
                current_id: currentId,
                limit
            }).toString();
            
            const response = await api.get(`/listings/similar?${queryParams}`);
            return response.data;
        } catch (error) {
            console.error('Error in getSimilarListings:', error);
            throw error;
        }
    },
    'getSimilarListings'
);

// User Profile APIs
export const updateUserProfile = apiWrapper(async (userData) => {
  try {
    const response = await api.post('/users/profile', userData);
    return response.data;
  } catch (error) {
    console.error('Error updating user profile:', error);
    if (error.response?.status === 401) {
      throw new Error('Vui lòng đăng nhập lại để thực hiện thao tác này');
    }
    throw error;
  }
}, 'updateUserProfile');

export const changePassword = apiWrapper(async (passwordData) => {
  try {
    const response = await api.post('/users/change-password', passwordData);
    return response.data;
  } catch (error) {
    console.error('Error changing password:', error);
    if (error.response?.status === 401) {
      throw new Error('Vui lòng đăng nhập lại để thực hiện thao tác này');
    }
    if (error.response?.data?.error) {
      throw new Error(error.response.data.error);
    }
    throw new Error('Có lỗi xảy ra khi đổi mật khẩu');
  }
}, 'changePassword');

// Thêm hàm updateListingViews
export const updateListingViews = apiWrapper(
    async (listingId) => {
        try {
            const response = await api.post(`/listings/${listingId}/views`);
            return response.data;
        } catch (error) {
            console.error('Error updating listing views:', error);
            // Không throw error vì đây không phải chức năng quan trọng
            return null;
        }
    },
    'updateListingViews'
);

export const forgotPassword = apiWrapper(
    async (email) => {
        try {
            const response = await api.post('/forgot-password', { email });
            return response.data;
        } catch (error) {
            console.error('Error in forgot password:', error);
            if (error.response?.data?.error) {
                throw new Error(error.response.data.error);
            }
            throw new Error('Có lỗi xảy ra khi gửi yêu cầu đặt lại mật khẩu');
        }
    },
    'forgotPassword'
);

export const resetPassword = apiWrapper(
    async (data) => {
        try {
            const response = await api.post('/reset-password', data);
            return response.data;
        } catch (error) {
            console.error('Error in reset password:', error);
            if (error.response?.data?.error) {
                throw new Error(error.response.data.error);
            }
            throw new Error('Có lỗi xảy ra khi đặt lại mật khẩu');
        }
    },
    'resetPassword'
);

export const googleLogin = apiWrapper(
    async (googleToken) => {
        try {
            const response = await api.post('/google-login', { googleToken });
            console.log('Google login response:', response);
            
            if (response.data && response.data.success) {
                return {
                    success: true,
                    token: response.data.token,
                    user: response.data.user
                };
            }
            
            throw new Error(response.data?.error || 'Đăng nhập thất bại');
        } catch (error) {
            console.error('Error in Google login:', error);
            if (error.response?.data?.error) {
                throw new Error(error.response.data.error);
            }
            throw new Error('Có lỗi xảy ra khi đăng nhập bằng Google');
        }
    },
    'googleLogin'
);

export const getImageUrl = (path) => {
    if (!path) return '';
    if (path.startsWith('http://')) {
        return path.replace('http://', 'https://');
    }
    return `https://thuenhadedang.io.vn${path}`;
};
export const createPassword = apiWrapper(async (passwordData) => {
  const response = await api.post('/users/create-password', passwordData);
  return response.data;
}, 'createPassword');

export default api;
