import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';

const ListingForm = ({ listing, onSubmit, onClose, isOpen }) => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    price: '',
    area: '',
    address: '',
    ward: '',
    district: '',
    city: '',
    type: '',
    thumbnail: null,
    additionalImages: []
  });
  const [addressData, setAddressData] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [wards, setWards] = useState([]);
  const [previewThumbnail, setPreviewThumbnail] = useState(null);
  const [previewAdditionalImages, setPreviewAdditionalImages] = useState([]);
  const formRef = useRef(null);

  useEffect(() => {
    fetchAddressData();
  }, []);

  useEffect(() => {
    if (listing && addressData.length > 0) {
      setFormData({
        ...listing,
        thumbnail: null,
        additionalImages: []
      });
      setPreviewThumbnail(listing.thumbnail ? `https://thuenhadedang.io.vn${listing.thumbnail}` : null);
      setPreviewAdditionalImages(listing.additionalImages ? listing.additionalImages.map(img => `https://thuenhadedang.io.vn${img}`) : []);
      
      // Set city, district, and ward
      const selectedCity = addressData.find(city => city.Name === listing.city);
      if (selectedCity) {
        setDistricts(selectedCity.Districts);
        const selectedDistrict = selectedCity.Districts.find(district => district.Name === listing.district);
        if (selectedDistrict) {
          setWards(selectedDistrict.Wards);
        }
      }
    }
  }, [listing, addressData]);

  const fetchAddressData = async () => {
    try {
      const response = await axios.get('https://raw.githubusercontent.com/kenzouno1/DiaGioiHanhChinhVN/master/data.json');
      setAddressData(response.data);
    } catch (error) {
      console.error('Error fetching address data:', error);
    }
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      if (name === 'thumbnail') {
        setFormData({ ...formData, [name]: files[0] });
        setPreviewThumbnail(URL.createObjectURL(files[0]));
      } else if (name === 'additionalImages') {
        const newImages = Array.from(files);
        setFormData({ ...formData, [name]: [...formData.additionalImages, ...newImages] });
        setPreviewAdditionalImages([...previewAdditionalImages, ...newImages.map(URL.createObjectURL)]);
      }
    } else if (name === 'price' || name === 'area') {
      setFormData({ ...formData, [name]: parseFloat(value) || '' });
    } else if (name === 'city') {
      const selectedCity = addressData.find(city => city.Name === value);
      setFormData({ ...formData, [name]: value, district: '', ward: '' });
      if (selectedCity) {
        setDistricts(selectedCity.Districts);
        setWards([]);
      }
    } else if (name === 'district') {
      const selectedDistrict = districts.find(district => district.Name === value);
      setFormData({ ...formData, [name]: value, ward: '' });
      if (selectedDistrict) {
        setWards(selectedDistrict.Wards);
      }
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleRemoveImage = (index, isAdditional = false) => {
    if (isAdditional) {
      setFormData({
        ...formData,
        additionalImages: formData.additionalImages.filter((_, i) => i !== index)
      });
      setPreviewAdditionalImages(previewAdditionalImages.filter((_, i) => i !== index));
    } else {
      setFormData({ ...formData, thumbnail: null });
      setPreviewThumbnail(null);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const data = new FormData();
    for (const key in formData) {
      if (formData[key] !== null && formData[key] !== undefined) {
        data.append(key, formData[key]);
      }
    }

    // Đảm bảo ID được thêm vào nếu đang chỉnh sửa
    if (listing && listing.id) {
      data.append('id', listing.id);
    }

    console.log("FormData being sent:", Object.fromEntries(data));

    try {
      await onSubmit(data);
    } catch (error) {
      console.error('Error submitting form:', error);
      alert("Có lỗi xảy ra khi cập nhật tin đăng. Vui lòng thử lại.");
    }
  };

  const handleOverlayClick = (e) => {
    if (formRef.current && !formRef.current.contains(e.target)) {
      onClose();
    }
  };

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full"
          onClick={handleOverlayClick}
        >
          <motion.div
            ref={formRef}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{ scale: 1, opacity: 1 }}
            exit={{ scale: 0.9, opacity: 0 }}
            transition={{ type: "spring", stiffness: 300, damping: 30 }}
            className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white"
          >
            <h2 className="text-lg font-bold mb-4">{listing ? 'Chỉnh sửa tin đăng' : 'Đăng tin mới'}</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Tiêu đề</label>
                <input
                  type="text"
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Mô tả</label>
                <textarea
                  name="description"
                  value={formData.description}
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Giá (VND/tháng)</label>
                <input
                  type="number"
                  name="price"
                  value={formData.price}
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Diện tích (m²)</label>
                <input
                  type="number"
                  name="area"
                  value={formData.area}
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Địa chỉ</label>
                <input
                  type="text"
                  name="address"
                  value={formData.address}
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                  required
                />
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Thành phố</label>
                <select
                  name="city"
                  value={formData.city}
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Chọn thành phố</option>
                  {addressData.map((city) => (
                    <option key={city.Id} value={city.Name}>
                      {city.Name}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Quận/Huyện</label>
                <select
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Chọn quận/huyện</option>
                  {districts.map((district) => (
                    <option key={district.Id} value={district.Name}>
                      {district.Name}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Phường/Xã</label>
                <select
                  name="ward"
                  value={formData.ward}
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Chọn phường/xã</option>
                  {wards.map((ward) => (
                    <option key={ward.Id} value={ward.Name}>
                      {ward.Name}
                    </option>
                  ))}
                </select>
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Loại nhà</label>
                <select
                  name="type"
                  value={formData.type}
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                  required
                >
                  <option value="">Chọn loại nhà</option>
                  <option value="Nhà nguyên căn">Nhà nguyên căn</option>
                  <option value="Căn hộ">Căn hộ</option>
                  <option value="Phòng trọ">Phòng trọ</option>
                </select>
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Ảnh đại diện</label>
                {previewThumbnail && (
                  <div className="relative inline-block">
                    <img src={previewThumbnail} alt="Thumbnail" className="w-32 h-32 object-cover rounded-md" />
                    <button
                      type="button"
                      onClick={() => handleRemoveImage(0)}
                      className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                    >
                      X
                    </button>
                  </div>
                )}
                <input
                  type="file"
                  name="thumbnail"
                  onChange={handleChange}
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                />
              </div>
              
              <div className="mb-2">
                <label className="block text-sm font-medium text-gray-700">Ảnh bổ sung</label>
                <div className="flex flex-wrap gap-2">
                  {previewAdditionalImages.map((img, index) => (
                    <div key={index} className="relative inline-block">
                      <img src={img} alt={`Additional ${index}`} className="w-24 h-24 object-cover rounded-md" />
                      <button
                        type="button"
                        onClick={() => handleRemoveImage(index, true)}
                        className="absolute top-0 right-0 bg-red-500 text-white rounded-full p-1"
                      >
                        X
                      </button>
                    </div>
                  ))}
                </div>
                <input
                  type="file"
                  name="additionalImages"
                  onChange={handleChange}
                  multiple
                  className="mt-1 w-full px-3 py-2 border rounded-md"
                />
              </div>
              
              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={onClose}
                  className="mr-2 px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                >
                  Hủy
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-500 text-white rounded-md hover:bg-blue-600"
                >
                  {listing ? 'Cập nhật' : 'Đăng tin'}
                </button>
              </div>
            </form>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default ListingForm;
