import React, { useContext, useState, useRef, useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Menu, X, LogOut, User, MessageSquare, FileText, Bookmark, ChevronDown, Home, Settings } from 'lucide-react';
import { AuthContext } from '../contexts/AuthContext';
import { CSSTransition } from 'react-transition-group';
import api from '../api';

const Header = ({ onSearchResults, onSearchTypeChange }) => {
  const { user, logout } = useContext(AuthContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isAccountMenuOpen, setIsAccountMenuOpen] = useState(false);
  const accountMenuRef = useRef(null);
  const [activeType, setActiveType] = useState('');
  const nodeRef = useRef(null);

  console.log('Current user in Header:', user);
  console.log('Is user logged in?', !!user);
  console.log('User object in Header:', JSON.stringify(user, null, 2));
  const isLandlord = user?.is_landlord || false;
  console.log("Is user a landlord?", isLandlord);

  const isAdmin = user?.is_admin || false;    // Ensure isAdmin is derived from user
  console.log("Is user an admin?", isAdmin);
  // Add "Tin tức" button to the navigation
  const handleNewsClick = () => {
    setActiveType('');  // Reset activeType khi chuyển sang trang tin tức
    navigate('/news');
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (accountMenuRef.current && !accountMenuRef.current.contains(event.target)) {
        setIsAccountMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const handlePostRental = () => {
    navigate('/create-listing');
  };

  const isActive = (path) => {
    if (path === '/add-rental') {
      return location.pathname.startsWith('/add-rental');
    }
    if (path === '/news') {
      return location.pathname === '/news';
    }
    return location.pathname === path;
  };

  const handleHomeClick = async () => {
    setIsMenuOpen(false);
    setActiveType('');
    onSearchResults([]);
    onSearchTypeChange('');

    try {
      const response = await api.get('/listings/latest');
      onSearchResults(response.data);
      navigate('/');
      window.location.reload();
    } catch (error) {
      console.error('Error fetching latest listings:', error);
      onSearchResults([]);
    }
  };

  const linkStyle = (type) => {
    // Kiểm tra nếu đang ở trang tin tức
    if (location.pathname === '/news') {
      return `text-base font-medium ${
        type === 'Tin tức'
          ? 'text-white bg-primary-600'
          : 'text-secondary-600 hover:text-secondary-900 hover:bg-secondary-100'
      } px-3 py-2 rounded-md transition duration-150 ease-in-out`;
    }

    // Xử lý các trường hợp khác
    return `text-base font-medium ${
      activeType === type && location.pathname === '/'
        ? 'text-white bg-primary-600'
        : 'text-secondary-600 hover:text-secondary-900 hover:bg-secondary-100'
    } px-3 py-2 rounded-md transition duration-150 ease-in-out`;
  };

  const toggleAccountMenu = () => {
    setIsAccountMenuOpen(!isAccountMenuOpen);
  };

  // Update menu items to include admin management for admins
  const menuItems = useMemo(() => {
    const isAdmin = Boolean(user?.is_admin);
    
    const items = [
      // Chỉ cho phép landlord truy cập vào trang quản lý tin đăng
      { icon: FileText, text: 'Quản lý tin đăng', link: '/manage-listings' },
      { icon: User, text: 'Thông tin cá nhân', link: '/profile' },
      { icon: Bookmark, text: 'Tin yêu thích', link: '/favorites' }
    ];
    
    if (isAdmin) {
      items.push({
        icon: Settings,
        text: 'Quản lý Admin',
        link: '/admin',
        iconColor: 'text-red-500'
      });
    }
    
    items.push({ icon: LogOut, text: 'Đăng xuất', onClick: handleLogout });
    
    return items;
  }, [user, isLandlord]);

  const handleTypeClick = async (type) => {
    setIsMenuOpen(false);
    setActiveType(type);
    
    try {
      const response = await api.get('/listings/search', { params: { type } });
      onSearchResults(response.data);
      onSearchTypeChange(type);
      navigate('/', { state: { searchType: type } });
    } catch (error) {
      console.error('Error searching listings:', error);
      onSearchResults([]);
    }
  };

  return (
    <>
      {/* Overlay for mobile menu */}
      {isMenuOpen && (
        <div 
          className="fixed inset-0 bg-black bg-opacity-50 z-20"
          onClick={() => setIsMenuOpen(false)}
        />
      )}

      <header className="bg-white shadow-md fixed w-full z-30">
        <div className="container mx-auto px-4">
          <div className="flex justify-between items-center py-4">
            {/* Logo */}
            <div className="flex-shrink-0">
              <Link to="/" className="text-xl md:text-2xl font-bold text-indigo-600">
                ThueNhaDeDang
              </Link>
            </div>

            {/* Mobile menu button */}
            <div className="lg:hidden">
              <button
                type="button"
                className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                onClick={toggleMenu}
              >
                <span className="sr-only">Open menu</span>
                {isMenuOpen ? (
                  <X className="h-6 w-6" aria-hidden="true" />
                ) : (
                  <Menu className="h-6 w-6" aria-hidden="true" />
                )}
              </button>
            </div>

            {/* Desktop Navigation - Thay đổi breakpoint từ md sang lg */}
            <nav className="hidden lg:flex items-center space-x-1 xl:space-x-4 flex-wrap">
              <button onClick={handleHomeClick} className={`${linkStyle('')} whitespace-nowrap`}>
                Trang chủ
              </button>
              <button onClick={handleNewsClick} className={`${linkStyle('Tin tức')} whitespace-nowrap`}>
                Tin tức
              </button>
              <button onClick={() => handleTypeClick('Phòng trọ')} className={`${linkStyle('Phòng trọ')} whitespace-nowrap`}>
                Phòng trọ
              </button>
              <button onClick={() => handleTypeClick('Nhà nguyên căn')} className={`${linkStyle('Nhà nguyên căn')} whitespace-nowrap`}>
                Nhà nguyên căn
              </button>
              <button onClick={() => handleTypeClick('Căn hộ')} className={`${linkStyle('Căn hộ')} whitespace-nowrap`}>
                Căn hộ
              </button>
              <button onClick={() => handleTypeClick('Mặt bằng')} className={`${linkStyle('Mặt bằng')} whitespace-nowrap`}>
                Mặt bằng
              </button>
              <button onClick={() => handleTypeClick('Ở ghép')} className={`${linkStyle('Ở ghép')} whitespace-nowrap`}>
                Ở ghép
              </button>
              
              <Link
                to="/create-listing"
                className="whitespace-nowrap text-sm xl:text-base font-medium text-white bg-red-600 hover:bg-red-700 px-3 xl:px-4 py-2 rounded-md transition duration-150 ease-in-out ml-4"
                onClick={(e) => {
                  e.preventDefault();
                  handlePostRental();
                }}
              >
                Đăng tin
              </Link>
            </nav>

            {/* Desktop Post Rental & Account Menu */}
            <div className="hidden lg:flex items-center space-x-4">
              {user ? (
                <div className="relative" ref={accountMenuRef}>
                  <button
                    onClick={toggleAccountMenu}
                    className="flex items-center text-sm xl:text-base font-medium text-secondary-600 hover:text-secondary-900 bg-secondary-100 hover:bg-secondary-200 px-3 xl:px-4 py-2 rounded-full transition-all duration-300 ease-in-out"
                  >
                    <User className="h-5 w-5 mr-2 text-primary-500" />
                    <span className="mr-1 hidden xl:inline">{user.name || user.username}</span>
                    <ChevronDown className={`h-4 w-4 text-secondary-400 transition-transform duration-300 ${isAccountMenuOpen ? 'transform rotate-180' : ''}`} />
                  </button>
                  {/* Account dropdown menu */}
                  <CSSTransition
                    nodeRef={nodeRef}
                    in={isAccountMenuOpen}
                    timeout={300}
                    classNames="menu"
                    unmountOnExit
                  >
                    <div ref={nodeRef} className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                      <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                        {menuItems.map((item, index) => (
                          item.onClick ? (
                            <button
                              key={index}
                              onClick={item.onClick}
                              className="flex items-center w-full text-left px-4 py-2 text-sm text-secondary-700 hover:bg-gray-100 transition-all duration-200 ease-in-out transform hover:scale-105"
                              role="menuitem"
                            >
                              <item.icon className={`h-5 w-5 mr-3 ${item.iconColor}`} />
                              <span>{item.text}</span>
                            </button>
                          ) : (
                            <Link
                              key={index}
                              to={item.link}
                              className="flex items-center px-4 py-2 text-sm text-secondary-700 hover:bg-gray-100 transition-all duration-200 ease-in-out transform hover:scale-105"
                              role="menuitem"
                            >
                              <item.icon className={`h-5 w-5 mr-3 ${item.iconColor}`} />
                              <span>{item.text}</span>
                            </Link>
                          )
                        ))}
                      </div>
                    </div>
                  </CSSTransition>
                </div>
              ) : (
                <div className="flex items-center space-x-4">
                  <Link to="/login" className="whitespace-nowrap text-sm xl:text-base font-medium text-secondary-600 hover:text-secondary-900">
                    Đăng nhập
                  </Link>
                  <Link
                    to="/register"
                    className="whitespace-nowrap text-sm xl:text-base font-medium text-white bg-primary-600 hover:bg-primary-700 px-3 xl:px-4 py-2 rounded-md"
                  >
                    Đăng ký
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Mobile Navigation Menu */}
        <div
          className={`${
            isMenuOpen ? 'translate-x-0' : '-translate-x-full'
          } fixed top-0 left-0 bottom-0 w-64 bg-white shadow-xl z-40 transform transition-transform duration-300 ease-in-out lg:hidden overflow-y-auto`}
        >
          <div className="pt-5 pb-6 px-5">
            <div className="flex items-center justify-between mb-6">
              <div>
                <Link 
                  to="/" 
                  className="text-2xl font-bold text-indigo-600"
                  onClick={() => {
                    setIsMenuOpen(false);
                    handleHomeClick();
                  }}
                >
                  ThueNhaDeDang
                </Link>
              </div>
              <button
                type="button"
                className="rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100"
                onClick={() => setIsMenuOpen(false)}
              >
                <X className="h-6 w-6" />
              </button>
            </div>
            
            <div className="flex flex-col space-y-4">
              {/* Mobile Navigation Items */}
              <button 
                onClick={handleHomeClick}
                className="flex items-center text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                <Home className="h-5 w-5 mr-2" />
                Trang chủ
              </button>
              <button 
                onClick={() => {
                  setIsMenuOpen(false);
                  handleNewsClick();
                }} 
                className="flex items-center text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                <FileText className="h-5 w-5 mr-2" />
                Tin tức
              </button>
              {/* Thêm các nút loại nhà trọ */}
              <button 
                onClick={() => {
                  setIsMenuOpen(false);
                  handleTypeClick('Phòng trọ');
                }}
                className="flex items-center text-left px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
              >
                Phòng trọ
              </button>
              {/* ... thêm các nút loại khác tương tự ... */}
            </div>
          </div>

          {/* Mobile Account Section */}
          <div className="border-t border-gray-200 py-6 px-5">
            {user ? (
              <div className="space-y-4">
                <div className="flex items-center">
                  <User className="h-5 w-5 text-gray-400 mr-2" />
                  <span className="text-base font-medium text-gray-900">
                    {user.name || user.username}
                  </span>
                </div>
                {menuItems.map((item, index) => (
                  item.onClick ? (
                    <button
                      key={index}
                      onClick={() => {
                        setIsMenuOpen(false);
                        item.onClick();
                      }}
                      className="flex items-center w-full px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 rounded-md"
                    >
                      <item.icon className="h-5 w-5 mr-3" />
                      {item.text}
                    </button>
                  ) : (
                    <Link
                      key={index}
                      to={item.link}
                      onClick={() => setIsMenuOpen(false)}
                      className="flex items-center px-3 py-2 text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 rounded-md"
                    >
                      <item.icon className="h-5 w-5 mr-3" />
                      {item.text}
                    </Link>
                  )
                ))}
              </div>
            ) : (
              <div className="space-y-4">
                <Link
                  to="/login"
                  onClick={() => setIsMenuOpen(false)}
                  className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  Đăng nhập
                </Link>
                <Link
                  to="/register"
                  onClick={() => setIsMenuOpen(false)}
                  className="w-full flex items-center justify-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-base font-medium text-gray-700 bg-white hover:bg-gray-50"
                >
                  Đăng ký
                </Link>
              </div>
            )}
          </div>
        </div>
      </header>

      {/* Spacer to prevent content from hiding under fixed header */}
      <div className="h-16"></div>
    </>
  );
};

export default Header;
