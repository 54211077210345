import React, { useState, useContext, useEffect } from 'react';
import { AuthContext } from '../contexts/AuthContext';
import { Form, Input, Button, message, Tabs, Modal, Select, Table, InputNumber, Tag, Tooltip, Alert, Upload } from 'antd';
import api, { createTransaction, getTransactions, uploadProofImage, updateUserProfile, changePassword } from '../api';
import { PlusOutlined, CopyOutlined, InfoCircleOutlined, UploadOutlined, DownloadOutlined } from '@ant-design/icons';
import { useNavigate, Link } from 'react-router-dom';

const { TabPane } = Tabs;

const BANK_INFO = {
  account_name: "TONG DUC DUY",
  account_number: "1970031140012",
  bank_name: "Techcombank",
  branch: "Hà Nội",
  qr_code: "https://thuenhadedang.io.vn/uploads/qrcode.jpg"
};

const Profile = () => {
  const { user, setUser } = useContext(AuthContext);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [userInfo, setUserInfo] = useState(null);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [depositAmount, setDepositAmount] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState('bank_transfer');
  const [transactions, setTransactions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [passwordForm] = Form.useForm();
  const [changingPassword, setChangingPassword] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const navigate = useNavigate();

  useEffect(() => {
    if (user) {
      fetchUserInfo();
      fetchTransactions();
    }
  }, [user]);

  useEffect(() => {
    if (userInfo) {
      form.setFieldsValue({
        full_name: userInfo.full_name,
        email: userInfo.email,
        phone_number: userInfo.phone_number
      });
    }
  }, [userInfo]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const tab = params.get('tab');
    if (tab) {
      setActiveTab(tab);
    }
  }, []);

  const fetchUserInfo = async () => {
    try {
      const response = await api.get('/user');
      setUserInfo(response.data.data);
    } catch (error) {
      message.error('Không thể lấy thông tin người dùng');
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await getTransactions();
      console.log('Transaction response:', response); // Thêm log để debug
      
      if (response.success) {
        setTransactions(response.data);
      } else {
        message.error(response.error || 'Không thể tải lịch sử giao dịch');
      }
    } catch (error) {
      console.error('Error fetching transactions:', error);
      message.error('Có lỗi xảy ra khi tải lịch sử giao dịch');
    }
  };

  // Thêm useEffect để tải giao dịch khi component mount
  useEffect(() => {
    fetchTransactions();
  }, []);

  const onFinish = async (values) => {
    try {
      setLoading(true);
      const response = await updateUserProfile(values);
      
      if (response.success) {
        message.success('Cập nhật thông tin thành công');
        // Cập nhật userInfo local
        setUserInfo(response.data);
        // Cập nhật context nếu có
        if (user && setUser) {
          setUser(prevUser => ({
            ...prevUser,
            ...response.data
          }));
        }
      }
    } catch (error) {
      message.error(error.message || 'Có lỗi xảy ra khi cập nhật thông tin');
    } finally {
      setLoading(false);
    }
  };

  const handleUploadChange = ({ fileList: newFileList }) => {
    const limitedFileList = newFileList.slice(-1);
    
    const validFileList = limitedFileList.filter(file => {
        if (file.size > 2 * 1024 * 1024) {
            message.error('File không được vượt quá 2MB');
            return false;
        }
        
        // Thêm preview cho file
        if (!file.url && !file.preview) {
            file.preview = URL.createObjectURL(file.originFileObj);
        }
        
        return true;
    });

    setFileList(validFileList);
  };

  const handleDeposit = async () => {
    try {
      // Validate form trước khi submit
      const formValues = await form.validateFields(['amount', 'proof_image']);
      
      // Kiểm tra file ảnh biên lai
      if (!fileList.length || !fileList[0].originFileObj) {
        message.error('Vui lòng tải lên biên lai chuyển khoản');
        return;
      }

      // Tạo mã giao dịch
      const transferContent = `NT${formValues.amount}_${Math.random().toString(36).substr(2, 6)}`;

      // Upload ảnh biên lai
      let proof_image = null;
      try {
        const uploadResponse = await uploadProofImage(fileList[0].originFileObj);
        if (uploadResponse.success && uploadResponse.data && uploadResponse.data.url) {
          proof_image = uploadResponse.data.url;
        } else {
          throw new Error('Không thể tải lên ảnh biên lai');
        }
      } catch (uploadError) {
        console.error('Upload error:', uploadError);
        message.error('Không thể tải lên ảnh biên lai');
        return;
      }

      // Tạo dữ liệu giao dịch
      const transactionData = {
        type: 'deposit',
        amount: Number(formValues.amount), // Đảm bảo amount là số
        payment_method: 'bank_transfer',
        transfer_content: transferContent,
        description: 'Nạp tiền vào tài khoản',
        proof_image: proof_image
      };

      // Gửi request tạo giao dịch
      const response = await createTransaction(transactionData);
      
      if (response && response.success) {
        message.success('Tạo giao dịch thành công');
        setShowDepositModal(false);
        form.resetFields();
        setFileList([]);
        await fetchTransactions(); // Refresh danh sách giao dịch
      } else {
        throw new Error(response?.error || 'Có lỗi xảy ra khi tạo giao dịch');
      }
    } catch (error) {
      console.error('Error in handleDeposit:', error);
      message.error(error.message || 'Có lỗi xảy ra khi tạo giao dịch');
    }
  };

  // Upload component configuration
  const uploadProps = {
    beforeUpload: (file) => {
      const isImage = file.type.startsWith('image/');
      if (!isImage) {
        message.error('Chỉ chấp nhận file ảnh!');
        return Upload.LIST_IGNORE;
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        message.error('Ảnh phải nhỏ hơn 2MB!');
        return Upload.LIST_IGNORE;
      }
      return false; // Prevent auto upload
    },
    onChange: ({ fileList: newFileList }) => {
      setFileList(newFileList);
    },
    fileList,
    maxCount: 1
  };
  // Cập nhật columns để hiển thị đúng dữ liệu
  const transactionColumns = [
    {
      title: 'Thời gian',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (date) => new Date(date).toLocaleString('vi-VN')
    },
    {
      title: 'Loại giao dịch',
      dataIndex: 'description',
      key: 'type',
      render: (description) => {
        const isDeposit = description?.toLowerCase().includes('nạp tiền');
        return (
          <Tag color={isDeposit ? 'green' : 'red'}>
            {isDeposit ? 'Nạp tiền' : 'Thanh toán'}
          </Tag>
        );
      }
    },
    {
      title: 'Số tiền',
      dataIndex: 'amount',
      key: 'amount',
      render: (amount, record) => {
        const isDeposit = record.description?.toLowerCase().includes('nạp tiền');
        return (
          <Tooltip title={isDeposit ? 'Nạp tiền vào tài khoản' : 'Thanh toán dịch vụ'}>
            <span className={`font-medium ${isDeposit ? 'text-green-600' : 'text-red-600'}`}>
              {isDeposit ? '+' : '-'}{Math.abs(Number(amount)).toLocaleString()}đ
            </span>
          </Tooltip>
        );
      }
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      render: (status, record) => (
        <div>
          <Tag color={
            status === 'pending' ? 'gold' :
            status === 'approved' ? 'green' :
            status === 'rejected' ? 'red' : 'default'
          }>
            {status === 'pending' ? 'Chờ duyệt' :
             status === 'approved' ? 'Đã duyệt' :
             status === 'rejected' ? 'Đã từ chối' : status}
          </Tag>
          {status === 'rejected' && record.reject_reason && (
            <div className="text-sm text-red-500 mt-1">
              Lý do: {record.reject_reason}
            </div>
          )}
        </div>
      )
    },
    {
      title: 'Nội dung',
      dataIndex: 'description',
      key: 'description',
      render: (description, record) => (
        <div>
          <div>{description}</div>
          {record.transfer_content && (
            <div className="text-sm text-gray-500">
              Mã GD: {record.transfer_content}
            </div>
          )}
        </div>
      )
    }
  ];

  // Cleanup preview URLs khi component unmount
  useEffect(() => {
    return () => {
      fileList.forEach(file => {
        if (file.preview) {
          URL.revokeObjectURL(file.preview);
        }
      });
    };
  }, [fileList]);

  const handlePasswordChange = async (values) => {
    try {
      setChangingPassword(true);
      
      // Log để debug
      console.log('Password values:', values);

      const response = await changePassword({
        current_password: values.currentPassword,
        new_password: values.newPassword,
        confirm_password: values.confirmPassword  // Thêm confirm password nếu API cần
      });
      
      // Log response để debug
      console.log('Change password response:', response);

      if (response.success) {
        message.success('Đổi mật khẩu thành công');
        passwordForm.resetFields();
        setChangingPassword(false);
      } else {
        // Xử lý các trường hợp lỗi cụ thể
        if (response.error === 'Mật khẩu hiện tại không đúng') {
          passwordForm.setFields([
            {
              name: 'currentPassword',
              errors: ['Mật khẩu hiện tại không đúng']
            }
          ]);
        } else {
          message.error(response.error || 'Có lỗi xảy ra khi đổi mật khẩu');
        }
      }
    } catch (error) {
      console.error('Error in handlePasswordChange:', error);
      message.error(error.message || 'Có lỗi xảy ra khi đổi mật khẩu');
    } finally {
      setChangingPassword(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-3 lg:p-6 pt-16 lg:pt-20">
      <Tabs 
        activeKey={activeTab} 
        onChange={(key) => {
          setActiveTab(key);
          // Cập nhật URL khi chuyển tab
          const url = new URL(window.location);
          url.searchParams.set('tab', key);
          window.history.pushState({}, '', url);
        }}
      >
        <TabPane tab="Thông tin cá nhân" key="1">
          <div className="mb-4 lg:mb-6 p-3 lg:p-4 bg-white rounded-lg shadow">
            <h2 className="text-lg lg:text-xl font-semibold mb-4">Thông tin tài khoản</h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-3 lg:gap-4">
              <div className="space-y-1">
                <p className="text-sm text-gray-600">Tên đăng nhập</p>
                <p className="font-medium">{userInfo?.username}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-600">Họ và tên</p>
                <p className="font-medium">{userInfo?.full_name}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-600">Email</p>
                <p className="font-medium">{userInfo?.email}</p>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-600">Số điện thoại</p>
                <p className="font-medium">{userInfo?.phone_number || 'Chưa cập nhật'}</p>
              </div>
            </div>
          </div>

          <div className="bg-white rounded-lg shadow p-3 lg:p-4">
            <h3 className="text-lg font-medium mb-4">Cập nhật thông tin</h3>
            <Form
              form={form}
              onFinish={onFinish}
              layout="vertical"
              initialValues={{
                full_name: userInfo?.full_name || '',
                phone_number: userInfo?.phone_number || ''
              }}
            >
              <Form.Item
                name="full_name"
                label="Họ và tên"
                rules={[{ required: true, message: 'Vui lòng nhập họ tên' }]}
              >
                <Input className="w-full" />
              </Form.Item>

              <Form.Item
                name="phone_number"
                label="Số điện thoại"
                rules={[
                  { required: true, message: 'Vui lòng nhập số điện thoại' },
                  {
                    validator: async (_, value) => {
                      if (!value) return Promise.resolve();

                      // Loại bỏ khoảng trắng
                      value = value.trim();

                      // Kiểm tra định dạng cơ bản
                      const phoneRegex = /^((0[3|5|7|8|9])|(\+84[3|5|7|8|9]))\d{8}$/;
                      if (!phoneRegex.test(value)) {
                        return Promise.reject('Số điện thoại không hợp lệ');
                      }

                      // Kiểm tra độ dài
                      if (value.startsWith('+84')) {
                        if (value.length !== 12) { // +84 + 9 số
                          return Promise.reject('Số điện thoại với đầu +84 phải có 12 ký tự');
                        }
                      } else {
                        if (value.length !== 10) { // 0 + 9 số
                          return Promise.reject('Số điện thoại phải có 10 số');
                        }
                      }

                      // Kiểm tra đầu số
                      const validPrefixes = ['03', '05', '07', '08', '09'];
                      const prefix = value.startsWith('+84') ? value.substring(3, 5) : value.substring(0, 2);
                      
                      if (!validPrefixes.includes(prefix)) {
                        return Promise.reject('Đầu số điện thoại không hợp lệ');
                      }

                      return Promise.resolve();
                    }
                  }
                ]}
                validateTrigger={['onChange', 'onBlur']}
              >
                <Input
                  className="w-full"
                  placeholder="Ví dụ: 0912345678 hoặc +84912345678"
                  maxLength={12}
                  showCount
                  onChange={(e) => {
                    // Chỉ cho phép nhập số và dấu +
                    const value = e.target.value.replace(/[^\d+]/g, '');
                    // Nếu có dấu +, chỉ cho phép ở đầu
                    if (value.includes('+') && !value.startsWith('+')) {
                      e.target.value = value.replace(/\+/g, '');
                    } else {
                      e.target.value = value;
                    }
                  }}
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Cập nhật
                </Button>
              </Form.Item>
            </Form>
          </div>
          

          <div className="bg-white rounded-lg shadow p-3 lg:p-4 mt-4 lg:mt-6">
            <h3 className="text-lg font-medium mb-4">Đổi mật khẩu</h3>
            <Form
              form={passwordForm}
              onFinish={handlePasswordChange}
              layout="vertical"
              validateTrigger="onBlur"
            >
              <Form.Item
                name="currentPassword"
                label="Mật khẩu hiện tại"
                rules={[
                  { 
                    required: true, 
                    message: 'Vui lòng nhập mật khẩu hiện tại',
                    validateTrigger: 'onBlur'
                  }
                ]}
                validateFirst={true}
              >
                <Input.Password className="w-full" />
              </Form.Item>

              <Form.Item
                name="newPassword"
                label="Mật khẩu mới"
                rules={[
                  { required: true, message: 'Vui lòng nhập mật khẩu mới' },
                  { min: 6, message: 'Mật khẩu phải có ít nhất 6 ký tự' }
                ]}
                validateFirst={true}
              >
                <Input.Password className="w-full" />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Xác nhận mật khẩu mới"
                dependencies={['newPassword']}
                rules={[
                  { required: true, message: 'Vui lòng xác nhận mật khẩu mới' },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('newPassword') === value) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error('Mật khẩu xác nhận không khớp'));
                    },
                  }),
                ]}
                validateFirst={true}
              >
                <Input.Password className="w-full" />
              </Form.Item>

              <Form.Item>
                <Button 
                  type="primary" 
                  htmlType="submit" 
                  loading={changingPassword}
                  disabled={changingPassword}
                >
                  Đổi mật khẩu
                </Button>
              </Form.Item>
            </Form>
          </div>
        </TabPane>

        <TabPane tab="Nạp tiền" key="2">
          <div className="mb-4">
            <h3 className="text-lg font-medium mb-2">Số dư hiện tại</h3>
            <p className="text-xl lg:text-2xl font-bold text-green-600">
              {userInfo?.balance?.toLocaleString()}đ
            </p>
          </div>

          <Button 
            type="primary"
            size="large"
            icon={<i className="fas fa-plus-circle mr-2"></i>}
            onClick={() => setShowDepositModal(true)}
            className="mb-6"
          >
            Nạp tiền
          </Button>

          <h3 className="text-lg font-medium mb-4">Lịch sử giao dịch</h3>
          <div className="overflow-x-auto">
            <Table 
              columns={transactionColumns} 
              dataSource={transactions}
              rowKey="id"
              pagination={{ pageSize: 10 }}
              scroll={{ x: true }}
              className="min-w-full"
            />
          </div>
        </TabPane>
      </Tabs>

      <Modal
        title={<h2 className="text-xl font-semibold">Nạp tiền vào tài khoản</h2>}
        open={showDepositModal}
        onOk={handleDeposit}
        onCancel={() => {
          setShowDepositModal(false);
          form.resetFields();
          setFileList([]);
        }}
        width={800}
        okText="Xác nhận đã chuyển tiền"
        cancelText="Đóng"
        className="max-w-[95vw]"
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={{
            amount: 100000
          }}
        >
          {/* Bước 1: Nhập số tiền */}
          <div className="bg-white p-3 lg:p-4 rounded-lg border border-gray-200 mb-4">
            <h3 className="text-base font-medium mb-3 flex items-center">
              <span className="bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">1</span>
              Nhập số tiền cần nạp
            </h3>
            <Form.Item
              name="amount"
              rules={[
                { required: true, message: 'Vui lòng nhập số tiền' },
                { type: 'number', min: 10000, message: 'Số tiền tối thiểu là 10,000đ' }
              ]}
            >
              <InputNumber
                className="w-full"
                formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={value => value.replace(/\$\s?|(,*)/g, '')}
                min={10000}
                step={10000}
                onChange={value => setDepositAmount(value)}
                size="large"
                placeholder="Nhập số tiền cần nạp"
              />
            </Form.Item>
          </div>

          {/* Bước 2: Thông tin chuyển khoản */}
          <div className="bg-white p-3 lg:p-4 rounded-lg border border-gray-200 mb-4">
            <h3 className="text-base font-medium mb-3 flex items-center">
              <span className="bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">2</span>
              Chuyển khoản theo thông tin sau
            </h3>
            
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              {/* Thông tin ngân hàng */}
              <div className="bg-gray-50 p-3 lg:p-4 rounded-lg">
                <div className="flex items-center space-x-3 mb-4">
                  <img 
                    src="https://thuenhadedang.io.vn/uploads/tech.jpg" 
                    alt="Bank Logo" 
                    className="w-8 h-8"
                  />
                  <span className="text-lg font-semibold text-gray-800">{BANK_INFO.bank_name}</span>
                </div>
                
                <div className="space-y-3">
                  <div>
                    <p className="text-gray-500 text-sm">Số tài khoản</p>
                    <div className="flex items-center space-x-2">
                      <span className="text-2xl font-bold text-gray-800 font-mono">
                        {BANK_INFO.account_number}
                      </span>
                      <Button
                        type="default"
                        icon={<CopyOutlined />}
                        onClick={() => {
                          navigator.clipboard.writeText(BANK_INFO.account_number);
                          message.success('Đã sao chép số tài khoản');
                        }}
                      >
                        Sao chép
                      </Button>
                    </div>
                  </div>
                  
                  <div>
                    <p className="text-gray-500 text-sm">Chủ tài khoản</p>
                    <p className="text-xl font-semibold text-gray-800">{BANK_INFO.account_name}</p>
                  </div>
                  
                  <div>
                    <p className="text-gray-500 text-sm">Chi nhánh</p>
                    <p className="text-base text-gray-700">{BANK_INFO.branch}</p>
                  </div>

                  {depositAmount > 0 && (
                    <div className="mt-4 pt-4 border-t border-gray-200">
                      <div className="mb-3">
                        <p className="text-gray-500 text-sm">Số tiền cần chuyển</p>
                        <p className="text-2xl font-bold text-blue-600">
                          {depositAmount?.toLocaleString()}đ
                        </p>
                      </div>
                      
                      <div>
                        <p className="text-gray-500 text-sm">Nội dung chuyển khoản</p>
                        <div className="flex items-center space-x-2 mt-1">
                          <code className="bg-white px-3 py-2 rounded text-blue-600 font-mono text-base">
                            NT{depositAmount}_{Math.random().toString(36).substr(2, 6)}
                          </code>
                          <Button
                            type="default"
                            icon={<CopyOutlined />}
                            onClick={() => {
                              const content = `NT${depositAmount}_${Math.random().toString(36).substr(2, 6)}`;
                              navigator.clipboard.writeText(content);
                              message.success('Đã sao chép nội dung chuyển khoản');
                            }}
                          >
                            Sao chép
                          </Button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {/* QR Code */}
              <div className="bg-gray-50 p-3 lg:p-4 rounded-lg flex flex-col items-center justify-center">
                <p className="font-medium text-gray-800 mb-3">Quét mã QR để chuyển khoản</p>
                <div className="relative group">
                  <img 
                    src={BANK_INFO.qr_code} 
                    alt="QR Payment" 
                    className="w-48 h-48 rounded-lg border-2 border-gray-200 bg-white p-2"
                  />
                  <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-30 transition-all duration-300 rounded-lg flex items-center justify-center">
                    <Button 
                      type="primary"
                      icon={<DownloadOutlined />}
                      className="opacity-0 group-hover:opacity-100 transition-all duration-300"
                      onClick={() => {
                        const link = document.createElement('a');
                        link.href = BANK_INFO.qr_code;
                        link.download = 'payment-qr.png';
                        link.click();
                      }}
                    >
                      Tải mã QR
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* Bước 3: Upload biên lai */}
          <div className="bg-white p-3 lg:p-4 rounded-lg border border-gray-200 mb-4">
            <h3 className="text-base font-medium mb-3 flex items-center">
              <span className="bg-blue-500 text-white rounded-full w-6 h-6 flex items-center justify-center mr-2">3</span>
              Tải lên biên lai chuyển khoản
            </h3>
            
            <Form.Item
              name="proof_image"
              rules={[{ required: true, message: 'Vui lòng tải lên biên lai chuyển khoản' }]}
              valuePropName="fileList"
              getValueFromEvent={(e) => {
                if (Array.isArray(e)) {
                  return e;
                }
                return e?.fileList;
              }}
            >
              <Upload
                fileList={fileList}
                onChange={handleUploadChange}
                beforeUpload={(file) => {
                  const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                  if (!isJpgOrPng) {
                    message.error('Chỉ hỗ trợ file JPG/PNG!');
                    return Upload.LIST_IGNORE;
                  }
                  const isLt2M = file.size / 1024 / 1024 < 2;
                  if (!isLt2M) {
                    message.error('Ảnh phải nhỏ hơn 2MB!');
                    return Upload.LIST_IGNORE;
                  }
                  return false;
                }}
                accept=".jpg,.jpeg,.png"
                maxCount={1}
              >
                <Button icon={<UploadOutlined />} size="large">Tải lên biên lai</Button>
              </Upload>
            </Form.Item>
            <p className="text-gray-500 text-sm">Hỗ trợ: JPG, PNG. Tối đa 2MB</p>
          </div>

          {/* Lưu ý */}
          <Alert
            message="Lưu ý quan trọng"
            description={
              <ul className="list-disc pl-4 text-sm">
                <li>Vui lòng chuyển khoản đúng số tiền và nội dung để được xử lý nhanh chóng</li>
                <li>Thời gian xử lý từ 5-15 phút trong giờ hành chính</li>
                <li>Nếu cần hỗ trợ, vui lòng liên hệ Hotline: 0906.123.456</li>
              </ul>
            }
            type="warning"
            showIcon
            className="text-sm"
          />
        </Form>
      </Modal>
    </div>
  );
};

export default Profile;
