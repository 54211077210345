import React, { useState, useEffect } from 'react';
import { Table, Tag, Space, Button, message, Modal, Image, Tooltip } from 'antd';
import { CheckOutlined, CloseOutlined, EyeOutlined, StarFilled, PhoneOutlined, DeleteOutlined } from '@ant-design/icons';
import api from '../../api';
import moment from 'moment';

const ListingManagement = () => {
    const [listings, setListings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedListing, setSelectedListing] = useState(null);
    const [previewVisible, setPreviewVisible] = useState(false);
    const [deleteModalVisible, setDeleteModalVisible] = useState(false);
    const [deletingListing, setDeletingListing] = useState(null);

    useEffect(() => {
        fetchListings();
    }, []);

    const fetchListings = async () => {
        setLoading(true);
        try {
            const response = await api.get('/admin/listings');
            if (response.data && response.data.success) {
                setListings(response.data.data.map(item => ({
                    ...item,
                    key: item.id,
                    user: {
                        full_name: item.full_name,
                        email: item.email,
                        phone_number: item.phone_number
                    },
                    images: item.images.map(url => ({ image_url: url }))
                })));
            } else {
                throw new Error(response.data?.error || 'Không thể tải danh sách tin đăng');
            }
        } catch (error) {
            console.error('Error fetching listings:', error);
            if (error.response && error.response.status === 403) {
                message.error('Bạn không có quyền truy cập vào danh sách này.');
            } else {
                message.error(error.message || 'Lỗi khi tải danh sách tin đăng');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleStatusChange = async (id, status) => {
        try {
            if (status === 'active') {
                await api.put(`/admin/listings/${id}/approve`);
                message.success('Đã duyệt tin đăng');
            } else {
                await api.put(`/admin/listings/${id}/reject`);
                message.success('Đã từ chối tin đăng');
            }
            fetchListings();
        } catch (error) {
            console.error('Error changing status:', error?.response?.data || error);
            message.error('Có lỗi xảy ra');
        }
    };

    const showPreview = (record) => {
        setSelectedListing(record);
        setPreviewVisible(true);
    };

    const getStatusTag = (status) => {
        const statusColors = {
            'active': 'success',
            'inactive': 'error',
            'pending': 'warning'
        };
        const statusTexts = {
            'active': 'Đã duyệt',
            'inactive': 'Đã ẩn',
            'pending': 'Chờ duyệt'
        };
        return <Tag color={statusColors[status]}>{statusTexts[status]}</Tag>;
    };

    const getPackageInfo = (record) => {
        if (!record.package_id) return null;
        
        const packageColors = {
            1: '#E13427', // VIP nổi bật
            2: '#ea2e9d', // VIP 1
            3: '#FF6600', // VIP 2 
            4: '#007BFF', // VIP 3
            5: '#055699'  // Tin thường
        };

        const packageNames = {
            1: 'VIP nổi bật',
            2: 'VIP 1',
            3: 'VIP 2',
            4: 'VIP 3',
            5: 'Tin thường'
        };

        return (
            <Tag color={packageColors[record.package_id]}>
                {packageNames[record.package_id]}
                {record.package_expires_at && 
                    <Tooltip title={`Hết hạn: ${moment(record.package_expires_at).format('DD/MM/YYYY HH:mm')}`}>
                        <small className="ml-1">
                            ({moment(record.package_expires_at).fromNow()})
                        </small>
                    </Tooltip>
                }
            </Tag>
        );
    };

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            width: 40,
        },
        {
            title: 'Ảnh',
            dataIndex: 'thumbnail',
            key: 'thumbnail',
            width: 60,
            render: (thumbnail) => (
                <Image
                    src={thumbnail}
                    alt="Thumbnail"
                    style={{ width: 40, height: 30, objectFit: 'cover' }}
                />
            ),
        },
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
            width: '25%',
            ellipsis: true,
            render: (text, record) => (
                <Tooltip title={text}>
                    <a onClick={() => showPreview(record)}>{text}</a>
                </Tooltip>
            ),
        },
        {
            title: 'Người đăng',
            dataIndex: ['user', 'full_name'],
            key: 'user_name',
            width: '15%',
            ellipsis: true,
        },
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            width: 90,
            render: getStatusTag,
        },
        {
            title: 'Gói tin',
            dataIndex: 'package_id',
            key: 'package',
            width: 100,
            render: (_, record) => getPackageInfo(record) || 'Chưa có gói',
        },
        {
            title: 'Tính năng',
            key: 'features',
            width: 130,
            render: (_, record) => (
                <Space size={[0, 4]} wrap>
                    {record.auto_approve && <Tag className="m-1" color="green">Auto</Tag>}
                    {record.show_phone_number && <Tag className="m-1" color="blue">SĐT</Tag>}
                    {record.highlight_badge && <Tag className="m-1" color="gold">HOT</Tag>}
                    {record.title_style === 'uppercase' && <Tag className="m-1">ABC</Tag>}
                    {record.title_color && (
                        <Tag className="m-1" color={record.title_color}>
                            <StarFilled />
                        </Tag>
                    )}
                </Space>
            ),
        },
        {
            title: 'Thao tác',
            key: 'action',
            width: 220,
            render: (_, record) => (
                <Space size="small" wrap>
                    <Button
                        type="primary"
                        size="small"
                        icon={<EyeOutlined />}
                        onClick={() => showPreview(record)}
                    >
                        Chi tiết
                    </Button>
                    {record.status === 'pending' && (
                        <>
                            <Button
                                type="primary"
                                size="small"
                                icon={<CheckOutlined />}
                                onClick={() => handleStatusChange(record.id, 'active')}
                            >
                                Duyệt
                            </Button>
                            <Button
                                danger
                                size="small"
                                icon={<CloseOutlined />}
                                onClick={() => handleStatusChange(record.id, 'inactive')}
                            >
                                Từ chối
                            </Button>
                        </>
                    )}
                    <Button
                        danger
                        size="small"
                        icon={<DeleteOutlined />}
                        onClick={() => {
                            setDeletingListing(record);
                            setDeleteModalVisible(true);
                        }}
                    >
                        Xóa
                    </Button>
                </Space>
            ),
        },
    ];

    const handleDelete = async (listing) => {
        try {
            const response = await api.delete(`/admin/listings/${listing.id}`);
            if (response.data && response.data.success) {
                message.success('Xóa tin đăng thành công');
                setDeleteModalVisible(false);
                fetchListings();
            } else {
                throw new Error(response.data?.error || 'Không thể xóa tin đăng');
            }
        } catch (error) {
            console.error('Error deleting listing:', error);
            message.error(error.message || 'Lỗi khi xóa tin đăng');
        }
    };

    const handleApprove = async (id) => {
        try {
            await api.put(`/admin/listings/${id}/approve`);
            message.success('Listing approved');
            fetchListings(); // Refresh the listings
        } catch (error) {
            console.error('Error approving listing:', error);
            message.error('Failed to approve listing');
        }
    };

    // Thêm thông tin gói tin vào modal chi tiết
    const renderPackageDetails = (listing) => {
        if (!listing.package_id) return null;
        return (
            <div className="mb-4">
                <h3 className="font-bold mb-2">Thông tin gói tin</h3>
                <p><strong>Gói:</strong> {getPackageInfo(listing)}</p>
                <p><strong>Tính năng:</strong></p>
                <Space direction="vertical">
                    {listing.auto_approve && <Tag color="green">Tự động duyệt tin</Tag>}
                    {listing.show_phone_number && <Tag color="blue">Hiển thị số điện thoại</Tag>}
                    {listing.highlight_badge && <Tag color="gold">Badge nổi bật</Tag>}
                    {listing.title_style === 'uppercase' && <Tag>Tiêu đề chữ in hoa</Tag>}
                    {listing.title_color && <Tag color={listing.title_color}>Màu tiêu đề tùy chọn</Tag>}
                </Space>
            </div>
        );
    };

    // Thêm vào phần chi tiết modal
    const renderStatistics = (listing) => (
        <div className="mb-4">
            <h3 className="font-bold mb-2">Thống kê</h3>
            <div className="grid grid-cols-2 gap-4">
                <div className="flex items-center gap-2">
                    <EyeOutlined />
                    <span>{listing.views || 0} lượt xem</span>
                </div>
                {/* <div className="flex items-center gap-2">
                    <PhoneOutlined />
                    <span>{listing.contact_count || 0} lượt liên hệ</span>
                </div> */}
            </div>
        </div>
    );

    return (
        <div className="p-4">
            <h1 className="text-xl font-bold mb-4">Quản lý tin đăng</h1>
            
            <Table
                columns={columns}
                dataSource={listings}
                rowKey="id"
                loading={loading}
                size="small"
                pagination={{
                    pageSize: 10,
                    responsive: true,
                    position: ['bottomCenter'],
                    showSizeChanger: false
                }}
                scroll={{ x: false }}
                className="whitespace-normal"
            />

            <Modal
                title="Chi tiết tin đăng"
                visible={previewVisible}
                onCancel={() => setPreviewVisible(false)}
                width="95%"
                style={{ maxWidth: '1200px' }}
                footer={null}
            >
                {selectedListing && (
                    <div className="overflow-y-auto max-h-[80vh]">
                        <h2 className="text-xl font-bold mb-4">{selectedListing.title}</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                            <div>
                                <p><strong>Giá:</strong> {Number(selectedListing.price).toLocaleString()}đ</p>
                                <p><strong>Diện tích:</strong> {selectedListing.area}m²</p>
                                <p><strong>Địa chỉ:</strong> {selectedListing.address}</p>
                                <p><strong>Phường/Xã:</strong> {selectedListing.ward}</p>
                                <p><strong>Quận/Huyện:</strong> {selectedListing.district}</p>
                                <p><strong>Thành phố:</strong> {selectedListing.city}</p>
                            </div>
                            <div>
                                <p><strong>Người đăng:</strong> {selectedListing.user?.full_name}</p>
                                <p><strong>Email:</strong> {selectedListing.user?.email}</p>
                                <p><strong>Số điện thoại:</strong> {selectedListing.user?.phone_number}</p>
                                <p><strong>Ngày đăng:</strong> {moment(selectedListing.created_at).format('DD/MM/YYYY HH:mm')}</p>
                                <p><strong>Lượt xem:</strong> {selectedListing.views}</p>
                                {/* <p><strong>Lượt liên hệ:</strong> {selectedListing.contact_count}</p> */}
                            </div>
                        </div>
                        {renderPackageDetails(selectedListing)}
                        {renderStatistics(selectedListing)}
                        <div className="mb-4">
                            <h3 className="font-bold mb-2">Mô tả</h3>
                            <div dangerouslySetInnerHTML={{ __html: selectedListing.description }} />
                        </div>
                        <div className="mb-4">
                            <h3 className="font-bold mb-2">Hình ảnh</h3>
                            <Image.PreviewGroup>
                                <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-2">
                                    <Image width={150} src={selectedListing.thumbnail} />
                                    {selectedListing.images?.map((image, index) => (
                                        <Image key={index} width={150} src={image.image_url} />
                                    ))}
                                </div>
                            </Image.PreviewGroup>
                        </div>
                    </div>
                )}
            </Modal>

            <Modal
                title="Xác nhận xóa tin đăng"
                visible={deleteModalVisible}
                onOk={() => handleDelete(deletingListing)}
                onCancel={() => {
                    setDeleteModalVisible(false);
                    setDeletingListing(null);
                }}
                okText="Xóa"
                cancelText="Hủy"
                okButtonProps={{ danger: true }}
            >
                <p>Bạn có chắc chắn muốn xóa tin đăng này?</p>
                {deletingListing && (
                    <div className="mt-2 p-3 bg-gray-50 rounded">
                        <p><strong>Tiêu đề:</strong> {deletingListing.title}</p>
                        <p><strong>Người đăng:</strong> {deletingListing.user?.full_name}</p>
                        <p><strong>Ngày đăng:</strong> {moment(deletingListing.created_at).format('DD/MM/YYYY HH:mm')}</p>
                    </div>
                )}
            </Modal>
        </div>
    );
};

export default ListingManagement;
