import React, { useState } from 'react';
import { Layout, Menu, Avatar, Dropdown, Button } from 'antd';
import { Link, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import {
    DashboardOutlined,
    UserOutlined,
    FileTextOutlined,
    HomeOutlined,
    DollarOutlined,
    MenuFoldOutlined,
    MenuUnfoldOutlined,
    LogoutOutlined,
} from '@ant-design/icons';
import UserManagement from '../../pages/admin/UserManagement';

const { Header, Sider, Content } = Layout;

const AdminLayout = ({ children }) => {
    const [collapsed, setCollapsed] = useState(false);
    const location = useLocation();
    const { user, isAuthenticated, logout } = useContext(AuthContext);
    const navigate = useNavigate();

    if (!isAuthenticated || !user?.is_admin) {
        return <Navigate to="/" replace />;
    }

    const handleLogout = () => {
        logout();
        navigate('/');
    };

    const userMenu = {
        items: [
            {
                key: 'logout',
                icon: <LogoutOutlined />,
                label: 'Đăng xuất',
                onClick: handleLogout
            }
        ]
    };

    const menuItems = [
        {
            key: '/admin',
            icon: <DashboardOutlined />,
            label: 'Dashboard',
            onClick: () => navigate('/admin')
        },
        {
            key: '/admin/listings',
            icon: <HomeOutlined />,
            label: 'Quản lý tin đăng',
            onClick: () => navigate('/admin/listings')
        },
        {
            key: '/admin/transactions',
            icon: <DollarOutlined />,
            label: 'Quản lý giao dịch',
            onClick: () => navigate('/admin/transactions')
        },
        {
            key: 'news',
            icon: <FileTextOutlined />,
            label: 'Quản lý tin tức',
            children: [
                {
                    key: '/admin/news',
                    label: 'Danh sách tin tức',
                    onClick: () => navigate('/admin/news')
                },
                {
                    key: '/admin/news/create',
                    label: 'Thêm tin tức',
                    onClick: () => navigate('/admin/news/create')
                }
            ]
        },
        {
            key: '/admin/users',
            icon: <UserOutlined />,
            label: 'Quản lý người dùng',
            onClick: () => navigate('/admin/users')
        },
    ];

    const getContent = () => {
        switch (location.pathname) {
            case '/admin':
                return children;
            case '/admin/listings':
                return children;
            case '/admin/transactions':
                return children;
            case '/admin/news':
                return children;
            case '/admin/news/create':
                return children;
            case '/admin/users':
                return <UserManagement />;
            default:
                return children;
        }
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider 
                collapsible 
                collapsed={collapsed} 
                onCollapse={setCollapsed}
                style={{
                    overflow: 'auto',
                    height: '100vh',
                    position: 'fixed',
                    left: 0,
                    top: 0,
                    bottom: 0,
                }}
            >
                <div className="logo p-4 text-white text-center">
                    <h2 className="text-lg font-bold">{collapsed ? 'PT' : 'ThueNhaDeDang'}</h2>
                </div>
                <Menu
                    theme="dark"
                    mode="inline"
                    selectedKeys={[location.pathname]}
                    defaultOpenKeys={['news']}
                    items={menuItems}
                />
            </Sider>
            <Layout style={{ marginLeft: collapsed ? 80 : 200 }}>
                <Header style={{ 
                    padding: '0 24px',
                    background: '#fff',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between'
                }}>
                    <div className="flex items-center">
                        <Button
                            type="text"
                            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            onClick={() => setCollapsed(!collapsed)}
                            style={{ fontSize: '16px', width: 64, height: 64 }}
                        />
                    </div>
                    <div className="flex items-center space-x-4">
                        <Dropdown menu={userMenu} placement="bottomRight">
                            <div className="flex items-center cursor-pointer">
                                <Avatar icon={<UserOutlined />} className="mr-2" />
                                <span>{user.username}</span>
                            </div>
                        </Dropdown>
                    </div>
                </Header>
                <Content style={{ margin: '24px 16px', padding: 24, minHeight: 280 }}>
                    {getContent()}
                </Content>
            </Layout>
        </Layout>
    );
};

export default AdminLayout;
