import React, { createContext, useState, useContext, useEffect } from 'react';
import api from '../api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuth();
  }, []);

  const checkAuth = async () => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const response = await api.get('/user');
        if (response.data && response.data.success) {
          setUser(response.data.data);
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Error checking auth:', error);
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setUser(null);
        setIsAuthenticated(false);
      }
    }
    setLoading(false);
  };

  const login = async (credentials) => {
    try {
      if (!credentials?.email || !credentials?.password) {
        throw new Error('Email và mật khẩu là b���t buộc');
      }

      const response = await api.post('/login', {
        email: credentials.email,
        password: credentials.password
      });

      // Check response format
      if (response?.data) {
        const { status, data, error } = response.data;

        if (error === 'email_not_verified') {
          return {
            success: false,
            error: 'email_not_verified',
            message: 'Email chưa được xác thực'
          };
        }

        if (status === 'success' && data) {
          const { user, token } = data;
          
          localStorage.setItem('token', token);
          localStorage.setItem('user', JSON.stringify(user));
          
          setUser(user);
          setIsAuthenticated(true);

          return {
            success: true,
            user: user,
            token: token
          };
        }
      }
      
      throw new Error('Đăng nhập thất bại');
    } catch (error) {
      console.error('Login error:', error);
      throw new Error(error.response?.data?.message || error.message);
    }
  };

  const logout = async () => {
    try {
      await api.post('/logout');
    } catch (error) {
      console.error('Logout error:', error);
    } finally {
      localStorage.removeItem('token');
      localStorage.removeItem('user');
      setUser(null);
      setIsAuthenticated(false);
    }
  };

  const register = async (userData) => {
    try {
      const response = await api.post('/register', userData);
      
      // Check if response is JSON
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('Invalid server response format');
      }

      if (response.data && response.data.success) {
        return response.data;
      } else if (response.data && response.data.error) {
        throw new Error(response.data.error);
      } else {
        throw new Error('Registration failed');
      }
    } catch (error) {
      console.error('Register error:', error);
      if (error.response?.data?.message) {
        throw new Error(error.response.data.message);
      } else if (error.response?.data?.error) {
        throw new Error(error.response.data.error);
      }
      throw error;
    }
  };

  const updateUser = (userData) => {
    // Log để debug
    console.log('Updating user with data:', userData);
    
    if (userData?.token) {
        localStorage.setItem('token', userData.token);
    }
    
    // Đảm bảo lưu đúng format dữ liệu
    const userToStore = {
        id: userData.id,
        email: userData.email,
        username: userData.username,
        full_name: userData.full_name,
        is_admin: userData.is_admin,
        is_landlord: userData.is_landlord,
        is_verified: userData.is_verified
    };
    
    localStorage.setItem('user', JSON.stringify(userToStore));
    setUser(userToStore);
    setIsAuthenticated(true);
  };

  const value = {
    user,
    isAuthenticated,
    loading,
    login,
    logout,
    register,
    checkAuth,
    updateUser
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};

// Thêm hook useAuth
export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
