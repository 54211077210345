import React, { useState, useEffect } from 'react';
import { Table, Space, Button, Tag, message, Popconfirm } from 'antd';
import { EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import api from '../../api';

const NewsManagement = () => {
    const [news, setNews] = useState([]);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        fetchNews();
    }, []);

    const fetchNews = async () => {
        setLoading(true);
        try {
            const response = await api.get('/news');
            // Kiểm tra và chuyển đổi dữ liệu
            const newsData = Array.isArray(response.data) ? response.data : [];
            setNews(newsData.map(item => ({
                ...item,
                key: item.id // Thêm key cho Table
            })));
        } catch (error) {
            console.error('Error fetching news:', error);
            message.error('Lỗi khi tải danh sách tin tức');
        }
        setLoading(false);
    };

    const handleDelete = async (id) => {
        try {
            await api.delete(`/news/${id}`);
            message.success('Xóa tin tức thành công');
            fetchNews(); // Tải lại danh sách sau khi xóa
        } catch (error) {
            console.error('Error deleting news:', error);
            message.error('Lỗi khi xóa tin tức');
        }
    };

    const columns = [
        {
            title: 'Tiêu đề',
            dataIndex: 'title',
            key: 'title',
        },
        
        {
            title: 'Trạng thái',
            dataIndex: 'status',
            key: 'status',
            render: (status) => (
                <Tag color={
                    status === 'published' ? 'green' :
                    status === 'draft' ? 'gold' : 'red'
                }>
                    {status === 'published' ? 'Đã đăng' :
                     status === 'draft' ? 'Nháp' : 'Đã ẩn'}
                </Tag>
            ),
        },
        {
            title: 'Tác giả',
            dataIndex: 'author_name',
            key: 'author_name',
        },
        {
            title: 'Thao tác',
            key: 'action',
            render: (_, record) => (
                <Space size="middle">
                    <Button 
                        icon={<EyeOutlined />}
                        onClick={() => window.open(`/news/${record.id}`, '_blank')}
                    />
                    <Button 
                        icon={<EditOutlined />}
                        onClick={() => navigate(`/admin/news/edit/${record.id}`)}
                    />
                    <Popconfirm
                        title="Bạn có chắc muốn xóa tin tức này?"
                        onConfirm={() => handleDelete(record.id)}
                        okText="Có"
                        cancelText="Không"
                    >
                        <Button icon={<DeleteOutlined />} danger />
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <div className="flex justify-between mb-4">
                <h1 className="text-2xl font-bold">Quản lý tin tức</h1>
                <Button 
                    type="primary"
                    onClick={() => navigate('/admin/news/create')}
                >
                    Thêm tin tức
                </Button>
            </div>
            <Table 
                columns={columns} 
                dataSource={news}
                rowKey="id"
                loading={loading}
            />
        </div>
    );
};

export default NewsManagement;
